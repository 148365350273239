import { Component, } from '@angular/core';

@Component({
    selector: 'ess-spinner',
    templateUrl: './spinner.component.html',
    styleUrls: ['./spinner.component.scss'],
})
export class EssSpinnerComponent {
    constructor(
    ) { }
}
