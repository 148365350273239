import { Inject, Injectable, InjectionToken } from '@angular/core';
export const CONFIG_OPTIONS = new InjectionToken<LibraryConfigurationOptions>('CONFIG_OPTIONS');

export interface LibraryConfigurationOptions {
    isDebug?: boolean;
    loggerPrefix?: string;
    appName?: string
    version?: string
    host?: string
}
@Injectable({
    providedIn: 'root'
})
export class LibraryConfigurationService {

    constructor(
        @Inject(CONFIG_OPTIONS) private configOptions: LibraryConfigurationOptions,

    ) {
    }
    get isDebug() {
        return this.configOptions.isDebug;
    }
    get loggerPrefix() {
        return this.configOptions.loggerPrefix;
    }

    get appName() {
        return this.configOptions.appName
    }

    get version() {
        return this.configOptions.version
    }

    get host() {
        return this.configOptions.host
    }
}
