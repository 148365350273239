import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'getInitials',
})
export class GetInitialsPipe implements PipeTransform {
    transform(value: string) {
        if (value) {
            return value.substring(0, 2).toUpperCase();
        } else {
            return '?';
        }
    }
}
