<div [ngClass]="responsiveService.getBigSmall()">
    <div class="submit-header">
        <span class="submit-title" i18n></span>
        <timer *ngIf="ownerHasPremium && this.estimateService.showTimer" [isOwner]="isOwner"></timer>
        <div class="open-settings">

        </div>
    </div>
    <div class="submit-content">
        <form [formGroup]="provideEstimateForm" autocomplete="off" novalidate>
            <div class="flex-container">
                <div [ngClass]="getSelectedClass(estimateOption)"
                    *ngFor="let estimateOption of estimateService.estimateOptions"
                    (click)=" submitEstimate( estimateOption)">
                    <button mat-button class="card" i18n-matTooltip matTooltip="Click to submit this estimate">
                        <div class="button-inside">
                            <div class="top">
                                <img src="assets/images/logo_trans.png" i18n-alt
                                    *ngIf="!responsiveService.isScreenSmall()">
                                <img src="assets/images/logo_trans.png" i18n-alt
                                    *ngIf="!responsiveService.isScreenSmall()">
                            </div>
                            <div class="middle" *ngIf="estimateOption!=='cof'">{{estimateOption}}</div>
                            <div class="middle" *ngIf="estimateOption==='cof'">
                                <mat-icon>coffee</mat-icon>
                            </div>
                            <div class="bottom">
                                <img src="assets/images/logo_trans.png" i18n-alt
                                    *ngIf="!responsiveService.isScreenSmall()">
                                <img src="assets/images/logo_trans.png" i18n-alt
                                    *ngIf="!responsiveService.isScreenSmall()">

                            </div>
                        </div>
                    </button>
                </div>
            </div>
        </form>
    </div>
</div>
