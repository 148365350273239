<div [ngClass]="responsiveService.getBigSmall()">
  <span *ngIf="userService.isLoggedIn() else guest">
    <home-logged-in></home-logged-in>
  </span>

  <ng-template #guest>
    <span>
      <home-not-logged-in></home-not-logged-in>
    </span>
  </ng-template>

  <how-it-works></how-it-works>


  <div class="article">
    <h2 i18n>What is Planning Poker versus Scrum Poker?</h2>
    <p i18n>
      <a routerLink="/">Scrum poker</a> or the related trade marked <b>Planning poker</b>, is an effort or relative size estimation technique often
      used in the context of scrum or other agile frameworks. The term "Planning Poker" was made popular by Mike Cohn in
      his Agile Estimating and Planning book and is based on Wideband delphi method. Scrum poker has become a favored
      technique for many to quickly and efficient run their planning session.
    </p>
    <p i18n>
      During such a session, the team will sit together physically or virtually and after the product owner has
      presented a user story the team will discuss to agree on the acceptance criteria. Once the conversation has run
      its natural course, it is time to start estimating: each individual chose the estimation, unbeknown to the others,
      which in his view best represents the relative effort and complexity of the discussed user story. Once this is
      done all the estimations are revealed at the same time. Now the people with the highest estimate and with the
      lowest are both invited to discuss a justification for their estimates. The goal is that all the members in the
      team reach a consensual estimation, which will be associated to the estimation.
    </p>
    <p i18n>
      This process is then repeated until a consensus is reached and the team comes to an agreement.
    </p>
    <h3 i18n>Why use Planning poker?</h3>
    <p i18n>
      An evident first reason to use planning poker for estimation is because it avoids the danger of individuals being
      influenced by the estimations of other participants. But more than that, a planning session allows the team to
      align even more deeply on the content of a user story, it's definition of done and the acceptance criteria. Often
      during the conversation following very large discrepancies, it becomes evident that one person assumed an implicit
      acceptance criteria or maybe someone missed a simplifying synergy.
    </p>
    <p i18n>
      Another valuable outcome of planning poker and the use of story points for your sprint, is that it allows you to
      start measuring velocity. Velocity is an indication of the average amount of product backlog items, measured using
      the aforementioned story points, that were turned into an increment during the current sprint. Velocity allows the
      team to structure their inspection and is a good baseline for a conversation during the retrospective. As we
      discussed since story points are a relative estimation and are based on a consensus, this should never be used as
      an absolute metric to measure performance or to compare teams, this should simply be a spark to start a
      conversation based on a measure.
    </p>
    <p i18n>
      So to summarize, planning poker is valuable because it removes reciprocal influences during the estimation process
      & because it allows for a deeper alignment on the acceptance criteria of a user story. You should try it out and
      see how quickly the estimations lay open underlying differences in understanding of the user story.
    </p>



  </div>
</div>