import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UIMesageService } from '../common/ui-message.service';
import { NGXLogger as LoggerService } from "ngx-logger";
import { ResponsiveService } from '../common/responsive.service';

@Component({
    selector: 'privacy',
    templateUrl: './privacy.component.html',
    styleUrls: ['./privacy.component.scss']

})
export class PrivacyComponent implements OnInit {

    constructor(
        private router: Router,
        private uiMessage: UIMesageService,
        private logger: LoggerService,
        private route: ActivatedRoute,
        public responsiveService: ResponsiveService
    ) {

    }

    ngOnInit() {
    }

}
