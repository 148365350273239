/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable @typescript-eslint/semi */
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { IEstimate, IRoom, IShowHide } from './esimtate.model'
import { AngularFireDatabase, AngularFireList, AngularFireObject, SnapshotAction, snapshotChanges } from '@angular/fire/compat/database';
import { NGXLogger as LoggerService } from "ngx-logger";
import firebase from 'firebase/compat/app';
import 'firebase/firestore';

@Injectable()
export class EstimateFdbService {
    roomObvservable: Observable<IRoom>;
    constructor(
        private afd: AngularFireDatabase,
        private logger: LoggerService
    ) {
    }

    public async createRoom(uid: string, roomId: string, isOwnerAnonymous: boolean): Promise<void> {
        this.logger.log('createRoom: ' + roomId)
        const roomData: IRoom = {
            ownerUid: uid,
            isOwnerAnonymous: isOwnerAnonymous,
        }
        const roomRef: AngularFireObject<any> = this.afd.object(`pokerRooms/${roomId}`);
        return roomRef.set(roomData)
    }

    private getEstimatesLocation(roomId: string) {
        return ('scrumPoker/estimates/' + roomId)
    }
    public async createEstimate(roomId: string, developerName: string, uid: string, estimate?: string): Promise<void> {
        let currentEstimate: IEstimate;
        currentEstimate = {
            displayName: developerName,
        };
        currentEstimate.updatedAt = this.getTimestamp()

        const estimateRef: AngularFireObject<any> = this.afd.object(this.getEstimatesLocation(roomId) + `/${uid}`);
        if (estimate) {
            this.logger.log('estimate given')
            currentEstimate.storyPoints = estimate
            await estimateRef.set(currentEstimate); // we want to delete the old attributes that are no longer required
        } else {
            await estimateRef.update(currentEstimate);
        }
    }

    private getTimestamp() {
        return firebase.database.ServerValue.TIMESTAMP
    }

    public async isExistentRoom(roomId): Promise<boolean> {
        this.logger.log('isExistentRoom: ' + roomId)
        const roomPromise = new Promise<boolean>(async (resolve, reject) => {
            try {
                const roomRef = this.afd.database.ref(('pokerRooms/' + roomId));
                roomRef.on('value', snapshot => {
                    if (snapshot.exists()) {
                        const roomData = snapshot.val();
                        this.logger.log('Room exists', roomData)
                        resolve(true)
                    } else {
                        this.logger.log('Room doesn\'t exists')
                        resolve(false)
                    }
                });
            } catch (error) {
                const errorMessage = 'Could not reach server. ' + error;
                this.logger.error(errorMessage)
                reject(error)
            }
        });
        return roomPromise
    }

    public getEstimatesObservable(roomId: string): Observable<SnapshotAction<any>[]> {
        const estimateRef: AngularFireList<any> = this.afd.list(this.getEstimatesLocation(roomId));
        return estimateRef.snapshotChanges();
    }
    public getShowHideObservable(roomId: string): Observable<IShowHide> {
        const ref: AngularFireObject<any> = this.afd.object(`scrumPoker/showEstimates/${roomId}`);
        return ref.valueChanges();
    }

    public getRoomObservable(roomId: string): Observable<IRoom> {
        const roomRefObject: AngularFireObject<any> = this.afd.object(`pokerRooms/${roomId}`);
        this.roomObvservable = roomRefObject.valueChanges();
        return this.roomObvservable;
    }
    public async setShowEstimates(roomId: string, showEstimates: boolean): Promise<void> {
        const updateObject: IShowHide = {
            showEstimates: showEstimates,
        }
        const roomRef: AngularFireObject<any> = this.afd.object(`scrumPoker/showEstimates/${roomId}`);
        return roomRef.update(updateObject)
    }

    public async setRoomConfiguration(roomId: string, estimateOptions: string, allowHide: string, allowClear: string, allowDeleteEstimates: string,
        userPresence: string,
        showAverage: string,
        showMedian: string,
        showTimer: string,

    ): Promise<void> {
        const roomUpdate: IRoom = {
            estimateOptions: estimateOptions,
            allowHide: allowHide,
            allowClear: allowClear,
            allowDeleteEstimates: allowDeleteEstimates,
            userPresence: userPresence,
            showAverage: showAverage,
            showMedian: showMedian,
            showTimer: showTimer,
        }
        const roomRef: AngularFireObject<any> = this.afd.object(`pokerRooms/${roomId}`);
        return roomRef.update(roomUpdate)
    }


    public clearStoryPoints(roomId: string): void {
        this.logger.log('clearStoryPoints')
        this.clearOldUsers(roomId);
        let currentEstimate: any;
        currentEstimate = {
        };
        currentEstimate.storyPoints = null;
        const estimatesRef = this.afd.database.ref(this.getEstimatesLocation(roomId));
        estimatesRef.once('value', snapshot => {
            snapshot.forEach(childSnapshot => {
                const childKey = childSnapshot.key;
                const childData = childSnapshot.val();
                const estimateRef: AngularFireObject<any> = this.afd.object(this.getEstimatesLocation(roomId) + `/${childKey}`);
                // await estimateRef.set(currentEstimate);
                estimateRef.update(currentEstimate);
            });
        });
    }
    public clearRoom(roomId: string): void {
        const roomRef: AngularFireList<any> = this.afd.list(this.getEstimatesLocation(roomId));
        roomRef.remove();
    }


    public async deleteRoom(roomId: string) {
        const roomRef: AngularFireObject<any> = this.afd.object(`/pokerRooms/${roomId}`);
        await roomRef.remove()

    }
    // returns the current time from  firenbase server
    // format is the millisecond of the clients time zone since 1.Jan.1970
    private async getServerTime(): Promise<number> {
        return new Promise(resolve => {
            const offsetRef = this.afd.database.ref('.info/serverTimeOffset');
            offsetRef.on('value', async snap => {
                const offset = snap.val();
                const estimatedServerTimeMs: number = new Date().getTime() + offset;
                console.log('estimatedServerTimeMs: ' + estimatedServerTimeMs)
                resolve(estimatedServerTimeMs);
            });
        })
    }

    // this removes users that did not have any activity during the last 30 minutes
    // it clears not only the estimate but actually removes the user from the room
    public async clearOldUsers(roomId: string) {

        const estimatedServerTimeMs = await this.getServerTime();
        const minutes = 30;
        const milliSeconds = 1000 * 60 * minutes;
        const cutOffTime = new Date(estimatedServerTimeMs - milliSeconds)
        this.logger.log('clearOldUsers with last update before: ' + cutOffTime)
        const esimtatesReference = this.afd.database.ref(this.getEstimatesLocation(roomId)); //('scrumPoker/estimates/' + roomId)
        esimtatesReference.once('value', estimates => {
            estimates.forEach(estimate => {
                const childKey = estimate.key;
                const childData = estimate.val();
                const estimateRef: AngularFireObject<any> = this.afd.object(this.getEstimatesLocation(roomId) + '/' + childKey);
                const updatedAt = new Date(childData.updatedAt)
                if (childData.updatedAt < cutOffTime) { // updateAt on esimtation
                    this.logger.warn('Remove estimate: ' + childKey + '; Updated:  ' + updatedAt)
                    estimateRef.remove()
                } else {
                    this.logger.warn('Don\'t remove estimate: ' + childKey + '; Updated:  ' + updatedAt)
                }
            });
        });
    }

    public deleteOldRooms(): void {
        const days = -15;
        const cutOffDate = new Date()// returns today
        //        cutOffDate = this.addDays(cutOffDate,-7);
        cutOffDate.setDate(cutOffDate.getDate() + days);
        this.logger.log('Deleting anonymous rooms older than: ' + cutOffDate)
        this.logger.log('deleteOldRooms')
        let currentEstimate: any;
        currentEstimate = {
        };
        currentEstimate.storyPoints = null;
        const estimatesRef = this.afd.database.ref('/pokerRooms');
        estimatesRef.once('value', snapshot => {
            snapshot.forEach(childSnapshot => {
                const childKey = childSnapshot.key;
                const childData = childSnapshot.val();
                const roomRef: AngularFireObject<any> = this.afd.object(`/pokerRooms/${childKey}`);
                if (childData.updatedAt < cutOffDate && childData.isOwnerAnonymous == true) {
                    this.logger.warn('Remove room: ' + childKey + '; Updated:  ' + childData.updatedAt + '; isOwnerAnonymous: ' + childData.isOwnerAnonymous)
                    roomRef.remove()
                } else {
                    this.logger.log('Don\'t remove room: ' + childKey + '; Updated:  ' + childData.updatedAt + '; isOwnerAnonymous: ' + childData.isOwnerAnonymous)
                }
            });
        });
    }
}
