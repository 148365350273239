import { Injectable } from '@angular/core';
import { NGXLogger as LoggerService } from "ngx-logger";
import { EstimateFdbService } from './estimate.fdb.service';



@Injectable()
export class GenerateRoomService {


    constructor(
        private estimateFireService: EstimateFdbService,
        private logger: LoggerService,
    ) {
    }

    public async generateRoom(uid: string, isOwnerAnonymous: boolean): Promise<string> {
        // radonmly generate a code and then check if does not  exist already then create the room
        const length = 8;
        let loopCount = 0;
        let roomId = '';
        while (true) {
            roomId = this.makeId(length);
            this.logger.log('roomId: ' + roomId);
            this.logger.log('await');
            const isExistentRoom = await this.isExistentRoom(roomId);
            this.logger.log('await');
            if (!isExistentRoom) {
                await this.createRoom(uid, roomId, isOwnerAnonymous);
                return roomId;
            }
            loopCount = loopCount + 1;
            if (loopCount > 100) { throw Error('No free room'); }
        }
    }
    public async isExistentRoom(roomId): Promise<boolean> {
        return this.estimateFireService.isExistentRoom(roomId);
    }


    public async createRoom(uid: string, roomId: string, isOwnerAnonymous: boolean): Promise<void> {
        await this.estimateFireService.createRoom(uid, roomId, isOwnerAnonymous);
    }

    private makeId(length): string {
        let result = '';
        //  var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
        const characters = '0123456789';
        const charactersLength = characters.length;
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }
}
