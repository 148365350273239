import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { RoomIdService } from '../poker-room/room-id.service';
import { AuthService } from '../user/auth.service';
import { UIMesageService } from '../common/ui-message.service';
import { UserService } from '../user/user.service';
import { NGXLogger as LoggerService } from "ngx-logger";
import { ResponsiveService } from '../common/responsive.service';
import { MatDialog } from '@angular/material/dialog';
import { CreateRoomDialogComponent } from './create-room-dialog.component';
import { GoogleAnalyticsService } from '../common/google-analytics.service';
import { QrCodeDialogComponent } from '../qr-code/qr-code-dialog.component';

@Component({
    selector: 'create-room-button',
    templateUrl: './create-room-button.component.html',
    styleUrls: ['./create-room-button.component.scss']
})

export class CreateRoomButtonComponent {
    name: string;
    constructor(
        private router: Router,
        private roomIdService: RoomIdService,
        public authService: AuthService,
        private uiMessage: UIMesageService,
        private logger: LoggerService,
        private route: ActivatedRoute,
        public userService: UserService,
        public responsiveService: ResponsiveService,
        public dialog: MatDialog,
        private googleAnalyticsService: GoogleAnalyticsService,
    ) {
    }
    public async handleClick() {
        this.googleAnalyticsService.eventEmitter('createRoom', 'Home Page', 'createRoomAction');
        if (this.userService.isLoggedIn()) {
            await this.userService.createAndAssignUserRoom(this.authService.uid, this.authService.isAnonymous);
            this.navigatAndOpenQRCodeDialog()
        } else {
            const dialogRef = this.dialog.open(CreateRoomDialogComponent, {
                width: '450px',
                data: { name: this.name }
            });
            dialogRef.afterClosed().subscribe(result => {
                console.log('The dialog was closed');
                this.name = result;
                if (this.name) {
                    this.logger.log('result: ' + result);
                    this.signup(result);
                } else {
                    this.logger.log('Closed without result - hence canceled: ');
                }
            });
        }
    }

    async signup(name) {
        try {
            await this.authService.signUpAnonymous(name, true);
            this.navigatAndOpenQRCodeDialog();
        } catch (error) {
            this.uiMessage.error(error, 'Creating the  room failed. ');
        }
    }

    private navigatAndOpenQRCodeDialog(): void {
        this.router.navigate(['/room/' + this.userService.getRoomId() + '/scrum-poker']);
        this.dialog.open(QrCodeDialogComponent);
    }
}

