<div [ngClass]="responsiveService.getBigSmall()">
    <mat-dialog-actions class="dialog-actions" align="end">

        <span class="cancel-button-container">
            <button *ngIf="showCancel" mat-flat-button class="cancel-button" mat-dialog-close (click)="close()">
                Cancel
            </button>
        </span>
        <span class="spo-font-color-white" *ngIf="showConfirmButton">
            <button mat-raised-button color="accent" [disabled]="confirmButtonDisabled" tabindex="0"
                (click)="confirm()">{{confirmLabel}}</button>
        </span>

    </mat-dialog-actions>
</div>
