import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { ServiceWorkerModule } from '@angular/service-worker';

import { EssDialogActions } from './common/dialogs/dialog-actions.component';
import { EssDialogService } from './common/dialogs/dialog-service';
import { EssDialogTitle } from './common/dialogs/dialog-title.component';

import { EssOkCancelDialogComponent } from './common/dialogs/ok-cancel-dialog.component';
import { EssTermsDialogComponent } from './common/dialogs/terms-dialog.component';
import { GetInitialsPipe } from './common/pipes/initials.pipe';
import {
    MediumTitlePipe,
    ShortTitlePipe
} from './common/pipes/short-title.pipe';
import {
    CONFIG_OPTIONS,
    LibraryConfigurationOptions,
    LibraryConfigurationService
} from './core/library-configuration.service';
import { ResponsiveService } from './core/responsive.service';
import { PaymentComponent } from './payment/payment.component';
import { EssSpinnerComponent } from './common/spinner/spinner.component';

@NgModule({
    declarations: [
        PaymentComponent,
        EssDialogActions,
        EssDialogTitle,
        EssOkCancelDialogComponent,
        EssTermsDialogComponent,
        GetInitialsPipe,
        ShortTitlePipe,
        MediumTitlePipe,
        EssSpinnerComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        ServiceWorkerModule,
        MatDialogModule,
        MatButtonModule,
        MatIconModule,
        MatSnackBarModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatRadioModule,
        MatProgressSpinnerModule,
        MatCheckboxModule,
        MatProgressSpinnerModule
    ],
    exports: [
        PaymentComponent,
        EssDialogActions,
        EssDialogTitle,
        EssOkCancelDialogComponent,
        EssTermsDialogComponent,
        GetInitialsPipe,
        ShortTitlePipe,
        MediumTitlePipe,
        EssSpinnerComponent
    ],
})
export class NgxEssentiaModule {
    constructor(private responsiveService: ResponsiveService) {
        this.responsiveService.setBreakpointObserver();
    }
    static init(
        options?: LibraryConfigurationOptions
    ): ModuleWithProviders<NgxEssentiaModule> {
        return {
            ngModule: NgxEssentiaModule,
            providers: [
                EssDialogService,
                LibraryConfigurationService,
                {
                    provide: CONFIG_OPTIONS,
                    useValue: options,
                },
            ],
        };
    }
}
