import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { NGXLogger as LoggerService } from "ngx-logger";
import { firstValueFrom } from 'rxjs';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { UIMesageService } from '../../common/ui-message.service';
import { environment } from '../../../environments/environment';
import { Timestamp } from 'firebase/firestore';
import { DepricatedShopService } from './depricated-shop.service';

export interface StripeProduct {
    id: string;
    active: boolean;
    name: string;
    description: string;
    metadata: {};
    prices: StripePrice[];
}

export interface StripePrice {
    id: string;
    active: boolean;
    amount: number;
    currency: string;
    interval: string;
    description: string;
    interval_count: number;
    product: string;
    unit_amount: number;
}
export type StripeSubscriptionStatus =
    | "active"
    | "canceled"
    | "incomplete"
    | "incomplete_expired"
    | "past_due"
    | "trialing"
    | "unpaid";



export interface StripeSubscription {
    cancelAtPeriodEnd?: boolean;
    status?: StripeSubscriptionStatus;
    currentPeriodEnd?: Timestamp;
    currentPeriodEndUtcDateString?: string;
    endedAt?: Timestamp;
    endedAtUtcDateString?: string;
    currency?: string;
    interval?: string;
    unitAmount?: number;
    priceId?: string;
    productId?: string;
}


function toNullableUTCDateString(timestamp: Timestamp | null): string | null {
    if (timestamp === null) {
        return null;
    }
    return toUTCDateString(timestamp);
}

function toUTCDateString(timestamp: Timestamp): string {
    return timestamp.toDate().toUTCString();
}

@Injectable()
export class StripeService {
    public products: StripeProduct[];
    public subscription: StripeSubscription;
    private _uid: string;
    private _isProductLoading = false;
    private _isSubscriptionLoading = false;
    private _redirectingToPortal = false;
    private _redirectingToCheckoutSubscription = false;
    constructor(
        private logger: LoggerService,
        private firestoreDb: AngularFirestore,
        private fns: AngularFireFunctions,
        private uiMessage: UIMesageService,
        @Inject(LOCALE_ID) public locale: string,
        private depricatedShopService: DepricatedShopService
    ) {
    }
    get isLoading(): boolean {
        return this._isProductLoading || this._redirectingToPortal || this._redirectingToCheckoutSubscription || this._isSubscriptionLoading;
    }
    get isProductLoading(): boolean {
        return this._isProductLoading
    }
    get redirectingToPortal(): boolean {
        return this._redirectingToPortal
    }
    get redirectingToCheckoutSubscription(): boolean {
        return this._redirectingToCheckoutSubscription
    }
    get isSubscriptionLoading(): boolean {
        return this._isSubscriptionLoading
    }
    get uid(): string {
        return this._uid
    }
    public async init(uid: string) {
        this._uid = uid;
        await this.loadActiveProducts();
        await this.loadSubscription();
        await firstValueFrom(this.depricatedShopService.getActiveProductsObvservable(this.uid))
    }

    public async getUserHasPremium(uid: string): Promise<boolean> {
        const userHasPayPalPremium = await this.depricatedShopService.getUserHasPremium(uid)
        const subscriptionData = await this.getSubscriptionData(uid);//this will NOT overwrite the subscription data
        return userHasPayPalPremium || subscriptionData !== undefined
    }

    public async loadActiveProducts() {
        this._isProductLoading = true;
        this.products = [];
        const promises: Promise<any>[] = []
        const observable = this.firestoreDb.collection('products', ref => ref.where('active', '==', true)).get();
        const activeProductsSnapshot = await firstValueFrom(observable)
        activeProductsSnapshot.forEach((productDoc) => {
            const product = productDoc.data() as StripeProduct;
            product.prices = [];
            this.products.push(product)
            product.id = productDoc.id;
            this.logger.log('productDoc' + productDoc.id, ' => ', productDoc.data());
            promises.push(productDoc.ref.collection('prices').where('active', '==', true).get().then(activePricesSnapshot => {
                activePricesSnapshot.docs.forEach((priceDoc) => {
                    const price = priceDoc.data() as StripePrice;
                    price.id = priceDoc.id;
                    product.prices.push(price);
                    this.logger.log('priceDoc', priceDoc.id, ' => ', priceDoc.data());
                });
            }));
        }
        );
        await Promise.all(promises) // wait for all prices to be loaded
        this._isProductLoading = false;
        this.logger.log('products', this.products);
    }

    /**
     * Does NOT and schould NOT overwrite the subscription data
     */
    public async getSubscriptionData(uid: string) {
        const subscriptionCollection = this.firestoreDb.collection('customers')
            .doc(uid)
            .collection('subscriptions', ref => ref.where('status', 'in', ['trialing', 'active']))
        const subscriptionsObservable = subscriptionCollection.valueChanges()
        const subscriptions = await firstValueFrom(subscriptionsObservable)
        const subscriptionData = subscriptions[0];
        return subscriptionData
    }

    public async loadSubscription() {
        this._isSubscriptionLoading = true
        const subscriptionData = await this.getSubscriptionData(this.uid);
        if (subscriptionData) {
            this.logger.log('subscription data', subscriptionData);
            this.subscription = {};
            this.subscription.cancelAtPeriodEnd = subscriptionData.cancel_at_period_end
            this.subscription.status = subscriptionData.status
            this.subscription.priceId = subscriptionData.price
            this.subscription.productId = subscriptionData.product
            this.subscription.currentPeriodEnd = subscriptionData.current_period_end
            this.subscription.currentPeriodEndUtcDateString = toUTCDateString(subscriptionData.current_period_end)
            this.subscription.endedAt = subscriptionData.ended_at
            this.subscription.endedAtUtcDateString = toNullableUTCDateString(subscriptionData.ended_at)
            this.subscription.currency = subscriptionData.items[0].price.currency
            this.subscription.unitAmount = subscriptionData.items[0].price.unit_amount
            this.subscription.interval = subscriptionData.items[0].price.recurring?.interval
            this.logger.warn('subsrciption', this.subscription)
        }
        this._isSubscriptionLoading = false;
    }

    public async redirectToCheckoutSubscription(priceId: string) {
        this._redirectingToCheckoutSubscription = true;
        this.logger.log('checkoutSubscription');
        const cancelUrl = this.getUrlHost() + '/user/account/upgrade';
        const successUrl = this.getUrlHost() + '/user/account/upgrade';
        const docRef = await this.firestoreDb
            .collection('customers')
            .doc(this.uid)
            .collection('checkout_sessions')
            .add({

                price: priceId,
                // allow_promotion_codes: true,
                promotion_code: await this.getPromotionCode(),
                payment_method_types: ['card', 'paypal'], //https://stripe.com/docs/payments/paypal/accept-a-payment
                success_url: successUrl,
                cancel_url: cancelUrl,
                locale: this.locale
            });
        // Wait for the CheckoutSession to get attached by the extension
        docRef.onSnapshot((snap) => {
            const { error, url } = snap.data();
            if (error) {
                this.uiMessage.error(error)
                this._redirectingToCheckoutSubscription = false
            }
            if (url) {
                // We have a Stripe Checkout URL, let's redirect.
                window.location.assign(url);
            }
        });
    }

    private async getPromotionCode(): Promise<string> {
        const hasPayPalPremium = await this.depricatedShopService.getUserHasPremium(this.uid);
        if (hasPayPalPremium) {
            const endDate = this.depricatedShopService.getEndDate();
            if (endDate > this.getDateMonthsFromNow(6)) {
                if (environment.production) return 'promo_1OPmdIGj6Cubns3VilLp0FGm'
                if (!environment.production) return 'promo_1OPmTrGj6Cubns3VMYpaUtoA'
            } else if (endDate > this.getDateMonthsFromNow(1)) {
                if (environment.production) return 'promo_1OPmf7Gj6Cubns3VXZNpZIIr'
                if (!environment.production) return 'promo_1OPmRuGj6Cubns3VSWcsF9Hj'

            } else {
                if (environment.production) return 'promo_1OPmgfGj6Cubns3VVkdtSZup'
                if (!environment.production) return 'promo_1OPmN7Gj6Cubns3VMwEK4o82'
            }
        } else {
            return '';
        }
    }

    private getDateMonthsFromNow(months: number): Date {
        const date = new Date();
        date.setMonth(date.getMonth() + months);
        return date;
    }

    async redirectToPortal() {
        this._redirectingToPortal = true;
        const functionRef = this.fns.httpsCallable('ext-firestore-stripe-payments-createPortalLink');
        const returnUrl = this.getUrlHost() + '/user/account/upgrade';
        const data = await firstValueFrom(functionRef({
            returnUrl: returnUrl,
            locale: this.locale, // Optional, defaults to "auto"
            //   configuration: "bpc_1JSEAKHYgolSBA358VNoc2Hs", // Optional ID of a portal configuration: https://stripe.com/docs/api/customer_portal/configuration
        }))
        window.location.assign(data.url);
    }

    private getUrlHost(): string {
        if (location.hostname === "localhost" || location.hostname === "127.0.0.1") {
            return "http://" + location.hostname + ":" + location.port
        }
        if (this.locale && this.locale !== '' && this.locale !== 'en-US') {
            return environment.host_url + '/' + this.locale;
        } else {
            return environment.host_url;
        }
    }
}
