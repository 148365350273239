import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { LoggerService } from '../../core/logger.service';
import { ResponsiveService } from '../../core/responsive.service';


@Injectable({
    providedIn: 'root'
})

export class EssDialogService {

    constructor(
        private dialog: MatDialog,
        private logger: LoggerService,
        public responsiveService: ResponsiveService,
    ) { }
    public open(dialogComponent: ComponentType<any>, dialogData?: any, bigScreenWidth?: string): MatDialogRef<any> {
        this.logger.log('open', dialogComponent, dialogData);
        let dialogRef: MatDialogRef<any>;
        if (this.responsiveService.isScreenSmall()) {
            dialogRef = this.dialog.open(dialogComponent, {
                data: dialogData,
                maxWidth: '100vw',
                height: '90%',
                width: '100%',
                panelClass: 'small-screen-modal-full',
                autoFocus: 'dialog' // make sure the focus is not on some random button button still on the dialog
            });
        } else {
            const width = bigScreenWidth ? bigScreenWidth : '1000px'
            dialogRef = this.dialog.open(dialogComponent, {
                data: dialogData,
                width: width,
                maxWidth: '1200px',
                panelClass: 'big-screen-modal',
                autoFocus: 'dialog' // make sure the focus is not on some random button button still on the dialog

            });
        }
        return dialogRef;
    }
    public openSmall(dialogComponent: ComponentType<any>, dialogData?: any,): MatDialogRef<any> {
        return this.open(dialogComponent, dialogData, '500px')
    }

    public openMedium(dialogComponent: ComponentType<any>, dialogData?: any,): MatDialogRef<any> {
        return this.open(dialogComponent, dialogData, '800px')
    }

}
