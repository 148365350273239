<div [ngClass]="responsiveService.getBigSmall()" class="results-dialog-container">
    <div class="top-bar">
        <button mat-icon-button (click)="dialogRef.close()">
            <mat-icon class="full-screen">fullscreen_exit</mat-icon>
        </button>
    </div>
    <h1>
        <img src="assets/images/scrum-poker.png" i18n-alt alt="Scrum Poker for Planning" height="46" width="46">
        <span *ngIf="!responsiveService.isScreenSmall()">
            {{getHeaderText()}}
        </span>
    </h1>
    <mat-dialog-content class="results-container">
        <results [isDialog]="true"></results>
    </mat-dialog-content>
</div>
