import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RoomIdService } from './room-id.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AuthService } from '../user/auth.service';
import { UIMesageService } from '../common/ui-message.service';
import { EstimateService } from './estimate.service';
import { NGXLogger as LoggerService } from "ngx-logger";
import { GoogleAnalyticsService } from '../common/google-analytics.service';
import { UserService } from '../user/user.service';
import { ResponsiveService } from '../common/responsive.service';

@Component({
    selector: 'login-gate',
    templateUrl: './login-gate.component.html',
    styleUrls: ['./login-gate.component.scss']

})
export class LoginGateComponent implements OnInit {
    enterRoomForm: FormGroup;
    public roomId: FormControl;
    public newDeveloperName: FormControl;

    constructor(
        public roomIdService: RoomIdService,
        private estimateService: EstimateService,
        public authService: AuthService,
        private uiMessage: UIMesageService,
        private logger: LoggerService,
        private googleAnalyticsService: GoogleAnalyticsService,
        private userService: UserService,
        public responsiveService: ResponsiveService,
        private router: Router


    ) {
    }

    ngOnInit() {
        this.newDeveloperName = new FormControl(
            '',
            [Validators.required]
        );
        this.enterRoomForm = new FormGroup({
            newDeveloperName: this.newDeveloperName,

        });
    }

    getDeveloperNameErrorMessage() {
        return this.newDeveloperName.hasError('required') ? 'Please enter your name' :
            '';
    }

    cancel() {
        this.router.navigate(['/index']);
    }

    async enterRoom(formValues) {
        this.logger.log('logingate: enterRoomForm');
        if (this.enterRoomForm.valid) {
            try {
                this.logger.log('logingate: signUpAnonymous befor');
                await this.authService.signUpAnonymous(this.newDeveloperName.value, false);
                this.logger.log('logingate: signUpAnonymous after');
                const roomId = this.roomIdService.getCurrentRoomID();
                const result = await this.estimateService.isExistentRoom(roomId);
                if (result) {
                    this.googleAnalyticsService.eventEmitter('Login Gate', 'Poker Room', 'enterRoom Action');
                } else {
                    this.uiMessage.warning('A room with Id ' + formValues.roomId + ' does not exist.');
                }
            } catch (error) {
                this.uiMessage.error(error, 'Entering room failed. ');
            }
        } else {
            this.uiMessage.warning('Form not valid');
        }
    }

}
