<div [ngClass]="responsiveService.getBigSmall()" class="component-container">
    <ng-container *ngIf="!responsiveService.isScreenSmall()">
        <!-- User  LOGGED in -->
        <span *ngIf="false else NO_PHOTO">
            <!-- User  has a Photo -->
            <!--  <img [src]="user?.photoURL" class="profile-pic" alt="User Picture">-->
        </span>

        <!-- User  has NO Photo -->
        <ng-template #NO_PHOTO>
            <span class="initials">
                <button mat-mini-fab class="intitials-button" aria-label="Setting" [matMenuTriggerFor]="menu">
                    {{userService.getDisplayName()| getInitials}}
                </button>
            </span>
        </ng-template>
        <mat-menu #menu="matMenu">
            <button *ngIf="authService.isAnonymous" mat-menu-item routerLink="/user/account/signup" class="menu-button">
                <mat-icon>person_add</mat-icon>
                <span i18n>Sign up</span>
            </button>
            <button mat-menu-item routerLink="/user/account/profile" class="menu-button">
                <mat-icon>person</mat-icon>
                <span i18n>Profile</span>
            </button>
            <button *ngIf="!authService.isAnonymous" mat-menu-item routerLink="/user/account/settings"
                class="menu-button">
                <mat-icon>settings</mat-icon>
                <span i18n>Settings</span>
            </button>
            <button *ngIf="!authService.isAnonymous" mat-menu-item routerLink="/user/account/upgrade"
                class="menu-button">
                <mat-icon>upgrade</mat-icon>
                <span i18n>Upgrade</span>
            </button>
            <button mat-menu-item (click)="logout()" class="menu-button">
                <mat-icon>logout</mat-icon>
                <span i18n>Logout</span>
            </button>
        </mat-menu>

    </ng-container>

    <!-- Menu-->
    <nav-menu></nav-menu>


</div>