import { Injectable } from '@angular/core';

interface EssErrorLogger {
    log(message?: any | (() => any), ...additional: any[]): void;
    warn(message?: any | (() => any), ...additional: any[]): void;
    error(message?: any | (() => any), ...additional: any[]): void;

}




/**
 * An error class that automatically logs the error that is thrown
 */
export class EssError extends Error {
    private _logger: EssErrorLogger
    constructor(logger: EssErrorLogger, message: string, logError?: boolean) {
        super(message); // Pass the message to the Error constructor
        // Set the prototype explicitly to maintain the instanceof functionality
        Object.setPrototypeOf(this, EssError.prototype);
        // Set the name property to the name of the custom error class
        this.name = 'EssError';
        this._logger = logger
        if (logError) this._logger.error(this.stack)
    }
}

@Injectable({ providedIn: 'root' })
class LoggerServiceInterface {
    constructor(
    ) {
    }
    log(message?: any | (() => any), ...additional: any[]): void { };
    warn(message?: any | (() => any), ...additional: any[]): void { };
    error(message?: any | (() => any), ...additional: any[]): void { };

}
/**
 * An error service that to create an Error object that automaticall log the error
 */

@Injectable({ providedIn: 'root' })
export class EssErrorService {
    private _logger: LoggerServiceInterface
    private _logError: boolean
    constructor(
    ) {
    }
    public init(loggerService: LoggerServiceInterface, logError: boolean) {
        this._logger = loggerService;
        this._logError = logError
    }

    newError(message, logError?: boolean) {
        if (logError === undefined) return new EssError(this._logger, message, this._logError)
        return new EssError(this._logger, message, logError)
    }
}
