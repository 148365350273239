<div [ngClass]="responsiveService.getBigSmall()">
  <ess-dialog-title>
    {{data.title}}
  </ess-dialog-title>
  <mat-dialog-content class="dialog-content">
    <div *ngIf="data.description1" class="description">{{data.description1}}</div>
    <div *ngIf="data.description2" class="description">{{data.description2}}</div>
    <div *ngIf="data.description3" class="description">{{data.description3}}</div>
  </mat-dialog-content>
  <ess-dialog-actions confirmLabel="{{data.okLabel}}" [showCancel]="true" (confirmEvent)="dialogRef.close(data)">
  </ess-dialog-actions>