import { Injectable } from '@angular/core';
import { SnackService } from './snack.service';
import { NGXLogger as LoggerService } from "ngx-logger";


@Injectable()
export class UIMesageService {

    constructor(
        private snack: SnackService,
        private logger: LoggerService

    ) {

    }
    success(message: string, title?: string) {
        this.logger.log(message, title);
        this.snack.open(message, this.snack.config('success'));
    }
    info(message: string, title?: string) {
        this.logger.log(message, title);
        this.snack.open(message, this.snack.config('info'));
    }
    warning(message: string, title?: string) {
        this.logger.warn('UI warning  message:' + message, title)
        this.logger.warn(message, title);
        this.snack.open(message, this.snack.config('warning'));
    }
    error(error: Error, uiMessage?: string) {
        this.snack.open(uiMessage ? uiMessage : error.message, this.snack.config('error'), null);
        if (uiMessage) {
            this.logger.warn('UI error message: ' + uiMessage)
        }
        this.logger.error(error);
    }

}
