import { Component, OnInit } from '@angular/core';
import { MatProgressBar, ProgressBarMode } from '@angular/material/progress-bar';

@Component({
    selector: 'app-progress-bar',
    templateUrl: './progress-bar.component.html',
    styleUrls: ['./progress-bar.component.scss']
})
export class ProgressBarComponent implements OnInit {

    color = 'warn';

    mode: ProgressBarMode = 'indeterminate';

    constructor() { }

    ngOnInit() {
    }

}
