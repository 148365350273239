<div [ngClass]="responsiveService.getBigSmall()">
  <h1 i18n>
    Choose the Language for your Planning Poker Session
  </h1>
  <h2 i18n>
    Each team mate can choose his own languages playing in the same poker room.
  </h2>

  <ul class="language-list flex-container">
    <li class="item">
      <a href="/en{{roomIdService.getUrlHome()}}">
        <figure class="flag">
          <img src="assets/images/flags/en.png" alt="English">
        </figure>
        <span i18n class="language-text">English</span>
      </a>
    </li>
    <li class="item">
      <a href="/es{{roomIdService.getUrlHome()}}">
        <figure class="flag">
          <img src="assets/images/flags/es.png" alt="Spanish">
        </figure>
        <span i18n class="language-text">Spanish - Español</span>
      </a>
    </li>
    <li class="item">
      <a href="/de{{roomIdService.getUrlHome()}}">
        <figure class="flag">
          <img src="assets/images/flags/de.png" alt="German">
        </figure>
        <span i18n class="language-text">German - Deutsch</span>
      </a>
    </li>
    <li class="item">
      <a href="/fr{{roomIdService.getUrlHome()}}">
        <figure class="flag">
          <img src="assets/images/flags/fr.png" alt="French">
        </figure>
        <span i18n class="language-text">French / Francés</span>
      </a>
    </li>
    <li class="item">
      <a href="/it{{roomIdService.getUrlHome()}}">
        <figure class="flag">
          <img src="assets/images/flags/it.png" alt="Italien">
        </figure>
        <span i18n class="language-text">Italien / Italiano</span>
      </a>
    </li>
    <li class="item">
      <a href="/pt{{roomIdService.getUrlHome()}}">
        <figure class="flag">
          <img src="assets/images/flags/pt.png" alt="Portugiese">
        </figure>
        <span i18n class="language-text">Portugiese / Português</span>
      </a>
    </li>
  </ul>
</div>