import { Pipe, PipeTransform } from '@angular/core';
import { ResponsiveService } from '../../core/responsive.service';
const short = 25;
const medium = 55;
@Pipe({
    name: 'shortTitle',
})
export class ShortTitlePipe implements PipeTransform {
    constructor(public responsiveService: ResponsiveService) { }
    transform(value: string, length?: number) {
        if (!length) {

            if (this.responsiveService.isScreenSmall()) {
                if (value?.length > short) {
                    return value.substring(0, short) + '...';
                } else {
                    return value;
                }
            }

            if (value?.length > medium) {
                return value.substring(0, medium) + '...';
            } else {
                return value;
            }
        } else {
            if (value?.length > length) {
                return value.substring(0, length) + '...';
            } else {
                return value;
            }
        }

    }
}

@Pipe({
    name: 'mediumTitle',
})
export class MediumTitlePipe implements PipeTransform {
    transform(value: string) {
        if (value?.length > medium) {
            return value.substring(0, medium) + '...';
        } else {
            return value;
        }
    }
}
