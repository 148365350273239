<div *ngIf="!isClosed &&  !userService.getUserMessageRead(alertId)" [ngClass]="responsiveService.getBigSmall()"
    [ngClass]="type" class="component-container">
    <mat-card class="card">
        <div class="whoo-left-right">
            <span class="message">{{title}}</span>
            <span (click)="close()" class="x-close">X</span>

        </div>
        <div *ngIf="messageLine1" class="description">{{messageLine1}}</div>
        <div *ngIf="messageLine2" class="description">{{messageLine2}}</div>
        <div *ngIf="messageLine3" class="description">{{messageLine3}}</div>
        <div *ngIf="messageLine4" class="description">{{messageLine4}}</div>
        <div *ngIf="goToText" class="whoo-left-right">
            <span></span>
            <span class="buttons">
                <button mat-button (click)="goToRoute()" color="accent">{{goToText}}</button>
            </span>
        </div>
    </mat-card>

</div>