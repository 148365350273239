import { Component, Input, Output, EventEmitter } from '@angular/core';
import { LoggerService } from '../../core/logger.service';
import { ResponsiveService } from '../../core/responsive.service';

@Component({
    selector: 'ess-dialog-title',
    templateUrl: './dialog-title.component.html',
    styleUrls: ['./dialog-title.component.scss']
})
export class EssDialogTitle {
    @Input() showClose = true;
    @Output() closeEvent = new EventEmitter<void>() /** Callback when dialog is closed */;
    @Input() action1Icon: string;
    @Output() action1Event = new EventEmitter<void>()

    constructor(
        private logger: LoggerService,
        public responsiveService: ResponsiveService,

    ) {
    }

    get showBottonContainer(): boolean {
        return this.showClose || !!this.action1Icon
    }

    action1(): void {
        this.logger.log('action1')
        this.action1Event.emit()
    }

    close(): void {
        this.logger.log('close');
        this.closeEvent.emit();
    }

}
