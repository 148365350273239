import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { RoomIdService } from '../poker-room/room-id.service';
import { ResponsiveService } from '../common/responsive.service';


export interface DialogData {
    dummy: string;
}

@Component({
    selector: 'qr-code-dialog',
    templateUrl: 'qr-code-dialog.component.html',
    styleUrls: ['qr-code-dialog.component.scss']
})

export class QrCodeDialogComponent {
    code = 'AAA-BBB-CCC';

    constructor(
        public roomIdService: RoomIdService,
        public responsiveService: ResponsiveService,
        public dialogRef: MatDialogRef<QrCodeDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
        public dialog: MatDialog
    ) {
    }

    onNoClick(): void {
        this.dialogRef.close();
    }
}
