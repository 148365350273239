
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { EstimateService } from '../poker-room/estimate.service';
import { AuthService } from '../user/auth.service';
import { NGXLogger as LoggerService } from "ngx-logger";
import { ResponsiveService } from './responsive.service';

//declare const window: any;
declare global {
    interface Window {
        fusetag: any;
        enableFooterSticky: boolean
    }
}
const fuseIdsMobile = [
    '22851570040',
    '22851418830',
];

const fuseIdsDesktop = [
    '22851570049',
    '22851570043',
    '22851570046',
    '22851569437',
];

const fuseScriptUrl = "https://cdn.fuseplatform.net/publift/tags/2/2981/fuse.js"
const fuseScriptStaging = `// Fuse script for staging only
window.googletag = window.googletag || { cmd: [] };
googletag.cmd?.push(function () {
googletag.pubads().set("page_url", "scrumpoker-online.org");
});`

@Injectable()
export class AdvertisingService {
    _scriptsLoaded = false;
    private primisScriptUrl = 'https://live.primis.tech/live/liveView.php?s=118424&schain=1.0,1!publift.com,[01GJ4EMKMTHPC2HDAQA9BPR4ZX],1'
    constructor(
        private authService: AuthService,
        private logger: LoggerService,
        public responsiveService: ResponsiveService,
        public estimateService: EstimateService,


    ) {
    }

    public init() {
        this.setStickyFooter(false); // off when starting , enable afater entering a room only
        if (!this._scriptsLoaded) { // make sure script loaded only once
            this._scriptsLoaded = true;
            if (this.fuseEnabled) {
                this.logger.log("init Fuse :loadScript")
                this.loadScript(fuseScriptUrl)
                if (!environment.production) {
                    this.loadFuseScriptForStaging()
                }
            }
        }
    }

    get enableAdds(): boolean {
        return !this.authService.hasPremium() && !this.estimateService.ownerHasPremium
    }

    get showPrimisAddBlocks(): boolean {
        return this.primisEnabled
    }

    get primisEnabled(): boolean {
        return environment.enablePrimis && this.enableAdds
    }

    get fuseEnabled(): boolean {
        return environment.enableFuse && this.enableAdds
    }

    get showFuseAddBlocks(): boolean {
        return this.fuseEnabled && !this.showPrimisAddBlocks
    }


    private loadScript(scriptUrl: string) {
        try {
            let node = document.createElement('script');
            node.type = 'text/javascript';
            node.async = true;
            node.src = scriptUrl;
            document.getElementsByTagName('head')[0].appendChild(node);
        } catch (error) {
            this.logger.error('loadScript domElementId', error)
        }
    }

    public loadPrimisScriptToDomElement(domElementId: string) {
        this.loadScriptToDomElement(this.primisScriptUrl, domElementId)
    }

    private loadScriptToDomElement(scriptUrl: string, domElementId: string) {
        try {
            let node = document.createElement('script');
            node.type = 'text/javascript';
            node.async = true;
            node.src = scriptUrl;
            document.getElementById(domElementId)?.appendChild(node)
        } catch (error) {
            this.logger.error('loadScriptToDomElement domElementId', domElementId, error)
        }
    }


    private loadFuseScriptForStaging() {
        this.logger.log('loadFuseScriptForStaging')
        try {
            let node = document.createElement('script');
            node.type = 'text/javascript';
            node.text = fuseScriptStaging;
            document.getElementsByTagName('head')[0]?.appendChild(node);
        } catch (error) {
            this.logger.error('loadFuseScriptForStaging', error)
        }


    }


    /**
     *You can control where and when the sticky footer becomes active through the script below. If window.enableFooterSticky = true, then the ad unit is active, if false, then the ad unit is not active.
    Through this condition you can control the sticky ad unit to be active on all non-premium pages. For premium users, you can exclude the script.
    <script type="text/javascript" id="publift-footer-sticky">window.enableFooterSticky = true;</script>
    Because the site is SPA, the script needs to execute before registerZone() and pageInit().
     */
    private setStickyFooter(stickyFooter: boolean) {
        window.enableFooterSticky = stickyFooter;
    }

    get fuseIds() {
        let fuseIds: string[];
        if (this.responsiveService.isScreenSmall()) {
            fuseIds = fuseIdsMobile
        } else {
            fuseIds = fuseIdsDesktop
        }
        return fuseIds
    }

    public registerFuseTag() {
        const fusetag = window.fusetag || (window.fusetag = { que: [] });
        const fuseIds = this.fuseIds
        if (this.fuseEnabled) {
            this.logger.log('registerFuseTag fuseEnabled ')
            this.setStickyFooter(true);
            fusetag.que.push(function () {
                fusetag.pageInit({
                    // Defer the first auction until FuseIds are registered
                    blockingFuseIds: fuseIds,
                });
            });
            fuseIds.forEach(id => {
                fusetag.que.push(function () {
                    fusetag.registerZone(id)
                });
            })
        } else {
            this.logger.log('setStickyFooter(false)')
            this.setStickyFooter(false);
            if (fusetag) {
                fusetag.pageInit()
            }

        }

    }

}
