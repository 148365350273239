import { Injectable } from "@angular/core";
import { NGXLogger as LoggerService } from "ngx-logger";
import { UserService } from "../user/user.service";
import { firstValueFrom } from "rxjs";
import { LoginGateDialog } from "./login-gate-dialog.component";
import { EssDialogService } from "ngx-essentia";

@Injectable()
export class LoginGateDialogService {
    constructor(
        private logger: LoggerService,
        private userService: UserService,
        private dialogService: EssDialogService,

    ) { }

    async openDialog() {
        this.logger.log('openLoginDialog')
        try {
            const dialogRef = this.dialogService.openMedium(LoginGateDialog, {
                autoFocus: 'dialog', // make sure the focus is not on some random button button still on the dialog
                disableClose: true
            });
            const isConfirmed = await firstValueFrom(dialogRef.afterClosed());
            if (isConfirmed === true) {
                this.logger.log('The dialog was closed', isConfirmed);
            }
        } catch (error) {
            this.logger.error('error: openLoginDialog')
        }
    }
}
