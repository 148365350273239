import { Injectable, OnInit } from '@angular/core';
import { NGXLogger as LoggerService } from "ngx-logger";
import * as firebase from 'firebase/compat/app';
import { AngularFireDatabase, } from '@angular/fire/compat/database';
import 'firebase/firestore';
import { UIMesageService } from './ui-message.service';
import { SnackService } from './snack.service';

@Injectable()
export class ConnectionStatusService {
    private _isConnected: boolean;

    constructor(
        private logger: LoggerService,
        private uiMessage: UIMesageService,
        private snackService: SnackService,
        private afd: AngularFireDatabase,

    ) {
        this._isConnected = false;
        this.logger.log('ConnectionStatusService.constructor');
        this.setConnectionCallback();
    }
    private setConnectionCallback() {
        const connectedRef = this.afd.database.ref('.info/connected');
        connectedRef.on('value', (snap) => {
            if (snap.val() === true) {
                this.logger.log('Connection to the server (firebase) established.');
                // this.uiMessage.warning("You are now connected to our server and you are ready to start your poker session.")
                this.snackService.closeOfflineMessage();
                this._isConnected = true;
            } else {
                const message = 'We are trying to connect to our server (https://firestore.googleapis.com). Please check your internet connection or your firewall configuration.';
                //    this.snackService.openOfflineMessage(message)
                this.logger.log(message);
                this._isConnected = false;
            }
        });
    }
    public isConnected() {
        if (this._isConnected) {
            return true;
        } else {
            return false;
        }
    }

}
