<div [ngClass]="responsiveService.getBigSmall()">
    <div class="toolbar-inner">
        <div class="row">

            <!-- column left column left column left column left -->
            <div class="column left">
                <span class="home-button">
                    <a routerLink="{{roomIdService.getUrlHome()}}">
                        <img src="assets/images/scrum-poker.png" i18n-alt alt="Scrum Poker for Planning" height="36"
                            width="36">
                        <span *ngIf="!responsiveService.isScreenSmall()">
                            &nbsp;{{getHomeButtonName()}}
                        </span>
                    </a>
                </span>
            </div>

            <!-- column middle column middle column middle column middle -->
            <div class="column middle">
                <div class="auto-margin">
                    &nbsp;
                    <span class="room-id" *ngIf="!roomIdService.isRoomIdEmpty()">
                        <!-- sho room number if exists -->
                        <span *ngIf="!responsiveService.isScreenSmall()">
                            <button mat-button (click)="openDialog()" aria-label="Share" class="share-button">
                                Room {{roomIdService.getCurrentRoomID() | room }}
                                <mat-icon>share</mat-icon>
                            </button>
                        </span>
                        <span *ngIf="responsiveService.isScreenSmall()">
                            <button mat-button (click)="openDialog()" aria-label="Share" class="share-button">
                                {{roomIdService.getCurrentRoomID() | room }}
                            </button>
                        </span>
                    </span>
                </div>
            </div>

            <!-- column right column right column right column right -->
            <div class="column right">
                <div class="right-float">

                    <span *ngIf="userService.isLoggedIn() else guest">
                        <!-- User  LOGGED in -->
                        <nav-logged-in></nav-logged-in>
                    </span>

                    <!-- User NOT logged in -->
                    <ng-template #guest>
                        <nav-not-logged-in></nav-not-logged-in>
                    </ng-template>

                </div>
            </div>
        </div>
    </div>
</div>