import { User } from './user.model';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { NGXLogger as LoggerService } from "ngx-logger";
import { UserFdbService } from './user.fdb.service';
import firebase from 'firebase/compat/app';

const messagePrefix = "messageRead-"
@Injectable()
export class UserService {
    constructor(
        private userFireService: UserFdbService,
        private logger: LoggerService,
    ) {
        this.logger.log('UserService:constructor');
    }
    public setUserObservable(userObservable: Observable<User | firebase.User>): void {
        this.userFireService.setUserObservable(userObservable);
    }
    public getUserDocObservable(uid: string): Observable<User | firebase.User> {
        return this.userFireService.getUserDocObservable(uid);
    }

    public async updateUserDoc(fbUser: firebase.User, syncName: boolean, nameParam: string, generateRoom: boolean): Promise<void> {
        return this.userFireService.updateUserDoc(fbUser, syncName, nameParam, generateRoom);
    }

    public async createAndAssignUserRoom(uid: string, isAnonymous: boolean) {
        const roomId = await this.userFireService.createAndAssignUserRoom(uid, isAnonymous);
        return roomId;
    }

    public async setRoomId(roomId: string) {
        this.userFireService.setRoomId(roomId)
    }
    // setting this.currentUser
    public subscribeToUserDocument(): Promise<boolean> {
        return this.userFireService.subscribeToUserDocument();
    }
    public async updateDispalyName(displayName: string): Promise<void> {
        this.userFireService.updateDispalyName(displayName);
    }

    public async markForDeletion(): Promise<void> {
        this.userFireService.markForDeletion();
    }

    public async unMarkForDeletion(): Promise<void> {
        this.userFireService.unMarkForDeletion();
    }

    public isLoggedIn(): boolean {
        if (this.userFireService.currentUser) {
            return true;
        } else {
            //      this.logger.log("No currentUser set")
            return false;
        }
    }

    public isPasswordAccount(): boolean {
        if (this.userFireService.currentUser) {
            if (this.userFireService.currentUser.providerId == 'password') {
                return true;
            }
        } else {
            this.logger.log('No currentUser set');
        }
        return false;
    }
    public getDisplayName(): string {
        if (this.userFireService.currentUser) {
            return this.userFireService.currentUser.displayName ? this.userFireService.currentUser.displayName : '';
        } else {
            const message = 'getDisplayName: No currentUser set';
            this.logger.error(message);
            throw ('message');
        }
    }
    public hasPhotoURL(): boolean {
        if (this.userFireService.currentUser) {
            // this.logger.log("hasPhotoURL: " + this.userFireService.currentUser.photoURL )
            return (this.userFireService.currentUser.photoURL != null);
        }
    }
    public getPhotoURL(): string {
        if (this.userFireService.currentUser && this.hasPhotoURL()) {
            return this.userFireService.currentUser.photoURL;
        } else {
            const message = ':getPhotoURL: No currentUser set';
            this.logger.error(message);
            throw ('message');
        }
    }
    public getUid(): string {
        if (this.userFireService.currentUser) {
            return this.userFireService.currentUser.uid;
        } else {
            const message = ':getUid: No currentUser set';
            this.logger.error(message);
            throw ('message');
        }
    }
    public getEmail(): string {
        if (this.userFireService.currentUser) {
            return this.userFireService.currentUser.email;
        } else {
            const message = 'getDisplayName: No currentUser set';
            this.logger.error(message);
            throw ('message');
        }
    }

    public async updateUserMessageRead(id: string) {

        await this.userFireService.updateUserMessageRead(messagePrefix + id);
    }

    public getUserMessageRead(id: string): boolean {
        if (this.userFireService.currentUser && this.userFireService.currentUser[messagePrefix + id]) {
            return true;
        } else {
            return false;

        }
    }

    public getRoomId(): string {
        return this.userFireService.currentUser?.pokerRoomId;
    }

    get hasRoom(): boolean {
        const roomId = this.getRoomId();
        if (roomId === null || roomId === "") {
            return false
        }
        return true;
    }

    public getUserObservable(): Observable<User | firebase.User> {
        return this.userFireService.getUserObservable();
    }
    // this works only because there are no sub collection for user docs
    public async deleteOldUsers(): Promise<void> {
        this.userFireService.deleteOldUsers();
    }
    public logout(): void {
        this.logger.log('logout')
        this.userFireService.currentUser = null;
        this.userFireService.logout();
        //  this.currentUserObservable = null
    }
}
