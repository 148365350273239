import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { RoomIdService } from '../poker-room/room-id.service';
import { AuthService } from '../user/auth.service';
import { UIMesageService } from '../common/ui-message.service';
import { UserService } from '../user/user.service';
import { NGXLogger as LoggerService } from "ngx-logger";
import { ResponsiveService } from '../common/responsive.service';
import { MatDialog } from '@angular/material/dialog';
import { CreateRoomDialogComponent } from './create-room-dialog.component';
import { GoogleAnalyticsService } from '../common/google-analytics.service';
import { QrCodeDialogComponent } from '../qr-code/qr-code-dialog.component';
import { LOCALE_ID, Inject } from '@angular/core';
import { environment } from '../../environments/environment';

@Component({
    selector: 'how-it-works',
    templateUrl: './how-it-works.component.html',
    styleUrls: ['./how-it-works.component.scss']
})

export class HowItWorksComponent implements OnInit {
    animal: string;
    name: string;
    constructor(

        @Inject(LOCALE_ID) public locale: string,
        private router: Router,
        private roomIdService: RoomIdService,
        public authService: AuthService,
        private uiMessage: UIMesageService,
        private logger: LoggerService,
        private route: ActivatedRoute,
        public userService: UserService,
        public responsiveService: ResponsiveService,
        public dialog: MatDialog,
        private googleAnalyticsService: GoogleAnalyticsService,

    ) {

    }

    ngOnInit() {
    }

    get whoodleUrl() {
        if (this.locale && this.locale !== '' && this.locale !== 'en-US') {
            return environment.whoodleUrl + '/' + this.locale;
        } else {
            return environment.whoodleUrl;
        }
    }


}

