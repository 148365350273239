import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { RoomIdService } from '../poker-room/room-id.service';
import { ResponsiveService } from '../common/responsive.service';
import { NGXLogger as LoggerService } from "ngx-logger";

export interface DialogData {
    name: string;
}

@Component({
    selector: 'create-room-dialog',
    templateUrl: 'create-room-dialog.component.html',
    styleUrls: ['create-room-dialog.component.scss']
})

export class CreateRoomDialogComponent {
    constructor(
        public logger: LoggerService,
        public roomIdService: RoomIdService,
        public responsiveService: ResponsiveService,
        public dialogRef: MatDialogRef<CreateRoomDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
        public dialog: MatDialog
    ) {
    }
    public isValid() {
        if (this.data && this.data.name && this.data.name.length >= 2) {
            return true;
        } else {
            return false;
        }
    }
    onNoClick(): void {
        this.dialogRef.close();
    }
}
