import { Injectable } from '@angular/core';
import { SnackService } from './snack.service';
import { LoggerService } from './logger.service';

@Injectable({
    providedIn: 'root',
})
export class UIMesageService {
    constructor(private snack: SnackService, private logger: LoggerService) {}

    success(message: string, title?: string) {
        this.logger.log(message, title);
        this.snack.open(message, this.snack.config('success'));
    }
    info(message: string, title?: string) {
        this.logger.log(message, title);
        this.snack.open(message, this.snack.config('info'));
    }
    warning(message: string, title?: string) {
        this.logger.log(message, title);
        this.snack.open(message, this.snack.config('warning'));
    }
    error(message: string, error?: string) {
        this.logger.error(message, error);
        /**@todo */
        //        this.googleAnalyticsService.eventEmitter(message + error, 'UI Message Error', '');
        if (error) {
            this.snack.open(message + ' ' + error, this.snack.config('error'));
        } else {
            this.snack.open(message, this.snack.config('error'));
        }
    }

    dismiss() {
        this.snack.dismiss();
    }
}
