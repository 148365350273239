<div [ngClass]="responsiveService.getBigSmall()">

    <h1 mat-dialog-title i18n>Create your Instant Room</h1>
    <div mat-dialog-content>
        <p i18n>Set-up in seconds. Use the instant room feature or sign-up to keep the same room number for future
            plannings - making the set-up even faster. All we need is a display name.</p>
        <div class="input">
            <div class="spo-field-container">
                <mat-form-field appearance="outline">
                    <mat-label i18n>Display Name</mat-label>
                    <input matInput [(ngModel)]="data.name" required minlength="2">
                    <mat-error i18n>Come on. Your name should have at least two characters.</mat-error>
                </mat-form-field>
            </div>
        </div>
    </div>
    <div class="dialog-actions">
        <div mat-dialog-actions align="end">
            <button mat-button (click)="onNoClick()" i18n>CANCEL</button>
            <span *ngIf="isValid() else NOT_VALID">
                <button mat-flat-button color="primary" [mat-dialog-close]="data.name" i18n
                    class="spo-font-color-white">CREATE ROOM</button>
            </span>
            <ng-template #NOT_VALID>
                <span>
                    <button mat-flat-button color="primary" [disabled]="true" i18n class="spo-font-color-white">CREATE
                        ROOM</button>
                </span>
            </ng-template>
        </div>
    </div>
</div>