import { Component, Input, Output, EventEmitter } from '@angular/core';
import { LoggerService } from '../../core/logger.service';
import { ResponsiveService } from '../../core/responsive.service';

@Component({
    selector: 'ess-dialog-actions',
    templateUrl: './dialog-actions.component.html',
    styleUrls: ['./dialog-actions.component.scss']
})
export class EssDialogActions {
    @Input() showCancel = true;
    @Input() confirmButtonDisabled = false;
    @Input() confirmLabel = '';
    @Output() closeEvent = new EventEmitter<void>() /** Callback when dialog is closed */;
    @Output() confirmEvent = new EventEmitter<void>() /** Callback when dialog is confirmed */;

    constructor(
        private logger: LoggerService,
        public responsiveService: ResponsiveService,

    ) {
    }
    public close(): void {
        this.logger.log('close');
        this.closeEvent.emit();

    }

    public confirm(): void {
        this.logger.log('close');
        this.confirmEvent.emit();

    }
}
