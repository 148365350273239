import { Injectable } from '@angular/core';
import { NGXLogger as LoggerService } from "ngx-logger";
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';


@Injectable()
export class ResponsiveService {
    smallScreen: boolean;

    constructor(
        private logger: LoggerService,
        public breakpointObserver: BreakpointObserver

    ) {
    }
    public setBreakpointObserver() {
        this.breakpointObserver
            .observe(['(min-width: 750px)'])
            .subscribe((state: BreakpointState) => {
                if (state.matches) {
                    this.smallScreen = false;
                    this.logger.log('Viewport is over breakpoint!');
                } else {
                    this.smallScreen = true;
                    this.logger.log('Viewport is below breakpoint!');
                }
            });
    }
    public isScreenSmall(): boolean {
        return this.smallScreen;
    }
    public getBigSmall(): string {
        if (this.isScreenSmall()) {
            //            this.logger.log('getBigSmall . small')
            return 'small-screen';
        } else {
            //          this.logger.log('getBigSmall . big')
            return 'big-screen';
        }
    }

}
