import { Component } from '@angular/core';

@Component({
    templateUrl: './404.page.html',
    styles: [`
  `]
})
export class Error404Page {
    constructor() {

    }

}
