import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { LoggerService } from '../../core/logger.service';
import { ResponsiveService } from '../../core/responsive.service';

export interface DialogData {
    title: string;
    okLabel: string;
    cancelLabel: string;
    description1: string;
    description2: string;
    description3: string;
    label: string;
    maxEntries: string;
    delete: boolean;
    edit: boolean;
}

@Component({
    selector: 'ess-ok-cancel-dialog',
    templateUrl: 'ok-cancel-dialog.component.html',
    styleUrls: ['ok-cancel-dialog.component.scss']
})

export class EssOkCancelDialogComponent {
    constructor(
        public logger: LoggerService,
        public responsiveService: ResponsiveService,
        public dialogRef: MatDialogRef<EssOkCancelDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
        public dialog: MatDialog
    ) {
    }

    onNoClick(): void {
        this.dialogRef.close();
    }
}
