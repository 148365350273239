<a *ngIf="!isVisible" (click)="toggleIsVisible()">
    <span *ngIf="moreLabel">{{moreLabel}}</span>
    <span *ngIf="!moreLabel" i18n> Read more... </span>
</a>

<fade-in-and-out [isVisible]="isVisible" [useNgIf]="false" display="block" maxHeight="none"
    [hideTransitionDuration]="150" [showTransitionDuration]="250" transform="translateY(-24px)">
    <ng-content></ng-content>
    <ng-container *ngIf="isVisible && displayShowLess">
        <a (click)="toggleIsVisible()" *ngIf="lessLabel"> {{lessLabel}} </a>
        <a (click)="toggleIsVisible()" *ngIf="!lessLabel" i18n> (Less...) </a>
    </ng-container>
</fade-in-and-out>
