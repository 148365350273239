import { ActivatedRoute } from '@angular/router';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UIMesageService } from '../common/ui-message.service';
import { NGXLogger as LoggerService } from "ngx-logger";
import { ResponsiveService } from '../common/responsive.service';
import { FormBuilder, FormGroup, UntypedFormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { AuthService } from '../user/auth.service';
import { AngularFireDatabase, AngularFireObject } from '@angular/fire/compat/database';
import firebase from 'firebase/compat/app';


const PATH_TO_USERFEEDBACK = 'user-feedback';

export interface FeedbackMessage {
    displayName?: string;
    email?: string;
    message?: string;
    updatedAt?: any;
    createtAt?: any;
}
@Component({
    selector: 'feedback',
    templateUrl: './feedback.component.html',
    styleUrls: ['./feedback.component.scss']

})
export class FeedbackComponent implements OnInit, OnDestroy {

    public feedbackForm: UntypedFormGroup;
    private userSubscription: Subscription;
    public formHasBeenSubimtted = false;

    constructor(
        private router: Router,
        private uiMessage: UIMesageService,
        private logger: LoggerService,
        private route: ActivatedRoute,
        public responsiveService: ResponsiveService,
        public authService: AuthService,
        private formBuilder: FormBuilder,
        private afd: AngularFireDatabase,
    ) {

    }

    ngOnInit() {
        this.feedbackForm = this.formBuilder.group({
            email: [this.authService.email, [Validators.email]],
            displayName: [this.authService.displayName, []],
            message: ['', [Validators.required]],
        });
        this.subscribeAuthUser();
    }

    ngOnDestroy() {
        if (this.userSubscription) { this.userSubscription.unsubscribe(); }
    }

    private subscribeAuthUser() {
        this.userSubscription = this.authService.afAuthUser$.subscribe(
            afAuthUser => {
                if (afAuthUser) {
                    this.logger.log('subscribeAuthUser: ', afAuthUser);
                    this.logger.log('subscribeAuthUser displayname email', afAuthUser.displayName, afAuthUser.email);
                    this.logger.log('', this.authService.email);
                    this.refreshForm(afAuthUser);

                }
            }
        );
    }


    private refreshForm(afAuthUser: firebase.User) { // refresh required e.g. if we upgrade anoynmous user email and name change
        this.logger.log('refreshForm displayname', afAuthUser.displayName, afAuthUser.email);
        this.feedbackForm.patchValue({
            email: afAuthUser.email,
            displayName: afAuthUser.displayName, // we need to handover the user here because the user in UserService might not be updated yet
        });
    }

    public async submitForm() {
        const formValues = this.feedbackForm.value;
        if (this.feedbackForm.valid) {
            try {
                this.logger.log('submitForm', formValues.displayName, formValues.email, formValues.message);
                await this.persist({
                    displayName: formValues.displayName,
                    email: formValues.email,
                    message: formValues.message
                });
                this.formHasBeenSubimtted = true;
                this.feedbackForm.controls.message.disable();
                this.feedbackForm.controls.email.disable();
                this.uiMessage.success('Your feedback as been submitted successfully. Thank you so much.');

            } catch (error) {
                this.uiMessage.error(error, 'Sorry, something went wrong');
            }
        } else {
            this.uiMessage.warning('Form invalid.');
        }
    }

    private async persist(feedbackMessage: FeedbackMessage): Promise<void> {
        this.logger.log('persist: ', feedbackMessage);
        feedbackMessage.createtAt = (await this.getServerTime()).toJSON();
        const newKey = this.afd.list(PATH_TO_USERFEEDBACK).push({}).key; // this call returns a new key but does not persist because the object is empty
        const ref: AngularFireObject<FeedbackMessage> = this.afd.object(PATH_TO_USERFEEDBACK + '/' + newKey);
        await ref.set(feedbackMessage);
    }


    /**
     * returns current date and time for firebase server
     * @returns current date and time
     */
    public async getServerTime(): Promise<Date> {
        // first save current date-time in the databse and then get it from there
        // (we can not use firebase.database.ServerValue.TIMESTAMP directly because this is just
        // a place holder that transorms to a date only when it's saved in the database)
        const path = PATH_TO_USERFEEDBACK + '/currentTime/';
        await firebase.database().ref(path).update({ time: firebase.database.ServerValue.TIMESTAMP });
        const promise = new Promise<Date>(async (resolve, reject) => {
            try {
                const ref = this.afd.database.ref(path);
                ref.once('value', snapshot => {
                    this.logger.log('getServerTime: ', snapshot.val().time);
                    const date = new Date(snapshot.val().time);
                    resolve(date);
                });
            } catch (error) {
                const errorMessage = 'Could not reach server. ' + error;
                this.logger.error(errorMessage);
                reject(error);
            }
        });
        return promise;
    }
}


