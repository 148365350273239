<div [ngClass]="responsiveService.getBigSmall()" class="component-container">

    <ng-container *ngIf="!responsiveService.isScreenSmall()">
        <span class="sign-up">
            <button mat-flat-button color="primary" routerLink="/user/signup" i18n class="spo-font-color-white">Sign
                up</button>
        </span>
        <span class="login">
            <button mat-flat-button routerLink="/user/login" i18n class="spo-font-color-turkis">Login</button>
        </span>
    </ng-container>
    <!-- Menu-->
    <nav-menu></nav-menu>

</div>