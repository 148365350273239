import { Component, OnDestroy, OnInit, SimpleChanges, Input, OnChanges, Output, EventEmitter } from '@angular/core';
import { NGXLogger as LoggerService } from "ngx-logger";
import { ResponsiveService } from '../common/responsive.service';
import { UserService } from '../user/user.service';
import { LOCALE_ID, Inject } from '@angular/core';
import { EstimateService } from './estimate.service';
import { Router } from '@angular/router';



@Component({
    selector: 'kp-alert',
    templateUrl: './alert.component.html',
    styleUrls: ['./alert.component.scss']
})
export class AlertComponent {
    @Input() alertId: string;
    @Input() title: string; /** Additional content of Alert	 */
    @Input() messageLine1: string; /** Content of Alert	 */
    @Input() messageLine2: string; /** Content of Alert	 */
    @Input() messageLine3: string; /** Content of Alert	 */
    @Input() messageLine4: string; /** Content of Alert	 */
    @Input() closeable: boolean; /** Whether Alert can be closed	 */
    @Input() type = 'info'; /** Type of Alert styles; Type:  success' | 'info' | 'warning' | 'error', default is 'infor' */
    @Input() goToLink: string;
    @Input() goToLinkReplaceLocale = '%locale%';
    @Input() goToText: string;
    @Output() onClose = new EventEmitter<void>() /** Callback when Alert is closed */;

    constructor(
        @Inject(LOCALE_ID) public locale: string,
        private logger: LoggerService,
        public responsiveService: ResponsiveService,
        public userService: UserService,
        private estimateService: EstimateService,
        private router: Router
    ) {
    }
    public close(): void {
        this.estimateService.alertIsClosed = true;
    }
    get isClosed() {
        return this.estimateService.alertIsClosed;
    }

    public goToRoute(): void {
        this.logger.log('goToRoute');
        this.userService.updateUserMessageRead(this.alertId);
        if (this.goToLink.startsWith('http')) {
            let locale = this.locale;
            let url = this.goToLink;
            if (!locale || locale === '' || locale === 'en-US') {
                locale = 'en';
            }
            url = this.replaceAll(this.goToLink, this.goToLinkReplaceLocale, locale);
            window.open(url, '_blank');

        } else {
            this.router.navigate([this.goToLink]);
        }
    }

    private replaceAll(str: string, find: string, replace: string) {
        return str?.replace(new RegExp(find, 'g'), replace);
    }
}
