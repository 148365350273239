import { ClipboardModule } from '@angular/cdk/clipboard';
import { LayoutModule } from '@angular/cdk/layout';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFireDatabaseModule } from '@angular/fire/compat/database';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';


import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatOptionModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';

import { QRCodeModule } from 'angularx-qrcode';
import { AdsenseModule } from 'ng2-adsense';
import { NgxEssentiaModule, NgxLoggerCustomServerService } from 'ngx-essentia';
import { LoggerModule, NgxLoggerLevel, TOKEN_LOGGER_SERVER_SERVICE } from "ngx-logger";


import { environment } from '../environments/environment';
import { AdminPage } from './admin/admin.page';
import { AppComponent } from './app.component';
import { ConnectionStatusService } from './common/connection-status.service';
import { AdvertisingService } from './common/advertising.service';
import { GoogleAnalyticsService } from './common/google-analytics.service';
import { GetInitialsPipe } from './common/initials.pipe';
import { NGXLogger as LoggerService } from "ngx-logger";
import { OkCancelDialogComponent } from './common/ok-cancel-dialog.component';
import { ResponsiveService } from './common/responsive.service';
import { RoomPipe } from './common/room.pipe';
import { SEOService } from './common/seo.service';
import { SnackService } from './common/snack.service';
import { UIMesageService } from './common/ui-message.service';
import { Error404Page } from './errors/404.component';
import { AboutComponent } from './footer/about.component';
import { BlogComponent } from './footer/blog.component';
import { BlogContactComponent } from './footer/blog.contact.component';
import { FeedbackComponent } from './footer/feedback.component';
import { FooterComponent } from './footer/footer.component';
import { LanguageComponent } from './footer/language.component';
import { PrivacyComponent } from './footer/privacy.component';
import { CreateRoomButtonComponent } from './home/create-room-button.component';
import { CreateRoomDialogComponent } from './home/create-room-dialog.component';
import { HomeLoggedInComponent } from './home/home-logged-in.component';
import { HomeNotLoggedInComponent } from './home/home-not-logged-in.component';
import { HomeComponent } from './home/home.page';
import { HowItWorksComponent } from './home/how-it-works.component';
import { PlanningPokerComponent } from './home/planning-poker-home.page';
import { EnterRoomComponent } from './nav/enter-room.component';
import { MenuComponent } from './nav/menu.component';
import { NavBarComponent } from './nav/nav-bar.component';
import { NavLoggedInComponent } from './nav/nav-logged-in.component';
import { NavNotLoggedInComponent } from './nav/nav-not-logged-in.component';
import { AlertComponent } from './poker-room/alert.component';
import { EstimateFdbService } from './poker-room/estimate.fdb.service';
import { EstimateService } from './poker-room/estimate.service';
import { LoginGateComponent } from './poker-room/login-gate.component';
import { PokerRoomPage } from './poker-room/poker-room.page';
import { ProvideEstimateComponent } from './poker-room/provide-estimate.component';
import { ResultsComponent } from './poker-room/results.component';
import { ResultsDialog } from './poker-room/results.dialog';
import { RoomIdService } from './poker-room/room-id.service';
import { ProgressBarComponent } from './progress-bar/progress-bar.component';
import { QrCodeDialogComponent } from './qr-code/qr-code-dialog.component';
import { appRoutes } from './routes';
import { EditTimerDialogComponent } from './timer/edit.timer-dialog.component';
import { TimerComponent } from './timer/timer.component';
import { TimerService } from './timer/timer.service';
import { AuthService } from './user/auth.service';
import { PresenceService } from './user/online-presence.service';
import { DepricatedShopService } from './user/services/depricated-shop.service';
import { UserPresenceComponent } from './user/user-presence.component';
import { UserFdbService } from './user/user.fdb.service';
import { UserService } from './user/user.service';
import { StripeService } from './user/services/stripe.service';
import { USE_EMULATOR as USE_AUTH_EMULATOR } from '@angular/fire/compat/auth';
import { USE_EMULATOR as USE_DATABASE_EMULATOR } from '@angular/fire/compat/database';
import { USE_EMULATOR as USE_FIRESTORE_EMULATOR } from '@angular/fire/compat/firestore';
import { USE_EMULATOR as USE_FUNCTIONS_EMULATOR } from '@angular/fire/compat/functions';
// called with APP_INITIALIZER
export function userProviderFactory(provider: AuthService) {
    return () => provider.loadOnAppInit();
}

@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        RouterModule.forRoot(appRoutes),
        AngularFireModule.initializeApp(environment.firebase),
        AngularFireAuthModule, // imports firebase/auth, only needed for auth features,
        AngularFireDatabaseModule,
        AngularFirestoreModule,

        MatIconModule,
        MatOptionModule,
        MatSelectModule,
        MatDialogModule,
        MatFormFieldModule,
        MatInputModule,
        MatTooltipModule,
        MatButtonModule,
        MatProgressBarModule,
        MatDividerModule,
        MatCardModule,
        MatMenuModule,
        MatTableModule,

        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        FlexLayoutModule,
        BrowserAnimationsModule,
        LayoutModule,
        ClipboardModule,
        QRCodeModule,
        AdsenseModule.forRoot({
            adClient: environment.adClient,
        }),
        NgxEssentiaModule.init({
            isDebug: environment.debug_log,
            loggerPrefix: 'WHOCAN-LIB',
            appName: environment.brandName,
            version: environment.appVersion,
            host: environment.host
        }),
        LoggerModule.forRoot({
            serverLoggingUrl: '/uilogging',
            level: environment.ngxLoggerLevel,
            serverLogLevel: environment.ngxServerLoggerLevel,
            enableSourceMaps: true
        },
            {
                serverProvider: {
                    provide: TOKEN_LOGGER_SERVER_SERVICE, useClass: NgxLoggerCustomServerService
                }
            }
        ),
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: true,
            // Register the ServiceWorker immediatly because the angular standard "registerWhenStable:30000"
            // seems not to work with lighthaouse
            // getting error "Es wurde kein Service Worker erkannt, der die Seite und start_url kontrolliert"
            registrationStrategy: 'registerImmediately',
        }),
    ],
    declarations: [
        AppComponent,
        Error404Page,
        NavBarComponent,
        HomeComponent,
        ProgressBarComponent,
        PokerRoomPage,
        ProvideEstimateComponent,
        ResultsComponent,
        FooterComponent,
        AboutComponent,
        PrivacyComponent,
        RoomPipe,
        EnterRoomComponent,
        AdminPage,
        GetInitialsPipe,
        QrCodeDialogComponent,
        UserPresenceComponent,
        LoginGateComponent,
        CreateRoomDialogComponent,
        CreateRoomButtonComponent,
        OkCancelDialogComponent,
        HomeNotLoggedInComponent,
        HomeLoggedInComponent,
        NavLoggedInComponent,
        NavNotLoggedInComponent,
        FeedbackComponent,
        LanguageComponent,
        HowItWorksComponent,
        PlanningPokerComponent,
        BlogComponent,
        BlogContactComponent,
        MenuComponent,
        AlertComponent,
        ResultsDialog,
        TimerComponent,
        EditTimerDialogComponent,
    ],

    providers: [
        RoomIdService,
        EstimateFdbService,
        EstimateService,
        ConnectionStatusService,
        UIMesageService,
        AuthService,
        UserService,
        UserFdbService,
        PresenceService,
        SnackService,
        LoggerService,
        ResponsiveService,
        SEOService,
        GoogleAnalyticsService,
        DepricatedShopService,
        TimerService,
        AdvertisingService,
        StripeService,

        // for details about APP_INITIALIZER see https://devblog.dymel.pl/2017/10/17/angular-preload/
        // it is basically used to load the user setting before the app initialize
        {
            provide: APP_INITIALIZER,
            useFactory: userProviderFactory,
            deps: [AuthService],
            multi: true,
        },
        // https://github.com/angular/angularfire/blob/master/docs/compat/emulators/emulators.md
        { provide: USE_AUTH_EMULATOR, useValue: environment.useEmulators ? ['http://localhost:9099'] : undefined },// https://www.reddit.com/r/Angular2/comments/piiwvc/angular_not_working_with_firebase_emulators/
        { provide: USE_DATABASE_EMULATOR, useValue: environment.useEmulators ? ['localhost', 9001] : undefined },
        //  { provide: USE_FIRESTORE_EMULATOR, useValue: environment.useEmulators ? ['localhost', 8080] : undefined },
        { provide: USE_FUNCTIONS_EMULATOR, useValue: environment.useEmulators ? ['localhost', 5001] : undefined },
    ],
    bootstrap: [AppComponent],
})
export class AppModule { }
