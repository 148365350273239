<div [ngClass]="responsiveService.getBigSmall()">
    <mat-dialog-content class="dialog-content">
        <span class="spo-hours-container">
            <mat-form-field appearance="fill" class="spo-minutes-select">
                <mat-select [formControl]="minutesControl">
                    <mat-option *ngFor="let minuteOption of minuteOptions" [value]="minuteOption.value">
                        {{minuteOption.label}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <span class="till">:</span>
            <mat-form-field appearance="fill" class="spo-minutes-select">
                <mat-select [formControl]="secondsControl">
                    <mat-option *ngFor="let secondOption of secondOptions" [value]="secondOption.value">
                        {{secondOption.label}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </span>
    </mat-dialog-content>
    <div mat-dialog-actions class="dialog-actions">
        <button mat-stroked-button (click)="onOkClick()" cdkFocusInitial>
            Ok
        </button>
    </div>

</div>