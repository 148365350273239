
export const paths = {
    currentTime: "/currentTime",
    options: "whoo/options",
    optionArray: "/optionArray",
    optionSortMap: "/sortMap",
    header: "/whoo/header",
    votes: "whoo/votes",
    participation: "whoo/participation",
    emails: {
        unsubscribe: "/unsubscribe",
        toBeProcessed: "/emails/toBeProcessed",
        processed: "/emails/processed",
        unsubscribed: "/emails/unsubscribed",
        error: "/emails/error",
    },
    notifications: {
        toBeProcessed: "/notifications/toBeProcessed",
        processed: "/notifications/processed",
        error: "/notifications/error",

    },
    users: "/users",
    feedback: "user-feedback",
    paymments: "/shop/payments",
    activeProducts: "shop/activeProducts", // the active products assgined to the user
    deactivatedProducts: "shop/deactivatedProducts",
    functions: {
        paypalStart: 'shop-paypalStart',
        paypalProcess: 'shop-paypalProcess',
    }
};

