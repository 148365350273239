import { Injectable } from '@angular/core';
import { NGXLogger as LoggerService } from "ngx-logger";
import { AngularFireDatabase, AngularFireList } from '@angular/fire/compat/database';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ActiveProduct, paths } from 'functions-lib';


@Injectable()
export class DepricatedShopService {
    public activeProducts: ActiveProduct[];
    constructor(
        private logger: LoggerService,
        private afd: AngularFireDatabase,
    ) {
    }

    getActiveProductsObvservable(uid: string): Observable<ActiveProduct[]> {
        const ref: AngularFireList<ActiveProduct> = this.afd.list(paths.activeProducts + "/" + uid);
        return ref.valueChanges().pipe(
            tap(activeProducts => {
                this.logger.log('activeProductsObvservable : ', activeProducts);
                this.activeProducts = <ActiveProduct[]>activeProducts;

            }));
    }

    getProduct(productKey: string): ActiveProduct {
        return this.activeProducts?.find(activeProduct => activeProduct.productKey === productKey)
    }


    /**
     * @depricated
     * check for stripe subscription instead
     */
    public async getUserHasPremium(uid: string): Promise<boolean> {
        const promise = new Promise<boolean>(async (resolve, reject) => {
            try {
                const ref = this.afd.database.ref(paths.activeProducts + "/" + uid);
                this.logger.log('getUserHasPremium: uid', uid);
                ref.once('value', snapshot => {
                    if (snapshot.exists()) {
                        this.logger.log('getUserHasPremium: TRUE ');
                        resolve(true);
                    } else {
                        this.logger.log('getUserHasPremium: FALSE');
                        resolve(false);
                    }
                });
            } catch (error) {
                const errorMessage = 'Could not reach server. ' + error;
                this.logger.error(errorMessage);
                reject(error);
            }
        });
        return promise;
    }

    public getEndDate() {
        const product = this.getProduct('premium')
        const endDate = product?.endDate
        if (endDate) return new Date(endDate);
        else return new Date();
    }

}
