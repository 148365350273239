<h1 mat-dialog-title i18n>How can people join the poker room?</h1>
<div mat-dialog-content [ngClass]="responsiveService.getBigSmall()">
    <div>
        <h2 i18n>
            Room {{roomIdService.getCurrentRoomID()}}
        </h2>
        <p><strong i18n>Scan the QR code</strong></p>
        <p class="qr-code" *ngIf="responsiveService.isScreenSmall()">
            <qrcode [qrdata]="roomIdService.getUrlFullPokerRoom()" [width]="150"></qrcode>
        </p>
        <p class="qr-code" *ngIf="!responsiveService.isScreenSmall()">
            <qrcode [qrdata]="roomIdService.getUrlFullPokerRoom()" [width]="250"></qrcode>
        </p>
    </div>
    <div class="other-options">
        <p>{{roomIdService.getUrlFullPokerRoom()}}</p>
        <span class="buttons">
            <button mat-button [cdkCopyToClipboard]="roomIdService.getUrlFullPokerRoom()">
                <mat-icon>file_copy</mat-icon>
                <span i18n>Copy URL to clipboard</span>
            </button>
        </span>
        <button mat-button>
            <a href="mailto:?subject=Link to Scrum Poker Online&body=Hello,%0D%0A%0D%0AHere is the link to the scrum
      poker:%0D%0A%0D%0A{{roomIdService.getUrlFullPokerRoom()}}%0D%0A%0D%0ARegards from %0D%0A%0D%0A
      Scrumpoker-online.org">
                <mat-icon>share</mat-icon>
                <span i18n>Share via email</span>
            </a>
        </button>
    </div>

</div>

<div mat-dialog-actions align="end">
    <span>
        <button mat-flat-button color="primary" [mat-dialog-close]="data" cdkFocusInitial i18n
            class="spo-font-color-white">GOT IT!</button>
    </span>
</div>