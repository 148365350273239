import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { GoogleAnalyticsService } from '../common/google-analytics.service';
import { NGXLogger as LoggerService } from "ngx-logger";
import { OkCancelDialogComponent } from '../common/ok-cancel-dialog.component';
import { ResponsiveService } from '../common/responsive.service';
import { UIMesageService } from '../common/ui-message.service';
import { AuthService } from '../user/auth.service';
import { UserService } from '../user/user.service';
import { IEstimate } from './esimtate.model';
import { EstimateService } from './estimate.service';
import { RoomIdService } from './room-id.service';

@Component({
    selector: 'results',
    templateUrl: './results.component.html',
    styleUrls: ['./results.component.scss']
})

export class ResultsComponent implements OnInit, OnDestroy {
    smallScreen: boolean;
    @Input() ownerHasPremium: boolean = false;

    constructor(
        private router: Router,
        private roomIdService: RoomIdService,
        public estimateService: EstimateService,
        public userService: UserService,
        public authService: AuthService,
        private uiMessage: UIMesageService,
        private logger: LoggerService,
        public responsiveService: ResponsiveService,
        private googleAnalyticsService: GoogleAnalyticsService,
        public dialog: MatDialog,

    ) {
    }
    ngOnInit() {
        try {
        } catch (error) {
            this.uiMessage.error(error, 'Initializing the room failed. Please check your intetnet connection. ');
        }
    }
    ngOnDestroy() {
    }

    clickThumbNail(estimate: IEstimate) {
        if (estimate.isSelected) {
            estimate.isSelected = false;
        } else { estimate.isSelected = true; }
    }
    clearRoom() {
        this.googleAnalyticsService.eventEmitter('clearRoom', 'Poker Room', 'clearRoom Action');
        const dialogRef = this.dialog.open(OkCancelDialogComponent, {
            autoFocus: false,
            restoreFocus: false,
            maxWidth: '500px',
            data: {
                title: 'Clear Room',
                description1: 'Clearing the room of inactive participants, removes all the names from the list but participants can still see the room.',
                description2: 'Each participant reappears once he submits a vote.',
                description3: 'Do you want to continue?',
                okLabel: 'Clear Room',
                cancelLabel: 'Cancel'
            }
        });
        dialogRef.afterClosed().subscribe(result => {
            console.log('The dialog was closed');
            if (result) {
                this.logger.log('result: ' + result);
                this.estimateService.clearRoom(this.roomIdService.getCurrentRoomID());
                this.estimateService.setShowEstimates(this.roomIdService.getCurrentRoomID(), false);
                this.router.navigate([this.roomIdService.getUrlPokerRoom() + '/' + 'clear']);
            } else {
                this.logger.log('Closed without result - hence canceled: ');
            }
        });
    }
    get deleteEstimatesClass() {
        if (this.estimateService.showEstimates) {
            return 'emphasize';
        }
        return 'not-emphasize';
    }

    get showHideClass() {
        if (!this.estimateService.showEstimates) {
            return 'emphasize';
        }
        return 'not-emphasize';
    }
    public toggleShowHideEstimates() {
        if (this.estimateService.showEstimates) {
            this.hideEstimates();
        } else {
            this.showEstimates();

        }
    }



    clearEstimates() {
        this.googleAnalyticsService.eventEmitter('clearEstimates', 'Poker Room', 'clearEstimates Action');
        const dialogRef = this.dialog.open(OkCancelDialogComponent, {
            data: {
                autoFocus: false,
                restoreFocus: false,
                maxWidth: '500',
                title: 'Delete Estimates',
                description1: 'This will clear all estimates in this room.',
                description2: 'Also users that have not submitted an estimate for 30 minutes will be removed.',
                description3: 'Do you want to continue?',
                okLabel: 'DELETE ESTIMATES',
                cancelLabel: 'CANCEL'
            }
        });
        dialogRef.afterClosed().subscribe(result => {
            console.log('The dialog was closed');
            if (result) {
                this.logger.log('result: ' + result);
                this.estimateService.clearStoryPoints(this.roomIdService.getCurrentRoomID());
                this.estimateService.setShowEstimates(this.roomIdService.getCurrentRoomID(), false);
                this.router.navigate([this.roomIdService.getUrlPokerRoom() + '/' + 'delete']);

            } else {
                this.logger.log('CANCELED');
            }
        });
    }
    async showEstimates() {
        try {
            this.googleAnalyticsService.eventEmitter('showEstimates', 'Poker Room', 'showEstimates Action');
            await this.estimateService.setShowEstimates(this.roomIdService.getCurrentRoomID(), true);
            this.router.navigate([this.roomIdService.getUrlPokerRoom() + '/' + 'show']);

        } catch (error) {
            this.uiMessage.error(error);
        }
    }

    async hideEstimates() {
        try {
            this.googleAnalyticsService.eventEmitter('hideEstimates', 'Poker Room', 'hideEstimates Action');
            await this.estimateService.setShowEstimates(this.roomIdService.getCurrentRoomID(), false);
            this.router.navigate([this.roomIdService.getUrlPokerRoom() + '/' + 'hide']);
        } catch (error) {
            this.uiMessage.error(error, 'Hiding estimates failed (setShowEstimates). ');
        }
    }
    public showHideButton(): boolean {
        return this.isOwner() || this.estimateService.allowHide;
    }
    public showClearButton(): boolean {
        return this.isOwner() || this.estimateService.allowClear;
    }
    public showDeleteEstimatesButton(): boolean {
        return this.isOwner() || this.estimateService.allowDeleteEstimates;
    }
    private isOwner(): boolean {
        if (this.roomIdService.getCurrentRoomID() === this.userService.getRoomId()) {
            return true;
        } else {
            return false;
        }
    }


    get flipCardTransform() {
        if (this.estimateService.showEstimates) {
            return 'flip-card-transform';
        } else {
            return '';
        }
    }



}
