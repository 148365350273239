<div [ngClass]="responsiveService.getBigSmall()">
    <div class="spo-container">
        <div class="spo-container-title" i18n>
            About Scrum Poker and Paul & Kai (Impressum)
        </div>
        <div class="spo-container-subtitle" i18n>
            Who are we? Why do we do this?
        </div>
        <div class="spo-container-content">
            <p i18n>
                Once upon a time… Paul and Kai were working in an agile team spread all over the globe.
                To better support the collaboration for refinement meeting, the quest for a scrum poker tool
                for story point estimation started.
            </p>
            <p i18n>
                But most planning poker tools they encountered required complicated set-up and a steep
                learning curve.
                And this even though they only wanted one thing: start estimating in less than 30 seconds…
                Thus they started putting their hands to their keyboards and the rest you can experience
                yourselves.

            </p>
            <p class="spo-center">
                Scrumpoker-online.org<br>
                Goldbacherstr. 38 <br>
                8700 Küsnacht<br>
                Switzerland<br>
                E-Mail: scrumpoker.online@gmail.com
            </p>
        </div>
    </div>


</div>