import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UIMesageService } from '../common/ui-message.service';
import { NGXLogger as LoggerService } from "ngx-logger";
import { ResponsiveService } from '../common/responsive.service';
import { LOCALE_ID, Inject } from '@angular/core';
import { environment } from '../../environments/environment';


@Component({
    selector: 'footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']

})
export class FooterComponent implements OnInit {

    constructor(
        @Inject(LOCALE_ID) public locale: string,

        private router: Router,
        private uiMessage: UIMesageService,
        private logger: LoggerService,
        private route: ActivatedRoute,
        public responsiveService: ResponsiveService
    ) {

    }

    ngOnInit() {
    }

    getLocal() {
        return this.locale.substring(0, 2);
    }

    get version() {
        return environment.appVersion;
    }
}
