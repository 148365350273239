<div [ngClass]="responsiveService.getBigSmall()" #componentContainer>
    <div class="component-container" [ngClass]="timerService.isLastSeconds==true?'warn':''">
        <span *ngIf="!isOwner" class="time-container" i18n-matTooltip
            matTooltip="Timer can be started by the room owner only">
            <span class="minutes">{{timerService.minutes | number: '2.0-0'}}</span><span class="seperator">:</span><span
                class="seconds">{{timerService.seconds | number: '2.0-0'}}</span>
        </span>
        <button mat-button aria-label="Timer" *ngIf="isOwner" (click)="openDialog()">
            <span class="time-container" i18n-matTooltip matTooltip="Click to edit">
                <span class="minutes">{{timerService.minutes | number: '2.0-0'}}</span><span
                    class="seperator">:</span><span class="seconds">{{timerService.seconds | number: '2.0-0'}}</span>
            </span>
        </button>
        <span *ngIf="isOwner" class="owner-actions">
            <button mat-icon-button i18n-matTooltip matTooltip="Reset" (click)="handleClickCancel()"
                [disabled]="!timerService.isRunning && !timerService.isPaused">
                <mat-icon>replay</mat-icon>
            </button>
            <button *ngIf="!timerService.isRunning" mat-icon-button i18n-matTooltip matTooltip="Start"
                (click)="handleClickStart()" [disabled]="timerService.minutes === 0 && timerService.seconds === 0">
                <mat-icon>not_started</mat-icon>
            </button>

            <button *ngIf="timerService.isRunning" mat-icon-button i18n-matTooltip matTooltip="Pause"
                (click)="handleClickPause()">
                <mat-icon>pause_circel</mat-icon>
            </button>
        </span>
    </div>
</div>