import { CommonModule } from '@angular/common';
import { Component, ElementRef, Input, OnChanges, Renderer2, SimpleChanges, ViewChild } from '@angular/core';
import { NGXLogger as LoggerService } from "ngx-logger";
import { FadeInAndOutComponent } from "../fade-in-and-out/fade-in-and-out.component";


@Component({
    selector: 'read-more',
    standalone: true,
    imports: [
        CommonModule,
        FadeInAndOutComponent
    ],
    templateUrl: './read-more.component.html',
    styleUrls: ['./read-more.component.scss'],
})

export class ReadMoreComponent {
    isVisible: boolean = false;
    @Input() moreLabel = ""
    @Input() lessLabel = ""
    constructor(

    ) {
    }

    toggleIsVisible() {
        this.isVisible = !this.isVisible
    }

}
