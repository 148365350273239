import { User } from './user.model';
import { Injectable } from '@angular/core';
import { AngularFireDatabase, AngularFireObject } from '@angular/fire/compat/database';

import { Observable, Subscription } from 'rxjs';
import { EstimateService } from '../poker-room/estimate.service';
import { UIMesageService } from '../common/ui-message.service';
import { NGXLogger as LoggerService } from "ngx-logger";
import firebase from 'firebase/compat/app';


@Injectable()
export class UserFdbService {
    private loadUserSub: Subscription;
    private getUserDocDataSub: Subscription;
    public currentUserObservable: Observable<User | firebase.User>;
    public currentUser: User;
    private isUpdating: boolean;

    constructor(
        private afd: AngularFireDatabase,
        private estimateService: EstimateService,
        private logger: LoggerService,


    ) {
        this.isUpdating = false;
    }
    public setUserObservable(userObservable: Observable<User | firebase.User>): void {
        this.currentUserObservable = userObservable;
    }

    public getUserDocObservable(uid: string): Observable<User | firebase.User> {
        try {
            const userRefObject: AngularFireObject<any> = this.afd.object(`users/${uid}`);
            return userRefObject.valueChanges();
        } catch (error) {
            this.logger.log('Catch getUserDocObservable. ' + error);
            throw (error);
        }
    }

    public async updateUserDoc(fbUser: firebase.User, syncName: boolean, nameParam: string, generateRoom: boolean): Promise<void> {
        this.logger.log('updateUserDoc generateRoom', generateRoom)
        if (!this.isUpdating) {    // make sure updating happens only once at the time
            this.logger.log('updateUserDoc: updating...');
            this.logger.log(fbUser);
            try {
                this.isUpdating = true;
                if (!fbUser) {
                    const message = 'fbUser is null.';
                    this.logger.error(message);
                    throw message;
                }
                const displayName = syncName ? await this.syncDisplayName(fbUser, nameParam) : nameParam;
                let roomId = "";
                if (generateRoom) {
                    roomId = await this.syncUserRoom(fbUser.uid, fbUser.isAnonymous);
                }
                this.logger.log('roomid');
                this.logger.log(roomId);
                let anonymousUpdatedAt = null;
                if (fbUser.isAnonymous) {
                    anonymousUpdatedAt = this.getTimestamp();

                }

                const userDataUpdate: User = {
                    createdBy: 'UserService',
                    uid: fbUser.uid,
                    email: fbUser.email,
                    emailVerified: fbUser.emailVerified,
                    phoneNumber: fbUser.phoneNumber,
                    photoURL: fbUser.photoURL,
                    displayName: displayName,
                    pokerRoomId: roomId,
                    providerId: this.getProviderId(fbUser),
                    isAnonymous: fbUser.isAnonymous,
                    updatedAt: this.getTimestamp(),
                    anonymousUpdatedAt: anonymousUpdatedAt,
                    roles: {
                        default: true
                    }
                };
                const userRef: AngularFireObject<any> = this.afd.object(`users/${fbUser.uid}`);
                await userRef.set(userDataUpdate);
                this.logger.log('updateUserDoc: updating finished');
                //   we need to set the this.currentUser
                await this.subscribeToUserDocument();
                this.isUpdating = false;

            } catch (error) {
                const message = 'Failed to update the profile. Your internet connection might not work. ' + error;
                this.logger.error(message);
                this.isUpdating = false;
                throw (error);
            }
        } else {
            this.logger.log('this.isUpdating');
        }
    }
    private getProviderId(fbUser: firebase.User): string {
        if (fbUser.providerData) {
            if (fbUser.providerData[0]) {
                this.logger.log('provider: ' + fbUser.providerData[0].providerId);
                return fbUser.providerData[0].providerId;
            }
        }
        this.logger.log('provider: ' + fbUser.providerId);
        return fbUser.providerId;
    }
    private getTimestamp() {
        return firebase.database.ServerValue.TIMESTAMP;
    }
    private async syncDisplayName(fbUser: firebase.User, name: string) {
        this.logger.log('syncDisplayName');
        this.logger.log(name);
        // if we have a name param this should be the new displyname
        const isNameParam = (name);
        if (isNameParam) {
            this.logger.log('isName');
            this.logger.log(name);
            return name;
        } else {
            this.logger.log('else');
            try {
                let userDocData: User;
                userDocData = await this.getUserDocData(fbUser.uid);
                //      userDocData = this.currentUser
                if (userDocData.displayName && userDocData.displayName != '') {
                    this.logger.log('userDoc has already a displayName');
                    return userDocData.displayName;
                } else {
                    this.logger.log('else - so we take name from the fBUser');
                    this.logger.log(fbUser.displayName);
                    return fbUser.displayName;
                }
            } catch (error) {// there is no user document
                this.logger.log('Getting profile failed. ' + error);
                return fbUser.displayName;
            }
        }
    }

    public async createAndAssignUserRoom(uid: string, isAnonymous: boolean) {
        const roomId = await this.estimateService.generateRoom(uid, isAnonymous);
        this.setRoomId(roomId)

    }

    public async setRoomId(roomId: string) {
        const uid = this.currentUser.uid;
        this.currentUser.pokerRoomId = roomId
        const userRef: AngularFireObject<any> = this.afd.object(`users/${uid}`);
        const userDataUpdate = { pokerRoomId: roomId }
        await userRef.update(userDataUpdate);
    }

    // check if the user has a poker room - if not generate the room and assign it to the user
    private async syncUserRoom(uid: string, isAnonymous: boolean) {
        let roomId = '';
        let userDocData: User;
        try {
            userDocData = await this.getUserDocData(uid);
        } catch (error) {
            this.logger.log('getUserDocData failed. ' + error);
            userDocData = null;
        }
        //    userDocData = this.currentUser
        // check if curentUser has alreadey a room id if not create the room
        this.logger.log('syncUserRoom');
        if ((userDocData == null) || (userDocData.pokerRoomId == null) || (userDocData.pokerRoomId == '')) {
            this.logger.log('user OR ROOM ID null');
            try {
                roomId = await this.estimateService.generateRoom(uid, isAnonymous);
                return roomId;
            } catch (error) {
                const message = 'Room could not be created. ' + error;
                this.logger.error(message);
                throw (error);
            }
        } else {
            this.logger.log('room exists already');
            return userDocData.pokerRoomId;
        }
    }
    // subscribe to the user document and set this.currentUser
    public subscribeToUserDocument(): Promise<boolean> {
        this.logger.log('subscribeToUserDocument()...');
        return new Promise((resolve, reject) => {
            try {
                if (this.loadUserSub) { this.loadUserSub.unsubscribe(); }
                this.loadUserSub = this.currentUserObservable.subscribe(response => {
                    this.currentUser = response;
                    if (this.currentUser != null) {
                        this.logger.log('subscribeToUserDocument subscription  userDocument is:');
                        this.logger.log(this.currentUser);
                    } else {
                        this.logger.log('subscribeToUserDocument  failed');
                    }
                    resolve(true);
                });
            } catch (error) {
                const errorMessage = 'Loading user profile failed. ' + error;
                this.logger.error(errorMessage);
                reject(error);
            }
        });
    }
    // this does NOT set  this.currentUser but returns the user document data for a specific UID
    // used to check during the synchronisation if the user already exist and what vaules it has
    private getUserDocData(uid: string) {
        this.logger.log('getUserDocData()....');
        return new Promise((resolve, reject) => {
            try {
                if (this.currentUser) {
                    // not sure why we unsubsrcribe at this point
                    if (this.getUserDocDataSub) {
                        this.logger.log('getUserDocData(): unsubscribe');
                        this.getUserDocDataSub.unsubscribe();
                    }
                    resolve(this.currentUser);
                } else {
                    this.logger.log('getUserDocObservable()....');
                    this.getUserDocDataSub = this.getUserDocObservable(uid).subscribe(response => {
                        this.logger.log('getUserDocObservable subscrrption');
                        let currentUser: User;
                        currentUser = response;
                        if (currentUser != null) {
                            this.logger.log('getUserDocData  subscription: userDocument is:');
                            this.logger.log(currentUser);
                            resolve(currentUser);
                        } else {
                            this.logger.log('Could not fetch the user document');
                            reject('loadUserDoc: Could not fetch the user document');
                        }
                    });
                }
            } catch (error) {
                this.logger.error('Could not reach the user document ' + error);
                throw (error);
            }
        });
    }

    public async updateDispalyName(displayName: string): Promise<void> {
        const uid = this.currentUser.uid;
        this.logger.log('UID: ' + uid);
        const userDataUpdate: User = {
            displayName: displayName
        };
        const userRef: AngularFireObject<any> = this.afd.object(`users/${uid}`);
        await userRef.update(userDataUpdate);

    }

    public async markForDeletion(): Promise<void> {
        const uid = this.currentUser.uid;
        this.logger.log('UID: ' + uid);
        const userDataUpdate: User = {
            markedForDeletion: this.getTimestamp(),
        };
        const userRef: AngularFireObject<any> = this.afd.object(`users/${uid}`);
        await userRef.update(userDataUpdate);

    }

    public async unMarkForDeletion(): Promise<void> {
        const uid = this.currentUser.uid;
        this.logger.log('UID: ' + uid);
        const userDataUpdate: User = {
            markedForDeletion: null,
        };
        const userRef: AngularFireObject<any> = this.afd.object(`users/${uid}`);
        await userRef.update(userDataUpdate);
    }


    public async updateUserMessageRead(id: string) {
        const uid = this.currentUser.uid;

        let userDataUpdate: any = {
        };
        userDataUpdate[id] = this.getTimestamp()
        const userRef: AngularFireObject<any> = this.afd.object(`users/${uid}`);
        await userRef.update(userDataUpdate);
    }

    public getUserObservable(): Observable<User | firebase.User> {
        return this.currentUserObservable;
    }

    // this works only because there are no sub collection for user docs
    public async deleteOldUsers(): Promise<void> {
        const path = 'users/';
        const days = -15;
        let count = 0;
        const cutOffDate = new Date(); // returns today
        cutOffDate.setDate(cutOffDate.getDate() + days);
        this.logger.log('Deleting users older than: ' + cutOffDate);
        const usersRef = this.afd.database.ref(path);
        usersRef.once('value', snapshot => {
            snapshot.forEach(childSnapshot => {
                const childKey = childSnapshot.key;
                const childData = childSnapshot.val();
                const userRef: AngularFireObject<any> = this.afd.object(path + `${childKey}`);
                if (childData.updatedAt < cutOffDate && childData.isAnonymous == true) {
                    this.logger.warn('Remove user: ' + childKey + '; Updated:  ' + childData.updatedAt + '; isAnonymous: ' + childData.isAnonymous);
                    count++;
                    userRef.remove();
                } else {
                    this.logger.log('Don\'t remove user ' + childKey + '; Updated:  ' + childData.updatedAt + '; isAnonymous: ' + childData.isAnonymous);
                }
            });
        });
    }
    // unubscribe on logout
    public logout(): void {
        this.currentUser = null;
        if (this.loadUserSub) { this.loadUserSub.unsubscribe(); }
        if (this.getUserDocDataSub) { this.getUserDocDataSub.unsubscribe(); }
        //  this.currentUserObservable = null
    }

}
