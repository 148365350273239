<div [ngClass]="responsiveService.getBigSmall()">
    <div class="spo-container">
        <ess-dialog-title [showClose]="isDialog">
            <div class="spo-container-title headline1" i18n *ngIf="!responsiveService.isScreenSmall()">
                Room Settings
            </div>
            <div class="spo-container-subtitle  headline2">
                <div *ngIf="authService.isAnonymous">
                    <div><span i18n>You currently have a</span>&nbsp;<span style="font-weight: bold" i18n>temporary
                            account</span>.
                    </div>
                    <div><span i18n>Go to</span>&nbsp;<a routerLink='/user/account/signup'>Sign-up</a>&nbsp;<span
                            i18n>to get a
                            persistant
                            account and get access to some of the below settings</span>.</div>
                </div>
            </div>
        </ess-dialog-title>

        <form [formGroup]="profileForm" (ngSubmit)="saveProfile()" autocomplete="off" novalidate class="form-container">
            <mat-dialog-content class="dialog-content">
                <div class="spo-container-content profile-container">
                    <div *ngIf="userService.isLoggedIn()">
                        <div *ngIf="!userService.hasRoom" class="row">
                            <div class="column left-column" *ngIf="!responsiveService.isScreenSmall()">
                                <label i18n>Personal Room ID</label>
                            </div>
                            <div class="column right-column ">
                                <button i18n mat-flat-button (click)="handleCreateRoom()">Create your own room</button>
                            </div>
                        </div>
                        <div *ngIf="userService.hasRoom && appearance!=='dialog'" class="row">
                            <div class="column left-column" *ngIf="!responsiveService.isScreenSmall()">
                                <label i18n>Personal Room ID</label>
                            </div>
                            <div class="column right-column spo-field-container">
                                <mat-form-field appearance="fill">
                                    <mat-label i18n>Personal Room ID</mat-label>
                                    <input matInput placeholder="Room Id" formControlName="pokerRoomId" required>
                                    <mat-hint i18n>Can be personalized with a premium account.
                                    </mat-hint>
                                    <mat-error *ngIf="profileForm.controls['pokerRoomId'].hasError('roomExists')"
                                        i18n>Exists already
                                    </mat-error>
                                    <mat-error *ngIf="profileForm.controls['pokerRoomId'].hasError('pattern')" i18n>Must
                                        be alphnumeric
                                    </mat-error>
                                    <mat-error *ngIf="profileForm.controls['pokerRoomId'].hasError('minlength')"
                                        i18n>Minimum four
                                        characters
                                    </mat-error>
                                    <mat-error *ngIf="profileForm.controls['pokerRoomId'].hasError('required')"
                                        i18n>Required
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>

                        <div class="row">
                            <div class="column left-column" *ngIf="!responsiveService.isScreenSmall()">
                                <label i18n>Estimate Options</label>
                            </div>
                            <div class="column right-column spo-field-container">
                                <mat-form-field appearance="fill">
                                    <mat-label i18n>Estimate Options</mat-label>
                                    <mat-label *ngIf="authService.isAnonymous" i18n> (can not be changed with a
                                        temporary profile)
                                    </mat-label>
                                    <input matInput placeholder="0.5,1,2,3,abc,x,y" formControlName="estimateOptions"
                                        required>
                                    <mat-error *ngIf="profileForm.controls.estimateOptions.invalid">Comma seperated
                                        list, each element not
                                        longer
                                        than 3 chars</mat-error>
                                </mat-form-field>
                            </div>
                        </div>

                        <div class="row">
                            <div class="column left-column">
                                <label class="limit-per-user-label" i18n>Allow others to show estimates</label>
                            </div>
                            <div class="column right-column">
                                <mat-radio-group aria-labelledby="radio-group-label" class="radio-group"
                                    formControlName="allowHide" required>
                                    <div class="radio-buttons">
                                        <mat-radio-button class="radio-button" value="yes" i18n>Yes</mat-radio-button>
                                        <mat-radio-button class="radio-button" value="no" i18n>No</mat-radio-button>
                                    </div>
                                </mat-radio-group>
                            </div>
                        </div>

                        <div class="row">
                            <div class="column left-column">
                                <label class="limit-per-user-label" i18n>Allow others to delete estimates</label>
                            </div>
                            <div class="column right-column">
                                <mat-radio-group aria-labelledby="radio-group-label" class="radio-group"
                                    formControlName="allowDeleteEstimates" required>
                                    <div class="radio-buttons">
                                        <mat-radio-button class="radio-button" value="yes" i18n>Yes</mat-radio-button>
                                        <mat-radio-button class="radio-button" value="no" i18n>No</mat-radio-button>
                                    </div>
                                </mat-radio-group>
                            </div>
                        </div>

                        <div class="row">
                            <div class="column left-column">
                                <label class="limit-per-user-label" i18n>Allow others to clear
                                    users</label>
                            </div>
                            <div class="column right-column">
                                <mat-radio-group aria-labelledby="radio-group-label" class="radio-group"
                                    formControlName="allowClear" required>
                                    <div class="radio-buttons">
                                        <mat-radio-button class="radio-button" value="yes" i18n>Yes</mat-radio-button>
                                        <mat-radio-button class="radio-button" value="no" i18n>No</mat-radio-button>
                                    </div>
                                </mat-radio-group>
                            </div>
                        </div>

                        <div class="row" i18n-matTooltip matTooltip="With premium only">
                            <div class="column left-column">
                                <label class="limit-per-user-label" i18n>Show timer</label>
                            </div>
                            <div class="column right-column">
                                <mat-radio-group aria-labelledby="radio-group-label" class="radio-group"
                                    formControlName="showTimer" required>
                                    <div class="radio-buttons">
                                        <mat-radio-button class="radio-button" value="yes" i18n>Yes</mat-radio-button>
                                        <mat-radio-button class="radio-button" value="no" i18n>No</mat-radio-button>
                                    </div>
                                </mat-radio-group>
                            </div>
                        </div>

                        <div class="row" i18n-matTooltip matTooltip="With premium only">
                            <div class="column left-column">
                                <label class="limit-per-user-label" i18n>Show user presence</label>
                            </div>
                            <div class="column right-column">
                                <mat-radio-group aria-labelledby="radio-group-label" class="radio-group"
                                    formControlName="userPresence" required>
                                    <div class="radio-buttons">
                                        <mat-radio-button class="radio-button" value="yes" i18n>Yes</mat-radio-button>
                                        <mat-radio-button class="radio-button" value="no" i18n>No</mat-radio-button>
                                    </div>
                                </mat-radio-group>
                            </div>
                        </div>

                        <div class="row" i18n-matTooltip matTooltip="With premium only">
                            <div class="column left-column">
                                <label class="limit-per-user-label" i18n>Show average</label>
                            </div>
                            <div class="column right-column">
                                <mat-radio-group aria-labelledby="radio-group-label" class="radio-group"
                                    formControlName="showAverage" required>
                                    <div class="radio-buttons">
                                        <mat-radio-button class="radio-button" value="yes" i18n>Yes</mat-radio-button>
                                        <mat-radio-button class="radio-button" value="no" i18n>No</mat-radio-button>
                                    </div>
                                </mat-radio-group>
                            </div>
                        </div>

                        <div class="row">
                            <div class="column left-column">
                                <label class="limit-per-user-label" i18n>Show median</label>
                            </div>
                            <div class="column right-column">
                                <mat-radio-group aria-labelledby="radio-group-label" class="radio-group"
                                    formControlName="showMedian" required>
                                    <div class="radio-buttons">
                                        <mat-radio-button class="radio-button" value="yes" i18n>Yes</mat-radio-button>
                                        <mat-radio-button class="radio-button" value="no" i18n>No</mat-radio-button>
                                    </div>
                                </mat-radio-group>
                            </div>
                        </div>
                    </div>
                </div>

            </mat-dialog-content>

            <mat-dialog-actions>
                <div class="spo-field-container save-button-container">
                    <button mat-flat-button color="primary" type="submit" i18n [disabled]="!profileForm.valid"
                        class="spo-font-color-white">
                        Save
                    </button>
                </div>
            </mat-dialog-actions>

        </form>
    </div>
</div>