import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RoomIdService } from '../poker-room/room-id.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AuthService } from '../user/auth.service';
import { UIMesageService } from '../common/ui-message.service';
import { EstimateService } from '../poker-room/estimate.service';
import { UserService } from '../user/user.service';
import { NGXLogger as LoggerService } from "ngx-logger";
import { GoogleAnalyticsService } from '../common/google-analytics.service';
import { MatDialog } from '@angular/material/dialog';
import { QrCodeDialogComponent } from '../qr-code/qr-code-dialog.component';
import { ResponsiveService } from '../common/responsive.service';


@Component({
    selector: 'enter-room',
    templateUrl: './enter-room.component.html',
    styleUrls: ['./enter-room.component.scss'],

})
export class EnterRoomComponent implements OnInit {
    enterRoomForm: FormGroup;
    public roomId: FormControl;

    constructor(
        private router: Router,
        private roomIdService: RoomIdService,
        private estimateService: EstimateService,
        public authService: AuthService,
        private uiMessage: UIMesageService,
        private logger: LoggerService,
        private route: ActivatedRoute,
        public userService: UserService,
        private googleAnalyticsService: GoogleAnalyticsService,
        public dialog: MatDialog,
        public responsiveService: ResponsiveService,


    ) {
    }

    ngOnInit() {
        this.roomId = new FormControl(
            ''
        );
        this.enterRoomForm = new FormGroup({
            roomId: this.roomId,

        });
    }


    async enterRoom(formValues) {
        this.logger.log('enterRoomByForm' + formValues.room);
        if (this.enterRoomForm.valid) {
            try {
                const roomId = this.cleanUpRoomId(formValues.roomId);
                const result = await this.estimateService.isExistentRoom(roomId);
                if (result) {
                    this.roomIdService.setRoomIdStr(roomId);
                    this.googleAnalyticsService.eventEmitter('Enter Room', 'Home Page', 'enterRoomByForm Action');
                    this.router.navigate([this.roomIdService.getUrlPokerRoom()]);
                    this.uiMessage.success('Entered room: ' + formValues.roomId);
                    this.roomId.setValue(' ');
                    this.roomId.markAsUntouched();
                } else {
                    this.uiMessage.warning('A room with Id ' + formValues.roomId + ' does not exist.');
                }
            } catch (error) {
                this.uiMessage.error(error, 'Entering room failed. ');
            }
        } else {
            this.uiMessage.warning('Form not valid');
        }
    }

    private cleanUpRoomId(input: string) {
        var replaced = input.split(' ').join('');
        var replaced = replaced.split('-').join('');
        var replaced = replaced.split('_').join('');
        return replaced;
    }

}
