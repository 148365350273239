<div>
        <mat-divider></mat-divider>
        <div class="flex-container">
                <div class="flex-item">
                        <a i18n routerLink="/about">About</a>
                </div>
                <div class="flex-item">
                        <a i18n routerLink="/feedback">Feedback</a>
                </div>
                <div class="flex-item">
                        <a i18n routerLink="/blog">Blog</a>
                </div>
                <div class="flex-item">
                        <a routerLink="/privacy">Privacy</a>
                </div>
                <div class="flex-item">
                        <a i18n routerLink="/choose-language">Language
                        </a>
                </div>
                <div class="flex-item version">
                        <span i18n>Version:</span> {{version}}
                </div>

        </div>
</div>

<div [ngClass]="locale"></div>