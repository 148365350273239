<div class="home" [ngClass]="responsiveService.getBigSmall()">
    <h1 i18n="Logged in; First Header for Small Screen | Room ID was assigend"
        *ngIf="responsiveService.isScreenSmall() else bigScreen" i18n>
        Planning poker in seconds
    </h1>
    <ng-template #bigScreen>
        <h1 i18n="Logged in; First Header for Big Screen | Room ID was assigend" i18n>
            A personal poker room has been assigned to you.
        </h1>
        <h2 i18n="Logged in; Second Header for Big Screen | Call to share the Room ID" i18n>
            Share the room id with your team mates so they can join the scrum poker.
        </h2>
        <h2 i18n="Logged in; Your Room Id" i18n>
            YOUR ROOM ID: {{userService?.getRoomId() | room }}
        </h2>
    </ng-template>
    <div class="spo-centered-button">
        <button mat-flat-button color="primary" (click)="enterUsersRoom()" i18n class="spo-font-color-white">
            ENTER YOUR ROOM
        </button>
    </div>
</div>