export enum AuthProvider {
    ANONYMOUS = 'anonymous',
    EmailAndPassword = 'firebase',
    Google = 'google',
    Facebook = 'facebook',
    Twitter = 'twitter',
    GitHub = 'github',
}

export interface Address {
    country?: string;
    street?: string;
    city?: string;
    zipCode?: string;
}

export interface User {
    createdBy?: string;
    uid: string;
    email?: string;
    emailVerified?: boolean;
    displayName?: string;
    firstName?: string;
    lastName?: string;
    phoneNumber?: string;
    address?: Address;
    photoURL?: string;
    languageCode?: string;
    orderInProgress?: boolean;
    newsLetter?: boolean;
    accDeleted?: boolean;
    providerId?: string;
    isAnonymous?: boolean;
}

export interface IdCredentials {
    email: string;
    password: string;
}


export interface SingUp {
    signUp(diplayName: string, credentials: IdCredentials): Promise<any>;
}

export interface SignInWith {
    signInWith(provider: AuthProvider, credentials: IdCredentials);
}

