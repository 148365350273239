<span [ngClass]="responsiveService.getBigSmall()">

    <div [ngClass]="appContainerClass">

        <div class="nav-container">
            <div class="enter-room-container">
                <enter-room></enter-room>
            </div>
            <div class="toolbar-container">
                <nav-bar>
                </nav-bar>
            </div>
        </div>

        <div [ngClass]="pageContainerClass">
            <router-outlet></router-outlet>
        </div>

        <div class="footer-container" *ngIf="!isBlog">
            <footer></footer>
        </div>
    </div>
</span>

<app-progress-bar *ngIf="authService.isLoading"></app-progress-bar>
