import { Component, Inject, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { NGXLogger as LoggerService } from "ngx-logger";
import { ResponsiveService } from '../common/responsive.service';

export interface EditTimerData {
    minutes: number,
    seconds: number
}


export interface Option {
    value: number;
    label: string;
}
const minuteOptions: Option[] = [
    { value: 0, label: "00" },
    { value: 1, label: "01" },
    { value: 2, label: "02" },
    { value: 3, label: "03" },
    { value: 4, label: "04" },
    { value: 5, label: "05" },
    { value: 6, label: "06" },
    { value: 7, label: "07" },
    { value: 8, label: "08" },
    { value: 9, label: "09" },
    { value: 10, label: "10" },
]
    ;
const secondOptions: Option[] = [
    { value: 0, label: "00" },
    { value: 5, label: "05" },
    { value: 10, label: "10" },
    { value: 15, label: "15" },
    { value: 20, label: "20" },
    { value: 25, label: "25" },
    { value: 30, label: "30" },
    { value: 35, label: "35" },
    { value: 40, label: "40" },
    { value: 45, label: "45" },
    { value: 50, label: "50" },
    { value: 55, label: "55" },
];

@Component({
    selector: 'edit-timer-dialog',
    templateUrl: 'edit-timer-dialog.component.html',
    styleUrls: ['edit-timer-dialog.component.scss']
})

export class EditTimerDialogComponent {
    public secondOptions: Option[] = secondOptions;
    public minuteOptions: Option[] = minuteOptions;
    public minutesControl = new UntypedFormControl();
    public secondsControl = new UntypedFormControl();

    constructor(
        public logger: LoggerService,
        public responsiveService: ResponsiveService,
        public dialogRef: MatDialogRef<EditTimerDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: EditTimerData,
        public dialog: MatDialog
    ) {
        this.minutesControl.setValue(data.minutes)
        this.secondsControl.setValue(data.seconds)
    }


    onOkClick() {
        this.dialogRef.close({ minutes: this.minutesControl.value, seconds: this.secondsControl.value });

    }

}
