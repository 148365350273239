<div class="component-container" [ngClass]="responsiveService.getBigSmall()">

    <ng-container *ngIf="enteringRoom">
        <ess-spinner><span i18n>Submitting...</span></ess-spinner>
    </ng-container>

    <ng-container *ngIf="!enteringRoom">
        <div mat-dialog-title>
            <h2 i18n>
                Provide your name to enter
            </h2>
        </div>
        <div mat-dialog-content>
            <div [ngClass]="responsiveService.getBigSmall()">
                <div class="spo-container">

                    <div class="content">
                        <form [formGroup]="enterRoomForm" (ngSubmit)="enterRoom()" autocomplete="off" novalidate>
                            <div class="spo-field-container">
                                <mat-form-field appearance="outline">
                                    <mat-label i18n>Display Name</mat-label>
                                    <input matInput placeholder="Paul Doe" [formControl]="newDeveloperName" required>
                                    <mat-error *ngIf="newDeveloperName.invalid">{{getDeveloperNameErrorMessage()}}
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="spo-field-container">
                                <button mat-flat-button color="primary" type="submit" i18n
                                    class="spo-font-color-white enter-room-button">
                                    Submit
                                </button>
                            </div>
                            <div class="spo-field-container cancel">
                                <button mat-raised-button (click)="cancel()" type="button" i18n>
                                    Cancel
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
</div>
