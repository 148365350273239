/*
https://github.com/dbfannin/ngx-logger/blob/HEAD/docs/customising.md
And now everytime you have 'SERVER' in your message, the log will be sent to your server

(example) Adds a property to the server log
This example adds a property "levelName" to the body sent to the API

NB : This example can be used to change the body however you like

Tweak the server service :

@Injectable()
export class ServerCustomisedService extends NGXLoggerServerService {


  public customiseRequestBody(metadata: INGXLoggerMetadata): any {
    let body = { ...metadata };
    body['levelName'] = NgxLoggerLevel[metadata.level];

    // note, for the example we log the body but in a real case the log is useless
    console.log('Customised body is', body);

    return body;
  }
}
Provide the customised service to the logger

LoggerModule.forRoot(
  { level: NgxLoggerLevel.TRACE, serverLogLevel: NgxLoggerLevel.TRACE, serverLoggingUrl: 'dummyURL' },
  {
    serverProvider: {
      provide: TOKEN_LOGGER_SERVER_SERVICE, useClass: ServerCustomisedService
    }
  }),
And now another property levelName will be sent to your API
*/

import { Injectable, NgZone } from "@angular/core";
import { INGXLoggerMetadata, NGXLoggerServerService, NgxLoggerLevel } from "ngx-logger";
import { LibraryConfigurationService } from './library-configuration.service';
import { HttpBackend, HttpHeaders, HttpParams, HttpRequest, HttpResponse } from '@angular/common/http';

@Injectable()
export class NgxLoggerCustomServerService extends NGXLoggerServerService {
    constructor(
        public config: LibraryConfigurationService,
        protected httpBackend: HttpBackend,
        protected ngZone: NgZone) {
        super(httpBackend, ngZone); // Pass the required arguments to the super() call
    }

    public customiseRequestBody(metadata: INGXLoggerMetadata): any {
        let body = { ...metadata };
        body['levelName'] = NgxLoggerLevel[metadata.level];
        body['version'] = this.config.version ? this.config.version : 'no-version-provided'
        body['host'] = this.config.host ? this.config.host : 'no-host-provided'
        return body;
    }
}
