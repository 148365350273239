export interface BuyableProduct {
    uniqueKey: string; /** uniuqe identifier like "premium-year" */
    productKey: string, /** can be the same for multiple  BuyapleProducts*/
    description: string,
    descriptionTranslations?: {
        en: string;
        es: string;
        it: string;
        fr: string;
        pt: string;
        de: string;
    }
    total: string,
    durationDays: number,
}

export interface ActiveProduct {
    "paymentPushId": string;
    "description": string;
    "uniqueKey": string;
    "productKey": string;
    "createdAt": number;
    "createdAtString": string;
    "endDate": number;
    "endDateString": string;
}
export interface DeactivatedProduct extends ActiveProduct {
    deactivationReason: string;
}
/** identifies products that can be bought
 * includes a duration
 */
export const buyableProductsWhocan: BuyableProduct[] = [
    {
        uniqueKey: "whocan-premium-year",
        productKey: "premium",
        description: "One year premium service for Whocan",
        total: "80",
        durationDays: 365,
    },
    {
        uniqueKey: "whocan-premium-month",
        productKey: "premium",
        description: "One month premium service Whocan",
        total: "12",
        durationDays: 31,
    },
    {
        uniqueKey: "whocan-super-premium-month",
        productKey: "super-premium",
        description: "One month SUPER premium service whocan",
        total: "30",
        durationDays: 31,
    },

];

/** identifies products that can be bought
 * includes a duration
 */
export const buyableProductsScrumpoker: BuyableProduct[] = [
    {
        uniqueKey: "scrumpoker-premium-year",
        productKey: "premium",
        description: "One year premium",
        descriptionTranslations: {
            en: "1 year premium",
            es: "1 año de membresía Premium",
            it: "1 anno di abbonamento Premium",
            fr: "1 an d'adhésion Premium",
            pt: "1 ano de adesão Premium",
            de: "1 Jahr Premium Mitgliedschaft",
        },
        total: "48",
        durationDays: 365,
    },
    {
        uniqueKey: "scrumpoker-premium-month",
        productKey: "premium",
        description: "One month premium",
        descriptionTranslations: {
            en: "1 month premium",
            es: "1 mes de membresía Premium",
            it: "1 mese di abbonamento Premium",
            fr: "1 mois d'adhésion Premium",
            pt: "1 Mês de adesão Premium",
            de: "1 Monat Premium Mitgliedschaft",
        },
        total: "5",
        durationDays: 31,
    }

];

