import { Injectable } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { IEstimate, IRoom } from './esimtate.model';
import { NGXLogger as LoggerService } from "ngx-logger";
import { EstimateFdbService } from './estimate.fdb.service';
import { map } from 'rxjs/operators';
import { GoogleAnalyticsService } from '../common/google-analytics.service';
import { PresenceService } from '../user/online-presence.service';
import { TimerService } from '../timer/timer.service';
import { SnapshotAction, snapshotChanges } from '@angular/fire/compat/database';
import { StripeService } from '../user/services/stripe.service';
import { AuthService } from '../user/auth.service';
import { UserService } from '../user/user.service';
const DEFAULT_OPTIONS: string[] = ['?', 'cof', '0', '0.5', '1', '2', '3', '5', '8', '13', '20', '40', '100'];


@Injectable()
export class EstimateService {
    private roomId = '';
    public displayedColumns: string[] = ['displayName', 'storyPoints'];
    private roomSubscription: Subscription;
    private estimatesSubscription: Subscription;
    private showHideSubscription: Subscription
    public estimateOptions: string[];
    public showEstimates = false;
    public allowHide = true;
    public allowClear = true;
    private isOwnerAnonymous: boolean;
    public allowDeleteEstimates = true;
    public userPresence = true;
    public showAverage = true;
    public showMedian = true;
    public estimates: IEstimate[] = [];
    public userStoryPoints: string = null;
    public alertIsClosed = false;
    public averageEstimate: string;
    public medianEstimate: string;
    public showTimer = true;
    public ownerUid: string;
    public ownerHasPremium: boolean;

    constructor(
        private estimateFireService: EstimateFdbService,
        private logger: LoggerService,
        private googleAnalyticsService: GoogleAnalyticsService,
        private presenceService: PresenceService,
        private timeService: TimerService,
        private stripeService: StripeService,
        public authService: AuthService,
        public userService: UserService,
    ) {
        this.roomId = '';
        this.estimateOptions = this.getDefaultEstimateOptions();
    }

    public async createEstimate(roomId: string, developerName: string, uid: string, estimate?: string): Promise<void> {
        const isExistentRoom = await this.isExistentRoom(roomId); // that's not need I guess
        if (isExistentRoom) {
            if (this.isOwnerAnonymous) {
                this.googleAnalyticsService.eventEmitter('submitEstimate isOwnerAnonymous', 'Poker Room', 'submitEstimate Action');
            } else {
                this.googleAnalyticsService.eventEmitter('submitEstimate NOT isOwnerAnonymous', 'Poker Room', 'submitEstimate Action');
            }
            await this.estimateFireService.createEstimate(roomId, developerName, uid, estimate);
        } else {
            const error = new Error('The estimate could not be submitted. The room with id ' + roomId + ' does not exist (anymore).');
            throw (error);
        }
    }

    /**
     *   must be called always when a user wants to enter a room -resets the esimate?
     * @param roomId
     * @returns
     */
    public async enterRoom(roomId: string) {
        this.logger.log('estimateService enterRoom: ', roomId);
        if (this.roomId !== roomId) { // if the user was in the same roooom before nothing needs to be done
            if (!await this.isExistentRoom(roomId)) throw new Error('Room does not exist: ' + roomId)
            this.roomId = roomId;
            this.setObservers()
            this.setDummyEstimate()
            this.googleAnalyticsService.eventEmitter('Enter Room', 'Poker Room', 'enterRoom Action');
        }
    }

    private setObservers() {
        this.roomSubscription?.unsubscribe();
        this.estimatesSubscription?.unsubscribe();
        this.roomSubscription = this.setRoomObserver(this.roomId);
        this.showHideSubscription = this.setShowHideObserver(this.roomId);
        this.estimatesSubscription = this.setEstimatesObserver(this.roomId);
        this.timeService.connectRoom(this.roomId);
    }

    /**
     * set a dummy estimate to make the user visibnle in the room
     */
    private setDummyEstimate() {
        if (this.userService.isLoggedIn() && !this.getUserHasEstimate()) {
            this.createEstimate(this.roomId, this.userService.getDisplayName(), this.authService.uid);
        }
    }

    async getOwnerHasPremium() {
        this.ownerHasPremium = await this.stripeService.getUserHasPremium(this.ownerUid)
    }

    private setShowHideObserver(roomId: string): Subscription {
        this.logger.log('setShowHideObserver: ' + roomId);
        return this.estimateFireService.getShowHideObservable(roomId).pipe(
            map(object => {
                if (object) {
                    this.showEstimates = object.showEstimates;
                    if (this.showEstimates) {
                        this.logger.log('setShowHideObserver showEstimtates');
                        this.estimates = [...this.estimates.sort(this.compareEstimates)]; // we sort only when showing the estimates
                    }
                    else {
                        this.showEstimates = false
                    }
                }
            })).subscribe()
    }

    private setRoomObserver(roomId: string) {
        this.logger.log('setRoomObserver: ' + roomId);
        return this.roomSubscription = this.getRoomObservable(roomId).pipe(
            map(room => {
                this.logger.log('room observer ', room);
                if (room) {
                    if (this.ownerUid !== room.ownerUid) {
                        this.ownerUid = room.ownerUid;
                        this.getOwnerHasPremium();
                    }
                    if (room.estimateOptions) {
                        this.estimateOptions = room.estimateOptions.split(',');
                    } else {
                        this.estimateOptions = this.getDefaultEstimateOptions();
                    }
                    if (room.allowHide === 'no') {
                        this.allowHide = false;
                    } else {
                        this.allowHide = true;
                    }
                    this.isOwnerAnonymous = room.isOwnerAnonymous;
                    if (room.allowClear === 'no') {
                        this.allowClear = false;
                    } else {
                        this.allowClear = true;
                    }
                    if (room.allowDeleteEstimates === 'no') {
                        this.allowDeleteEstimates = false;
                    } else {
                        this.allowDeleteEstimates = true;
                    }
                    if (room.userPresence === 'yes') {
                        this.logger.log('userpresesnce yes');
                        this.presenceService.setConnectionCallback(this.authService.uid);
                        this.userPresence = true;
                    } else {
                        this.userPresence = false;
                    }
                    if (room.showAverage === 'yes') {
                        this.logger.log('showAverage yes');
                        this.showAverage = true;
                    } else {
                        this.showAverage = false;
                    }
                    if (room.showMedian === 'yes') {
                        this.logger.log('showMedian yes');
                        this.showMedian = true;
                    } else {
                        this.showMedian = false;
                    }
                    if (room.showTimer === 'no') {
                        this.logger.log('showMedian yes');
                        this.showTimer = false;
                    } else {
                        this.showTimer = true;
                    }
                } else {
                    this.logger.warn('The room ' + roomId + '  does not exist (anymore). Please choose a different one.');
                }
            })).subscribe();
    }

    private setEstimatesObserver(roomId: string): Subscription {
        this.logger.log('setEstimatesObserver: ' + roomId);
        return this.estimateFireService.getEstimatesObservable(roomId).pipe(
            map(snapshotList => {
                this.logger.log(snapshotList);
                this.setEstimates(snapshotList);
                this.setUsersEstimate();
                this.averageEstimate = this.getAverageEstimate();
                this.medianEstimate = this.getMedian();
                if (this.showEstimates) {
                    this.estimates = this.estimates.sort(this.compareEstimates);
                }
                this.logger.log('setEstimatesObserver', this.estimates);
            })
        ).subscribe();
    };

    public getMedian(): string {
        let estimateNumbers: number[] = [];
        const estimatesCopy = [...this.estimates]
        estimatesCopy.sort(this.compareEstimates)
        estimatesCopy.forEach((value) => {
            const valueNumber = parseFloat(value.storyPoints);
            if (valueNumber !== undefined && valueNumber !== null && !isNaN(valueNumber)) {
                estimateNumbers.push(valueNumber)
            }
        });
        if (estimateNumbers.length === 0) { return ""; }
        else if (estimateNumbers.length % 2 === 0) {
            //even
            const lower = estimateNumbers[(estimateNumbers.length / 2) - 1]
            const higher = estimateNumbers[estimateNumbers.length / 2]
            return "" + ((higher + lower) / 2)
        } else {
            //not even
            return "" + estimateNumbers[(estimateNumbers.length / 2) - 0.5]
        }
    }

    private setEstimates(snapshotChangesArray: SnapshotAction<any>[]) {
        this.estimates = []// create a new a array to make sure the table updates
        snapshotChangesArray.forEach(snapshotChange => {
            const estimate: IEstimate = { ...snapshotChange.payload.val() };
            estimate.userUid = snapshotChange.key // we dont persist the uid to reduce firebase cost
            this.estimates.push(estimate);
        });
        this.logger.log('setEstimates', this.estimates);
    }

    getAverageEstimate(): string {
        if (this.estimates) {
            this.logger.log('get averageEstimate')
            let returnValue = 0;
            let index = 0;
            this.estimates.forEach((value) => {
                const valueNumber = parseFloat(value.storyPoints);
                if (valueNumber !== undefined && valueNumber !== null && !isNaN(valueNumber)) {
                    //  this.logger.error('averageEstimate, forEach', valueNumber);
                    index = index + 1;
                    returnValue = returnValue + valueNumber;
                }
            });
            if (index !== 0) {
                return (Math.round(returnValue * 10 / index) / 10).toString();
            }
        }
        return '';
    }

    private setUsersEstimate() {
        this.userStoryPoints = null;
        this.estimates.forEach(estimate => {
            this.logger.log('setUserEstimate', this.authService.uid, estimate.userUid);
            if (this.authService.uid === estimate.userUid) {
                this.userStoryPoints = estimate.storyPoints;
            }
        });
    }

    private getUserHasEstimate(): boolean {
        const uid = this.userService.getUid()
        this.estimates.forEach(estimate => {
            if (uid === estimate.userUid) {
                return true
            }
        });
        return false
    }


    compareEstimates(estimateA: IEstimate, estimateB: IEstimate): number {
        // check empty case first
        const aIsEmpty = estimateA.storyPoints === undefined || estimateA.storyPoints === null || estimateA.storyPoints === ''
        const bIsEmpty = estimateB.storyPoints === undefined || estimateB.storyPoints === null || estimateB.storyPoints === ''
        if (aIsEmpty || bIsEmpty) {
            if (aIsEmpty && !bIsEmpty) {
                return -1;
            }
            if (!aIsEmpty && bIsEmpty) {
                return 1;
            }
            // both must be empty
            return 0
        }
        const a = parseFloat(estimateA.storyPoints);
        const b = parseFloat(estimateB.storyPoints);
        // case that at least one is not  a number
        if (isNaN(a) || isNaN(b)) {
            if (isNaN(a) && !isNaN(b)) {
                return -1;
            }
            if (!isNaN(a) && isNaN(b)) {
                return 1;
            }
            // both must be not a number
            if (estimateA.storyPoints < estimateB.storyPoints) {
                return -1;
            } else if (estimateA.storyPoints > estimateB.storyPoints) {
                return 1;
            } else {
                return 0;
            }
        }
        // both numbers
        if (a < b) {
            return -1;
        }
        if (a > b) {
            return 1;
        }
        return 0;
    }

    public unsubscribe() {
        this.roomId = '';
        this.estimateOptions = this.getDefaultEstimateOptions();
        this.estimatesSubscription?.unsubscribe();
        this.roomSubscription?.unsubscribe();
        this.showHideSubscription?.unsubscribe();
        this.timeService.disconnnectRoom();
    }

    public async isExistentRoom(roomId): Promise<boolean> {
        return this.estimateFireService.isExistentRoom(roomId);
    }

    public getRoomObservable(roomId: string): Observable<IRoom> {
        return this.estimateFireService.getRoomObservable(roomId);
    }
    public async setShowEstimates(roomId: string, showEstimates: boolean): Promise<void> {
        try {
            this.estimateFireService.setShowEstimates(roomId, showEstimates);
        } catch (e) {
            const error = new Error('The room with id ' + roomId + ' does not exist (anymore). ' + e);
            throw (error);
        }
    }
    public clearStoryPoints(roomId: string): void {
        this.estimateFireService.clearStoryPoints(roomId);
    }
    public clearRoom(roomId: string): void {
        this.estimateFireService.clearRoom(roomId);
    }

    public deleteRoom(roomId: string) {
        this.estimateFireService.deleteRoom(roomId);
    }
    public deleteOldRooms(): void {
        this.estimateFireService.deleteOldRooms();
    }
    public getDefaultEstimateOptions(): string[] {
        return DEFAULT_OPTIONS;
    }
    public setRoomConfiguration(
        roomId: string,
        estimateOptions: string,
        allowHide: string,
        allowClear: string,
        allowDeleteEstimates: string,
        userPresence: string,
        showAverage: string,
        showMedian: string,
        showTimer: string)
        : void {
        this.logger.log('setRoomConfiguration: ' + estimateOptions);
        this.estimateFireService.setRoomConfiguration(
            roomId,
            estimateOptions,
            allowHide,
            allowClear,
            allowDeleteEstimates,
            userPresence,
            showAverage,
            showMedian,
            showTimer);
    }
}
