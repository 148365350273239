import { Pipe } from '@angular/core';

@Pipe({
    name: 'room'
})
export class RoomPipe {
    transform(val) {
        if (val == null) { return ''; }
        if (isNaN(val)) {
            return val
        }

        let newStr = '';
        let i = 0;
        for (i = 0; i < (Math.floor(val.length / 2) - 1); i++) {
            newStr = newStr + val.substr(i * 2, 2) + ' ';
        }
        return newStr + val.substr(i * 2);
    }
}
