<div [ngClass]="responsiveService.getBigSmall()">
    <ess-spinner *ngIf="isLoading"><span i18n>Loading...</span></ess-spinner>
    <div *ngIf="!isLoading" [ngClass]="estimateService.ownerHasPremium?'advert':''">
        <div *ngIf="isExistentRoom else roomDoesNotExist">
            <!--SMALL SCREEN-->
            <span *ngIf="responsiveService.isScreenSmall()">
                <div *ngIf="advertisingService.enableAdds" class="ad-container-header-mobile">
                    <div *ngIf="advertisingService.showFuseAddBlocks" id="22851570040" data-fuse="22851570040"></div>
                    <div *ngIf="advertisingService.showPrimisAddBlocks" id="primis-header-mobile" class="primis-ad">
                    </div>
                </div>
                <ng-container *ngTemplateOutlet="ARTICLE_TOP"></ng-container>
                <provide-estimate [isOwner]="isOwner"
                    [ownerHasPremium]="estimateService.ownerHasPremium"></provide-estimate>
                <mat-divider class="divider"></mat-divider>
                <results></results>
                <div *ngIf="advertisingService.enableAdds" class="ad-container-footer-mobile">
                    <div *ngIf="advertisingService.showFuseAddBlocks" id="22851418830" data-fuse="22851418830"></div>
                    <div *ngIf="advertisingService.showPrimisAddBlocks" id="primis-footer-mobile" class="primis-ad">
                    </div>
                </div>
                <ng-container *ngTemplateOutlet="ARTICLE_MIDDLE"></ng-container>
                <ng-container *ngTemplateOutlet="ARTICLE_BOTTOM"></ng-container>
            </span>
            <!--BIG SCREEN-->
            <span *ngIf="!responsiveService.isScreenSmall()">
                <div class="poker-room-container">
                    <div class="row">
                        <div class="column middle">
                            <kp-alert *ngIf="authService.isAnonymous && isOwner" type="info" alertId="Anonymous"
                                i18n-title title="You have been provided a temporary account." i18n-messageLine1
                                messageLine1="To enable additional features we recommend you sign-up." i18n-messageLine4
                                messageLine4="Paul & Kai." goToLink="user/account/signup" i18n-goToText
                                goToText="Sign up"></kp-alert>
                            <kp-alert *ngIf="!authService.isAnonymous && !authService.hasPremium() && isOwner"
                                type="info" alertId="Upgrade2" i18n-title title="Upgrade to 'Premium'" i18n-messageLine1
                                messageLine1="Enjoy an ad-free experience, enhanced features like personalized room names, advanced display settings, and a voting timer."
                                i18n-messageLine2 messageLine2=" Upgrade now for a smoother Scrum Poker session!"
                                i18n-messageLine4 messageLine4="Paul & Kai." goToLink="user/account/upgrade"
                                i18n-goToText goToText="Upgrade"></kp-alert>
                            <ng-container *ngTemplateOutlet="ARTICLE_TOP"></ng-container>
                            <provide-estimate [isOwner]="isOwner"
                                [ownerHasPremium]="estimateService.ownerHasPremium"></provide-estimate>
                            <mat-divider class="divider"></mat-divider>
                            <ng-container *ngTemplateOutlet="ARTICLE_MIDDLE"></ng-container>
                            <div class="results">
                                <results [ownerHasPremium]="estimateService.ownerHasPremium"></results>
                            </div>
                            <div *ngIf="advertisingService.enableAdds" class="add-container-middle">
                                <div *ngIf="advertisingService.showFuseAddBlocks">
                                    <div class="fuse-add-middle" id="22851570049" data-fuse="22851570049"></div>
                                    <div class="fuse-add-middle" id="22851570043" data-fuse="22851570043"></div>
                                </div>
                                <div *ngIf="advertisingService.showPrimisAddBlocks" class="primis-middle-container">
                                    <div class="primis-ad" id="primis-middle-1"></div>
                                    <div class="primis-ad" id="primis-middle-2"></div>
                                </div>
                            </div>
                            <ng-container *ngTemplateOutlet="ARTICLE_BOTTOM"></ng-container>
                        </div>
                        <div *ngIf="advertisingService.enableAdds" class="column right">
                            <div class="add-container-right">
                                <div *ngIf="environment.enableAdsense">
                                    <ng-adsense *ngIf="!delayAdsense" [display]="'inline-block'" [width]="250"
                                        [height]="250" [adSlot]="6733965246"></ng-adsense>
                                    <ng-adsense *ngIf="!delayAdsense" [display]="'inline-block'" [width]="250"
                                        [height]="600" [adSlot]="7670770250"></ng-adsense>
                                </div>
                                <div *ngIf="advertisingService.showFuseAddBlocks">
                                    <div class="fuse-add-right" id="22851570046" data-fuse="22851570046"></div>
                                    <div class="fuse-add-right" id="22851569437" data-fuse="22851569437"></div>
                                </div>
                                <div *ngIf="advertisingService.showPrimisAddBlocks" class="primis-right-container">
                                    <div class="primis-ad" id="primis-right-1"></div>
                                    <div class="primis-ad" id="primis-right-2"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </span>
        </div>
        <ng-template #roomDoesNotExist>
            <h1>Room "{{roomIdService.getCurrentRoomID()}}" does not exist</h1>
            <h2>Sorry, it seeems you got the wrong room number.</h2>
            <h2>Please try an other one!</h2>
        </ng-template>
    </div>
</div>
<ng-template #ARTICLE_TOP>
    <div class="article top">
        <h3 i18n>Provide an effort estimate - choose one of the cards</h3>
        <span i18n class="article-paragraph">Each team member should estimate the complexity of the task (user story) to
            be completed.</span>
        <read-more class="article-paragraph">
            This is done by selecting one of the cards below. The numbers on the cards represent the effort or the
            degree of complexity.
        </read-more>
    </div>
</ng-template>
<ng-template #ARTICLE_MIDDLE>
    <div class="article middle">
        <h3 i18n>Reveal the cards</h3>
        <span i18n class="article-paragraph">Once everyone has submitted their effort estimates, the organizer reveals
            the cards.</span>
        <read-more class="article-paragraph" i18n>
            Subsequently, the team discusses why there were differing estimates. The goal is to agree on a common
            estimate. Afterward, the organizer can
            clear the estimates. The next task is presented – and the process starts over.
        </read-more>
    </div>
</ng-template>
<ng-template #ARTICLE_BOTTOM>
    <div class="article bottom" *ngIf="!estimateService.ownerHasPremium">
        <h3 i18n>Before estimating make sure that the user story is “ready” and was discussed</h3>
        <span i18n class="article-paragraph">This might be a straight-forward advice but to have a successful estimation
            session, it is important that the feature or user story that we want to estimate is ready. So how do we get
            a story ready? </span>
        <read-more class="article-paragraph">
            <p i18n class="article-paragraph">In Scrum this is generally done during
                the refinement sessions, where the product owner together with the development (and often under the
                guidance of a scrum master) discuss the details of the story.</p>
            <p i18n class="article-paragraph">In our team, this starts with the product owner presenting in a
                succinct manner, what the user wants to be able to do and what value for him is. This second part is
                especially important as it should give a sense why what we are doing matters. The most widespread
                structure for a user story is “As a …, I want to do …, so that …”.</p>
            <p i18n class="article-paragraph">Often the product owner will also have written down some high-level
                acceptance criteria, that are then discussed with the development team. Maybe counterintuitively, good
                acceptance criteria are high-level conditions that would satisfy the customer and allow them to achieve
                the value defined and not finely detailed requirement description of the solution. Together the scrum
                team discusses the the user story looking at the acceptance criteria and might decide to add or remove
                some of them or clarifying elements that are clearly out of scope.</p>
            <p i18n class="article-paragraph">Once everyone agrees on the acceptance criteria and the definition
                of
                done, we can move on to the actual estimation using in our case the Scrum Poker (also called
                Planning
                Poker).
            </p>
        </read-more>
        <h3 i18n>Estimating using scrum poker</h3>
        <p i18n class="article-paragraph">This is the moment where the estimation tool on scrumpoker-online.org
            becomes helpful.
        </p>
        <read-more class="article-paragraph">
            We recommend opening the session at the beginning of the refinement or planning session and have all members
            of the scrum team enter the room using the room ID provided. Once the user story has been discussed and all
            question answered all the members of the development team start estimating the complexity of the story by
            giving it story points. Ok, story points and complexity, you make that sound easy… but what exactly are
            story points and how do I know how many story points should be assigned to a story?
        </read-more>

        <h3 i18n>Story Points</h3>
        <span i18n class="article-paragraph">Story points in scrum are an abstract measure to represent the complexity
            of implementing a user story.</span>
        <read-more class="article-paragraph">
            <p i18n class="article-paragraph">
                In general this “complexity” is related of course to effort, but also to risk and difficulties
                foreseen. The measure is abstract, because it cannot be related to a unit of time such as person days or
                hours.
            </p>
            <p i18n class="article-paragraph">Scrumpoker-online.org uses the fibonacci sequence (1,2,3,5,8,13,21) to
                estimate stories. It is also very helpful to have a reference user story that all the members of the
                scrum team have a good understanding of and assign it an estimate. The team can then start estimating
                other user stories by comparing them to the reference user story. So for example if the reference user
                story was estimated at 3 points, a story that has only 1 point should be three times less complex. As
                such the absolute value of the stories are less important than their relation to each other. And
                remember to stay agile and start experimenting, the more stories the team estimates the better they will
                get at it.
            </p>
        </read-more>

        <h3 i18n>Uncovering the results</h3>
        <span i18n class="article-paragraph">After everyone has turned in their estimates, the product owner or scrum
            master shows the results and if they all match, the story has been successfully estimated.</span>
        <read-more class="article-paragraph" i18n>
            If on the other hand there are some discrepancies, the members that are the furthest apart can start
            discussing why their estimates diverge. Most often this is an indication that there is not yet a common
            understanding of everything that this story entails. This discussion can lead to redefining the acceptance
            criteria and this is absolutely normal, this is afterall an iterative process.
        </read-more>

        <h3 i18n>Ok great, I now have an estimate but why does it matter?</h3>
        <span i18n class="article-paragraph">A well estimated story helps greatly for the product owner to better judge
            if the value of a user story (or new functionality) is worth the complexity and effort to implement
            it.</span>
        <read-more class="article-paragraph">
            Furthermore it allows to get a better grasp on planning a sprint: after the first sprint, the team will know
            exactly how many story points they were able to achieve, thus the following sprint should be filled with a
            similar sum of story points. As the team gains knowledge and becomes more efficient, delivery of an amount
            of story points could be achieved faster than previously. But this is the second step – for now start
            estimating during yourplanning poker session using scrumpoker-online.org and enjoy the pleasure of trying to
            agree on an estimate… You will see, it will do wonders for producing a shared understanding of what is
            expected.
        </read-more>
    </div>
</ng-template>
