<div [ngClass]="responsiveService.getBigSmall()" class="component-container">
    <div class="table-container" class="mat-elevation-z8">
        <!--button-bar-->
        <div class="button-bar">
            <div class="left">
                <button *ngIf="showClearButton()" mat-icon-button (click)="clearRoom()" class="clear-room-button"
                    i18n-matTooltip matTooltip="Remove the names of all participants">
                    <mat-icon>person_remove</mat-icon>
                </button>
            </div>
            <div class="center">
                <button *ngIf="showDeleteEstimatesButton()" mat-raised-button [ngClass]="deleteEstimatesClass"
                    class="transition-button delete-estimates-button" (click)="clearEstimates()">
                    <ng-container *ngIf=" !responsiveService.isScreenSmall()">
                        <span i18n>Delete Estimates</span>
                    </ng-container>
                    <ng-container *ngIf=" responsiveService.isScreenSmall()">
                        <mat-icon>delete</mat-icon>
                    </ng-container>
                </button>
                <button *ngIf="showHideButton()" [ngClass]="showHideClass" mat-raised-button
                    class="transition-button show-hide-button" (click)="toggleShowHideEstimates()">
                    <span *ngIf="estimateService.showEstimates " i18n>Hide</span>
                    <span *ngIf="!estimateService.showEstimates" i18n>Show</span>
                </button>
            </div>

            <div class="right">
                <button mat-icon-button *ngIf="isOwner" i18n-matTooltip matTooltip="Open room settings"
                    (click)="openSettings()">
                    <mat-icon>settings</mat-icon>
                </button>
                <button *ngIf="!isDialog" mat-icon-button (click)="openResultsDialog()" i18n-matTooltip
                    matTooltip="Open results in presentation mode">
                    <mat-icon>fit_screen</mat-icon>
                </button>
            </div>
        </div>
        <!--table-->
        <table mat-table [dataSource]="estimateService.estimates">
            <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef> ID </th>
                <td mat-cell *matCellDef="let estimate"> {{estimate.id}} </td>
            </ng-container>
            <ng-container matColumnDef="displayName">
                <th mat-header-cell *matHeaderCellDef i18n> Name </th>
                <td mat-cell *matCellDef="let estimate">
                    <app-user-presence *ngIf="estimateService.userPresence" [uid]="estimate.userUid">
                    </app-user-presence>
                    {{estimate.displayName}}
                </td>
            </ng-container>
            <ng-container matColumnDef="storyPoints">
                <th mat-header-cell *matHeaderCellDef>
                    <div class="points-column" i18n>Story Points</div>
                </th>
                <td mat-cell *matCellDef="let estimate" class="story-points points-column">
                    <div class="flip-card" [ngClass]="flipCardTransform">
                        <div class="flip-card-inner">
                            <div class="flip-card-front">
                                <img *ngIf="estimate.storyPoints" src="assets/images/logo_trans.png" alt="SP"
                                    style="width:30px;height:30px;">
                                <mat-icon *ngIf="!estimate.storyPoints " class="black-light">remove
                                </mat-icon>
                            </div>
                            <div class="flip-card-back">
                                <span class="middle" *ngIf="estimate.storyPoints==='cof'">
                                    <mat-icon>coffee</mat-icon>
                                </span>
                                <span *ngIf="estimate.storyPoints!=='cof'">
                                    {{estimate.storyPoints}}
                                </span>
                            </div>
                        </div>
                    </div>
                </td>
            </ng-container>
            <!--average-->
            <ng-container matColumnDef="average-label">
                <td mat-footer-cell *matFooterCellDef class="average">
                    <span i18n>Average</span>
                </td>
            </ng-container>
            <ng-container matColumnDef="average-result">
                <td mat-footer-cell *matFooterCellDef class="story-points points-column">
                    <div class="flip-card" [ngClass]="flipCardTransform">
                        <div class="flip-card-inner">
                            <div class="flip-card-front">
                                <img src="assets/images/logo_trans.png" alt="SP" style="width:30px;height:30px;">
                            </div>
                            <div class="flip-card-back average">
                                {{estimateService.averageEstimate}}
                            </div>
                        </div>
                    </div>
                </td>
            </ng-container>
            <!--median-->
            <ng-container matColumnDef="median-label">
                <td mat-footer-cell *matFooterCellDef class="average">
                    <span i18n>Median</span>
                </td>
            </ng-container>
            <ng-container matColumnDef="median-result">
                <td mat-footer-cell *matFooterCellDef class="story-points points-column">
                    <div class="flip-card" [ngClass]="flipCardTransform">
                        <div class="flip-card-inner">
                            <div class="flip-card-front">
                                <img src="assets/images/logo_trans.png" alt="SP" style="width:30px;height:30px;">
                            </div>
                            <div class="flip-card-back average">
                                {{estimateService.medianEstimate}}
                            </div>
                        </div>
                    </div>
                </td>
            </ng-container>
            <!--header, rows, footers-->
            <tr mat-header-row *matHeaderRowDef="estimateService.displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: estimateService.displayedColumns;"></tr>
            <tr mat-footer-row *matFooterRowDef="['average-label', 'average-result']"
                [hidden]="!estimateService.showAverage "></tr>
            <tr mat-footer-row *matFooterRowDef="['median-label', 'median-result']"
                [hidden]="!estimateService.showMedian || !ownerHasPremium"></tr>

        </table>
    </div>

</div>
