import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import firebase from 'firebase/compat/app';
import { Observable, Subscription, of } from 'rxjs';
import { first, switchMap } from 'rxjs/operators';
import { NGXLogger as LoggerService } from "ngx-logger";

// 1. Signed-in and using app (online)
// 2. Signed-in but app is closed (offline)
// 3. Signed-in but on a different browser tab (away)
// 4. Signed-out but app is still opened (offline)
// 5. Signed-out and app closed (offline)

@Injectable({
    providedIn: 'root'
})
export class PresenceService {
    sub: Subscription;
    constructor(
        private afAuth: AngularFireAuth,
        private db: AngularFireDatabase,
        private logger: LoggerService
    ) {
    }


    private updateOnDisconnect() {
        // if authstae changes the presence needs to be adapted
        this.sub = this.afAuth.authState.pipe(
            switchMap(user => {
                if (user) {
                    this.logger.log('afAuth.authState.pipe');
                    this.setConnectionCallback(user.uid);
                    return of(user);
                }
            })
        ).subscribe();

    }

    public setConnectionCallback(uid: string) {
        this.logger.warn('setConnectionCallback on uid: ' + uid);
        this.updateOnAway();
        //    this.updateOnDisconnect();
        const connectedRef = firebase.database().ref('.info/connected'); // special firebae reference to check connection
        const presenceRef = firebase.database().ref('status/' + uid);
        // we need to set the onDisconnect every time we connect because the onDisconnect is eexcuted only once
        connectedRef.on('value', (snap) => {
            if (snap.val() === true) {
                // first set the onDisconnect to be sure this works if in the middle the connection gets lost
                presenceRef.onDisconnect().update({
                    status: 'offline',
                    timestamp: this.timestamp
                });
                this.logger.log('CONNECTED' + uid);
                presenceRef.update({
                    status: 'online',
                    timestamp: this.timestamp
                });

            } else {
                this.logger.log('OFFLINE');
            }
        });
    }



    getPresence(uid: string): Observable<{ status?: string }> {
        return this.db.object(`status/${uid}`).valueChanges();
    }

    getUser() {
        return this.afAuth.authState.pipe(first()).toPromise();
    }

    async setPresence(status: string) {
        const user = await this.getUser();
        if (user) {
            return this.db.object(`status/${user.uid}`).update({ status, timestamp: this.timestamp });
        }
    }

    get timestamp() {
        return firebase.database.ServerValue.TIMESTAMP;
    }

    // User navigates to a new tab, case 3
    updateOnAway() {
        this.logger.warn('updateOnAway');
        document.onvisibilitychange = (e) => {
            if (document.visibilityState === 'hidden') {
                this.setPresence('away');
            } else {
                this.setPresence('online');
            }
        };
    }


    // needs to be called on log out
    async signOut() {
        //    this.sub.unsubscribe();
        await this.setPresence('offline');
    }


}
