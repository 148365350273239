<!-- Menu-->
<span class="menu-button">
    <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Menu">
        <mat-icon>menu</mat-icon>
    </button>
</span>
<mat-menu #menu="matMenu">
    <span *ngIf="!userService.isLoggedIn()">
        <button mat-menu-item routerLink="/user/signup">
            <mat-icon>person_add</mat-icon>
            <span i18n>Sign up</span>
        </button>
        <button mat-menu-item routerLink="/user/login">
            <mat-icon>login</mat-icon>
            <span i18n>Login</span>
        </button>
    </span>

    <span *ngIf="userService.isLoggedIn()">
        <button *ngIf="authService.isAnonymous" mat-menu-item routerLink="/user/account/signup" class="menu-button">
            <mat-icon>person_add</mat-icon>
            <span i18n>Sign up</span>
        </button>
        <button mat-menu-item routerLink="/user/account/profile" class="menu-button">
            <mat-icon>settings</mat-icon>
            <span i18n>Profile</span>
        </button>
        <button mat-menu-item (click)="logout()" class="menu-button">
            <mat-icon>logout</mat-icon>
            <span i18n>Logout</span>
        </button>
    </span>

    <button mat-menu-item routerLink="/choose-language" class="menu-button">
        <mat-icon>language</mat-icon>
        <span i18n>Language</span>
    </button>

    <button mat-menu-item routerLink="/feedback" class="menu-button">
        <mat-icon>add_comment</mat-icon>
        <span i18n>Contact</span>
    </button>
    <button mat-menu-item routerLink="/about" class="menu-button">
        <mat-icon>sports_kabaddi</mat-icon>
        <span i18n>About Us</span>
    </button>
    <button mat-menu-item routerLink="/blog" class="menu-button">
        <mat-icon>article</mat-icon>
        <span i18n>Blog</span>
    </button>
</mat-menu>