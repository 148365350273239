import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NGXLogger as LoggerService } from "ngx-logger";
import { ResponsiveService } from '../common/responsive.service';
import { EditTimerDialogComponent } from './edit.timer-dialog.component';
import { TimerService } from './timer.service';

@Component({
    selector: 'timer',
    templateUrl: './timer.component.html',
    styleUrls: ['./timer.component.scss']
})

export class TimerComponent implements OnInit {
    @Input() isOwner: boolean;
    @ViewChild('componentContainer') componentContainer: ElementRef;

    constructor(
        private logger: LoggerService,
        public responsiveService: ResponsiveService,
        public timerService: TimerService,
        public dialog: MatDialog,
    ) {
    }
    ngOnInit() {
    }
    public handleClickStart() {
        this.logger.log("handleClickStart")
        this.timerService.start()
    }
    public handleClickCancel() {
        this.logger.log("handleClickCancel")
        this.timerService.cancel();
    }

    public handleClickPause() {
        this.logger.log("handleClickPause")
        this.timerService.pause();
    }


    openDialog() {
        const offsetTop = (this.componentContainer.nativeElement.offsetTop + 50).toString() + "px"
        const offsetLeft = (this.componentContainer.nativeElement.offsetLeft).toString() + "px"
        this.logger.log('offset', offsetTop)
        const dialogRef = this.dialog.open(EditTimerDialogComponent, {
            data: { minutes: this.timerService.startMinutes, seconds: this.timerService.startSeconds },
            position: { top: offsetTop, left: offsetLeft },
            autoFocus: 'dialog' // make sure the focus is not on some random button button still on the dialog

        });
        dialogRef.afterClosed().subscribe(async (result) => {
            if (result) {
                this.logger.log('The dialog was closed', result);
                this.timerService.cancel()
                this.timerService.setStartMinutesAndSeconds(result.minutes, result.seconds)
            }
        });
    }
}
