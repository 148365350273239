<div [ngClass]="responsiveService.getBigSmall()">
    <div class="results">
        <!--BUTTONS-->
        <div class="results-header">
            <div class="results-buttons">
                <span *ngIf="showClearButton()">
                    <button mat-icon-button (click)="clearRoom()" class="clear-room-button" i18n-matTooltip
                        matTooltip="Remove the names of all participants">
                        <mat-icon>person_remove</mat-icon>
                    </button>
                </span>
                <span *ngIf="showDeleteEstimatesButton()" [ngClass]="deleteEstimatesClass">
                    <button mat-raised-button class="transition-button" (click)="clearEstimates()" i18n>
                        Delete Estimates
                    </button>
                </span>
                <span *ngIf="showHideButton()" [ngClass]="showHideClass">
                    <button mat-raised-button class="transition-button" (click)="toggleShowHideEstimates()">
                        <span *ngIf="estimateService.showEstimates" i18n>Hide</span>
                        <span *ngIf="!estimateService.showEstimates" i18n>Show</span>
                    </button>
                </span>
            </div>
        </div>
        <!--TABLE-->
        <div class="results-content">
            <table mat-table [dataSource]="estimateService.estimates" class="mat-elevation-z8">
                <ng-container matColumnDef="id">
                    <th mat-header-cell *matHeaderCellDef> ID </th>
                    <td mat-cell *matCellDef="let estimate"> {{estimate.id}} </td>
                </ng-container>
                <ng-container matColumnDef="displayName">
                    <th mat-header-cell *matHeaderCellDef i18n> Name </th>
                    <td mat-cell *matCellDef="let estimate">
                        <app-user-presence *ngIf="estimateService.userPresence" [uid]="estimate.userUid">
                        </app-user-presence>
                        {{estimate.displayName}}
                    </td>
                </ng-container>
                <ng-container matColumnDef="storyPoints">
                    <th mat-header-cell *matHeaderCellDef>
                        <div class="points-column" i18n>Story Points</div>
                    </th>
                    <td mat-cell *matCellDef="let estimate" class="story-points points-column">
                        <div class="flip-card" [ngClass]="flipCardTransform">
                            <div class="flip-card-inner">
                                <div class="flip-card-front">
                                    <img *ngIf="estimate.storyPoints" src="assets/images/logo_trans.png" alt="SP"
                                        style="width:30px;height:30px;">
                                    <mat-icon *ngIf="!estimate.storyPoints " class="black-light">remove
                                    </mat-icon>
                                </div>
                                <div class="flip-card-back">
                                    <span class="middle" *ngIf="estimate.storyPoints==='cof'">
                                        <mat-icon>coffee</mat-icon>
                                    </span>
                                    <span *ngIf="estimate.storyPoints!=='cof'">
                                        {{estimate.storyPoints}}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </td>
                </ng-container>
                <!--average-->
                <ng-container matColumnDef="average-label">
                    <td mat-footer-cell *matFooterCellDef class="average">
                        <span i18n>Average</span>
                    </td>
                </ng-container>
                <ng-container matColumnDef="average-result">
                    <td mat-footer-cell *matFooterCellDef class="story-points points-column">
                        <div class="flip-card" [ngClass]="flipCardTransform">
                            <div class="flip-card-inner">
                                <div class="flip-card-front">
                                    <img src="assets/images/logo_trans.png" alt="SP" style="width:30px;height:30px;">
                                </div>
                                <div class="flip-card-back average">
                                    {{estimateService.averageEstimate}}
                                </div>
                            </div>
                        </div>
                    </td>
                </ng-container>
                <!--median-->
                <ng-container matColumnDef="median-label">
                    <td mat-footer-cell *matFooterCellDef class="average">
                        <span i18n>Median</span>
                    </td>
                </ng-container>
                <ng-container matColumnDef="median-result">
                    <td mat-footer-cell *matFooterCellDef class="story-points points-column">
                        <div class="flip-card" [ngClass]="flipCardTransform">
                            <div class="flip-card-inner">
                                <div class="flip-card-front">
                                    <img src="assets/images/logo_trans.png" alt="SP" style="width:30px;height:30px;">
                                </div>
                                <div class="flip-card-back average">
                                    {{estimateService.medianEstimate}}
                                </div>
                            </div>
                        </div>
                    </td>
                </ng-container>
                <!--header, rows, footers-->
                <tr mat-header-row *matHeaderRowDef="estimateService.displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: estimateService.displayedColumns;"></tr>
                <tr mat-footer-row *matFooterRowDef="['average-label', 'average-result']"
                    [hidden]="!estimateService.showAverage "></tr>
                <tr mat-footer-row *matFooterRowDef="['median-label', 'median-result']"
                    [hidden]="!estimateService.showMedian || !ownerHasPremium"></tr>

            </table>


        </div>
    </div>
</div>