import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UIMesageService } from '../common/ui-message.service';
import { NGXLogger as LoggerService } from "ngx-logger";
import { ResponsiveService } from '../common/responsive.service';
import { RoomIdService } from '../poker-room/room-id.service';
import { EstimateService } from '../poker-room/estimate.service';
import { UserService } from '../user/user.service'; import { AuthService } from '../user/auth.service';
import { HttpClient } from '@angular/common/http';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { environment } from '../../environments/environment';
import { firstValueFrom } from 'rxjs';

@Component({
    selector: 'admin',
    templateUrl: './admin.page.html',
    styleUrls: ['./admin.scss']


})
export class AdminPage implements OnInit {

    public secretKey: string = ""// check the firebase function shop-index.ts
    public productKey: string = "scrumpoker-premium-month"
    constructor(
        private router: Router,
        private uiMessage: UIMesageService,
        private logger: LoggerService,
        private route: ActivatedRoute,
        public responsiveService: ResponsiveService,
        private estimateService: EstimateService,
        public userService: UserService,
        private authService: AuthService,
        private http: HttpClient,
        private fns: AngularFireFunctions,

    ) {

    }

    ngOnInit() {
    }


    deleteOldRooms() {
        if (window.confirm('Are you sure you want to delete all old Rooms?')) {
            this.estimateService.deleteOldRooms();
        } else {
            this.logger.log('Deletion canceled');
        }
    }

    deleteOldUserDocs() {
        if (window.confirm('Are you sure you want to delete all old USER DOCS?')) {
            this.userService.deleteOldUsers();
        } else {
            this.logger.log('Deletion canceled');
        }
    }
    async addProductForUserManually() {
        const urlHost = environment.firebase.functionsUrl
        const functionUrl = urlHost + '/shop-addProductForUserManually';
        const userId = this.authService.uid;
        const reason = 'test-from-admin-page';
        // combine the url and  open it in a new window
        const url = `${functionUrl}?securityKey=${this.secretKey}&userId=${userId}&reason=${reason}&buyaybleProductKey=${this.productKey}`;
        this.logger.warn('url', url);
        window.open(url, '_blank');

    }
}
