import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { RoomIdService } from './room-id.service';
import { ResponsiveService } from '../common/responsive.service';
import { NGXLogger as LoggerService } from "ngx-logger";
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { EstimateService } from './estimate.service';
import { AuthService } from '../user/auth.service';
import { UIMesageService } from '../common/ui-message.service';
import { GoogleAnalyticsService } from '../common/google-analytics.service';
import { UserService } from '../user/user.service';
import { Router } from '@angular/router';

export interface DialogData {

}

@Component({
    selector: 'login-gate-dialog',
    templateUrl: 'login-gate-dialog.component.html',
    styleUrls: ['login-gate-dialog.component.scss']
})

export class LoginGateDialog {
    enterRoomForm: FormGroup;
    public roomId: FormControl;
    public newDeveloperName: FormControl;
    enteringRoom: boolean = false
    constructor(
        public logger: LoggerService,
        public roomIdService: RoomIdService,
        public responsiveService: ResponsiveService,
        public dialogRef: MatDialogRef<LoginGateDialog>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
        public dialog: MatDialog,
        private estimateService: EstimateService,
        public authService: AuthService,
        private uiMessage: UIMesageService,
        private googleAnalyticsService: GoogleAnalyticsService,
        private userService: UserService,
        private router: Router
    ) {
    }
    onNoClick(): void {
        this.dialogRef.close();
    }

    ngOnInit() {
        this.newDeveloperName = new FormControl(
            '',
            [Validators.required]
        );
        this.enterRoomForm = new FormGroup({
            newDeveloperName: this.newDeveloperName,
        });
    }

    getDeveloperNameErrorMessage() {
        return this.newDeveloperName.hasError('required') ? ' Enter your name' :
            '';
    }

    cancel() {
        this.dialogRef.close();
        this.router.navigate(['/index']);
    }

    async enterRoom(formValues) {
        this.logger.log('logingate: enterRoomForm');

        this.enteringRoom = true;
        if (this.enterRoomForm.valid) {
            try {
                this.logger.log('logingate: signUpAnonymous befor');
                await this.authService.signUpAnonymous(this.newDeveloperName.value, false);
                this.logger.log('logingate: signUpAnonymous after');
                const roomId = this.roomIdService.getCurrentRoomID();
                const result = await this.estimateService.isExistentRoom(roomId);
                if (result) {
                    this.googleAnalyticsService.eventEmitter('Login Gate', 'Poker Room', 'enterRoom Action');
                    this.estimateService.enterRoom(
                        this.roomIdService.getCurrentRoomID(),
                        this.userService.getDisplayName(),
                        this.userService.getUid()
                    );

                } else {
                    this.uiMessage.warning('A room with Id ' + formValues.roomId + ' does not exist.');
                }
                this.enteringRoom = false
                this.dialogRef.close();

            } catch (error) {
                this.router.navigate(['/index']);
                this.dialogRef.close();

                this.uiMessage.error(error, 'Entering room failed. ');
            }
        } else {
            this.uiMessage.warning('Form not valid');
        }
    }
}

