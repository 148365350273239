import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { LoggerService } from '../../core/logger.service';
import { ResponsiveService } from '../../core/responsive.service';

export interface TermsData {

}

@Component({
    selector: 'ess-terms-dialog',
    templateUrl: 'terms-dialog.component.html',
    styleUrls: ['terms-dialog.component.scss']
})

export class EssTermsDialogComponent {
    constructor(
        public logger: LoggerService,
        public responsiveService: ResponsiveService,
        public dialogRef: MatDialogRef<EssTermsDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: TermsData,
        public dialog: MatDialog
    ) {
    }

    onNoClick(): void {
        this.dialogRef.close();
    }
    openNewWindow() {
        const url = '../../assets/terms/terms-en.html';
        const windowFeatures = "popup=1,left=100,top=100,width=800,height=800";
        const handle = window.open(url, 'terms', windowFeatures);
        if (!handle) {
            window.open(url, 'terms');

        }
    }
}
