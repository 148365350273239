<div [ngClass]="responsiveService.getBigSmall()">

    <ess-spinner *ngIf="isLoading">
        <span i18n>Loading...</span>
    </ess-spinner>

    <div *ngIf="!isLoading" [ngClass]="estimateService.ownerHasPremium?'advert':''">
        <div *ngIf="isExistentRoom else roomDoesNotExist">
            <div *ngIf="userService.isLoggedIn() else guest">
                <!--SMALL SCREEN-->
                <span *ngIf="responsiveService.isScreenSmall()">
                    <div *ngIf="advertisingService.enableAdds" class="ad-container-header-mobile">
                        <!-- 71161633/header_mobile/header_mobile -->
                        <div *ngIf="advertisingService.showFuseAddBlocks" id="22851570040" data-fuse="22851570040">
                        </div>
                        <div *ngIf="advertisingService.showPrimisAddBlocks" id="primis-header-mobile" class="primis-ad">
                        </div>
                    </div>
                    <provide-estimate [isOwner]="isOwner" [ownerHasPremium]="estimateService.ownerHasPremium">
                    </provide-estimate>
                    <mat-divider class="divider"></mat-divider>
                    <div class="results-title">
                        <span i18n>Results</span>
                    </div>
                    <results></results>
                    <div *ngIf="advertisingService.enableAdds" class="ad-container-footer-mobile">
                        <!-- 71161633/footer_mobile/footer_mobile -->
                        <div *ngIf="advertisingService.showFuseAddBlocks" id="22851418830" data-fuse="22851418830">
                        </div>
                        <div *ngIf="advertisingService.showPrimisAddBlocks" id="primis-footer-mobile" class="primis-ad">
                        </div>
                    </div>
                </span>
                <!--BIG SCREEN-->
                <span *ngIf="!responsiveService.isScreenSmall()">
                    <div class="poker-room-container">
                        <div class="row">
                            <div class="column middle">
                                <kp-alert *ngIf="authService.isAnonymous  && isOwner" type="info" alertId="Anonymous"
                                    i18n-title title="You have been provided a temporary account." i18n-messageLine1
                                    messageLine1="To enable additional features we recommend you sign-up."
                                    i18n-messageLine4 messageLine4="Paul & Kai." goToLink="user/account/signup"
                                    i18n-goToText goToText="Sign up">
                                </kp-alert>
                                <kp-alert *ngIf="!authService.isAnonymous  && !authService.hasPremium() && isOwner"
                                    type="info" alertId="Upgrade2" i18n-title title="Upgrade to 'Premium'"
                                    i18n-messageLine1
                                    messageLine1="Enjoy an ad-free experience, enhanced features like personalized room names, advanced display settings, and a voting timer."
                                    i18n-messageLine2 messageLine2=" Upgrade now for a smoother Scrum Poker session!"
                                    i18n-messageLine4 messageLine4="Paul & Kai." goToLink="user/account/upgrade"
                                    i18n-goToText goToText="Upgrade">
                                </kp-alert>
                                <provide-estimate [isOwner]="isOwner"
                                    [ownerHasPremium]="estimateService.ownerHasPremium">
                                </provide-estimate>
                                <mat-divider class="divider"></mat-divider>
                                <div class="results-title">
                                    <span i18n>Results</span>
                                    <div class="open-full">
                                        <button mat-icon-button (click)="openResultsDialog()" i18n-matTooltip
                                            matTooltip="Open results in presentation mode">
                                            <mat-icon>fit_screen</mat-icon>
                                        </button>
                                    </div>
                                </div>
                                <div class="results">
                                    <results [ownerHasPremium]="estimateService.ownerHasPremium"></results>
                                </div>
                                <div *ngIf="advertisingService.enableAdds" class="add-container-middle">
                                    <div *ngIf="advertisingService.showFuseAddBlocks">
                                        <!-- 71161633/mrec_1/mrec_1 -->
                                        <div class="fuse-add-middle" id="22851570049" data-fuse="22851570049">
                                        </div>
                                        <!-- 71161633/mrec_2/mrec_2 -->
                                        <div class="fuse-add-middle" id="22851570043" data-fuse="22851570043">
                                        </div>
                                    </div>
                                    <div *ngIf="advertisingService.showPrimisAddBlocks" class="primis-middle-container">
                                        <div class="primis-ad" id="primis-middle-1">
                                        </div>
                                        <div class="primis-ad" id="primis-middle-2">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="advertisingService.enableAdds" class="column right">
                                <div class="add-container-right">
                                    <div *ngIf="environment.enableAdsense">
                                        <ng-adsense *ngIf="!delayAdsense" [display]="'inline-block'" [width]="250"
                                            [height]="250" [adSlot]="6733965246">
                                        </ng-adsense>
                                        <ng-adsense *ngIf="!delayAdsense" [display]="'inline-block'" [width]="250"
                                            [height]="600" [adSlot]="7670770250">
                                        </ng-adsense>
                                    </div>
                                    <div *ngIf="advertisingService.showFuseAddBlocks">
                                        <!-- 71161633/vrec_1/vrec_1 -->
                                        <div class="fuse-add-right" id="22851570046" data-fuse="22851570046">
                                        </div>
                                        <!-- 71161633/vrec_2/vrec_2 -->
                                        <div class="fuse-add-right" id="22851569437" data-fuse="22851569437">
                                        </div>

                                    </div>
                                    <div *ngIf="advertisingService.showPrimisAddBlocks" class="primis-right-container">
                                        <div class="primis-ad" id="primis-right-1">
                                        </div>
                                        <div class="primis-ad" id="primis-right-2">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </span>
            </div>
            <ng-template #guest>
                <login-gate></login-gate>
            </ng-template>
        </div>
        <ng-template #roomDoesNotExist>
            <h1>
                Room "{{roomIdService.getCurrentRoomID()}}" does not exist
            </h1>
            <h2>
                Sorry, it seeems you got the wrong room number.
            </h2>
            <h2>
                Please try an other one!
            </h2>
        </ng-template>
    </div>
</div>