<div [ngClass]="responsiveService.getBigSmall()">
    <div class="spo-container">
        <div class="spo-container-title" i18n>
            Enter Room
        </div>
        <div class="spo-container-subtitle" i18n>
            Provide your name or any pseudonym to enter the scrum poker room
            {{roomIdService.getCurrentRoomID() | room }}.
        </div>
        <div class="spo-container-content">
            <form [formGroup]="enterRoomForm" (ngSubmit)="enterRoom(enterRoomForm.value)" autocomplete="off" novalidate>
                <div class="spo-field-container">
                    <mat-form-field appearance="outline">
                        <mat-label i18n>Display Name</mat-label>
                        <input matInput placeholder="Paul Doe" [formControl]="newDeveloperName" required>
                        <mat-error *ngIf="newDeveloperName.invalid">{{getDeveloperNameErrorMessage()}}
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="spo-field-container">
                    <button mat-flat-button color="primary" type="submit" i18n class="spo-font-color-white">
                        ENTER ROOM
                    </button>
                </div>
                <div class="spo-field-container cancel">
                    <button mat-raised-button (click)="cancel()" type="button" i18n>
                        CANCEL
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>