<div [ngClass]="responsiveService.getBigSmall()">
  <div class="spo-container">

    <div class="how-it-works spo-container-content">
      <h2 i18n>HOW IT WORKS</h2>
      <div class="row">
        <div class="column picture-big-screen spo-border">
          <img src="assets/images/create_scrum_poker_room.png" i18n-alt alt="Create Scrum Poker Room">
        </div>
        <div class="column right-text">
          <h3 class="first-h3" i18n>Create an Instant Room</h3>
          <div class="column picture-small-screen spo-border">
            <img src="assets/images/create_scrum_poker_room.png" i18n-alt alt="Create Scrum Poker Room">
          </div>

          <p i18n>Set-up in seconds. Use the instant room feature or sign-up to keep the same room number for future
            plannings - making the set-up even faster.</p>
        </div>
      </div>

      <div class="row">
        <div class="column left-text">
          <h3 i18n>Invite others</h3>
          <div class="column picture-small-screen spo-border">
            <img src="assets/images/share_planning_poker.png" i18n-alt alt="Share Planning Poker Room">
          </div>
          <p> <span i18n>Invite your colleagues to your planning poker by sharing the room id, let them scan the QR
              code with their mobile or just send them the link. If you have trouble to find a common timeslot, create a
              poll with
              our </span> <a href='{{whoodleUrl}}' target="_blank" i18n>Doodle alternative "Whocan.org"</a>.</p>
        </div>
        <div class="column picture-big-screen spo-border">
          <img src="assets/images/share_planning_poker.png" i18n-alt alt="Share Planning Poker Room">
        </div>
      </div>

      <div class="row">
        <div class="column picture-big-screen spo-border">
          <img src="assets/images/play_scrum_poker.png" i18n-alt alt="Play Scrum Poker for Planning">
        </div>
        <div class="column right-text">
          <h3 i18n>Start estimating stories</h3>
          <div class="column picture-small-screen spo-border">
            <img src="assets/images/play_scrum_poker.png" i18n-alt alt="Play Scrum Poker for Planning">
          </div>
          <p i18n>Once your team has entered the room, you can see them online and the scrum poker can start.
            The team can submit estimates, see who has provided estimates and show the results.
          </p>
        </div>
      </div>
    </div>
  </div>

</div>