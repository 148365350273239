<div [ngClass]="responsiveService.getBigSmall()">
    <div mat-dialog-title>
        <div *ngIf="showBottonContainer" class="button-container">
            <button *ngIf="action1Icon" mat-icon-button color="primary" mat-dialog-close (click)="action1()"
                tabindex="-1">
                <mat-icon class="whoo-icon">{{action1Icon}}</mat-icon>
            </button>
            <button *ngIf="showClose" mat-icon-button color="primary" mat-dialog-close (click)="close()" tabindex="-1">
                <mat-icon class="whoo-icon">close</mat-icon>
            </button>

        </div>
        <div class="title">
            <ng-content></ng-content>
        </div>
    </div>
</div>
