import { Component, Input, OnInit, Optional } from '@angular/core';
import { Validators, AsyncValidatorFn, AbstractControl, FormBuilder, UntypedFormGroup } from '@angular/forms';
import { AuthService } from './auth.service';
import { Router } from '@angular/router';
import { User } from './user.model';
import { UIMesageService } from '../common/ui-message.service';
import { Subscription } from 'rxjs';
import { UserService } from './user.service';
import { NGXLogger as LoggerService } from "ngx-logger";
import { ResponsiveService } from '../common/responsive.service';
import { EstimateService } from '../poker-room/estimate.service';
import { map } from 'rxjs/operators';
import { ActiveProduct } from 'functions-lib';
import { RoomIdService } from '../poker-room/room-id.service';
import { MatDialogRef } from '@angular/material/dialog';
import { GenerateRoomService } from '../poker-room/generate-room.service';

@Component({
    selector: 'settings',
    templateUrl: './settings.component.html',
    styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {
    @Input() appearance: string = ""
    public profileForm: UntypedFormGroup;
    private roomSubscription: Subscription;
    private estimateOptions: string;
    private allowHide: string;
    private allowClear: string;
    private allowDeleteEstimates: string;
    private userPresence: string;
    private showAverage: string;
    private showMedian: string;
    private showTimer: string;
    public markedForDeletion: any;
    private activeProductsSubscription: Subscription;
    public activeProducts: ActiveProduct[];
    private userSubscription: Subscription;
    public initialRoomId: { value: string } = { value: "" };


    constructor(private router: Router,
        public userService: UserService,
        public responsiveService: ResponsiveService,
        public estimateService: EstimateService,
        public generateRoomService: GenerateRoomService,
        public authService: AuthService,
        private logger: LoggerService,
        private uiMessage: UIMesageService,
        private formBuilder: FormBuilder,
        private roomIdService: RoomIdService,
        @Optional() public dialogRef: MatDialogRef<SettingsComponent>,


    ) {
        this.estimateOptions = this.estimateService.getDefaultEstimateOptions().toString();
    }
    ngOnInit() {
        this.initialRoomId.value = this.userService.getRoomId();
        this.initForm()
        this.getUser();
        this.roomSubscription = this.setRoomObserver();
    }

    get isDialog(): boolean {
        return this.dialogRef ? true : false;
    }
    private initForm() {
        this.profileForm = this.formBuilder.group({
            pokerRoomId: [{
                value: '',
                disabled: !this.authService.hasPremium()
            },
            {
                validators: [
                    Validators.required,
                    Validators.minLength(4),
                    Validators.pattern('[a-zA-Z0-9]*')
                ],
                asyncValidators: [roomIdValidatorAsync({ initialRoomId: this.initialRoomId, estimateService: this.estimateService })]
            }],
            estimateOptions: [{ value: this.estimateOptions, disabled: this.authService.isAnonymous }, [optionsValidator]],
            allowHide: [{ value: 'yes', disabled: this.authService.isAnonymous }],
            allowClear: [{ value: this.allowClear, disabled: this.authService.isAnonymous }],
            allowDeleteEstimates: [{ value: this.allowDeleteEstimates, disabled: this.authService.isAnonymous }],
            userPresence: [{ value: 'no', disabled: !this.authService.hasPremium() }],
            showAverage: [{ value: 'no', disabled: !this.authService.hasPremium() }],
            showMedian: [{ value: 'no', disabled: !this.authService.hasPremium() }],
            showTimer: [{ value: this.authService.hasPremium() ? 'yes' : 'no', disabled: !this.authService.hasPremium() }],
        });
    }



    private refreshForm(user: User) {
        if (user) {
            this.markedForDeletion = user.markedForDeletion;
            this.initForm()
            this.patchValues();
            this.initialRoomId.value = user.pokerRoomId
        }
    }
    private patchValues() {
        this.profileForm.patchValue({
            allowHide: this.allowHide,
            allowClear: this.allowClear,
            allowDeleteEstimates: this.allowDeleteEstimates,
            userPresence: this.userPresence,
            showAverage: this.showAverage,
            showMedian: this.showMedian,
            showTimer: this.showTimer,
            estimateOptions: this.estimateOptions,
            pokerRoomId: this.initialRoomId.value
        });



    }
    getRoomIdError() {
        /// this.profileForm.controls['pokerRoomId'].err
    }
    private setRoomObserver(): Subscription {
        this.logger.log('setRoomObserver');
        return this.estimateService.getRoomObservable(this.userService.getRoomId()).pipe(
            map(room => {
                if (room) {
                    if (room.estimateOptions) {
                        this.logger.log('estimateOptions' + room.estimateOptions);
                        this.estimateOptions = room.estimateOptions;
                        this.profileForm.patchValue({ estimateOptions: this.estimateOptions });
                    } else {
                        this.logger.log('no estimate options');
                    }
                    if (room.allowHide) {
                        this.allowHide = room.allowHide;
                    } else {
                        this.allowHide = 'yes';
                    }
                    if (room.allowClear) {
                        this.allowClear = room.allowClear;
                    } else {
                        this.allowClear = 'yes';
                    }
                    if (room.allowDeleteEstimates) {
                        this.allowDeleteEstimates = room.allowDeleteEstimates;
                    } else {
                        this.allowDeleteEstimates = 'yes';
                    }
                    if (room.userPresence) {
                        this.userPresence = room.userPresence;
                    } else {
                        this.userPresence = 'no';
                    }
                    if (room.showAverage) {
                        this.showAverage = room.showAverage;
                    } else {
                        this.showAverage = 'no';
                    }
                    if (room.showMedian) {
                        this.showMedian = room.showMedian;
                    } else {
                        this.showMedian = 'no';
                    }
                    if (room.showTimer) {
                        this.showTimer = room.showTimer;
                    } else {
                        this.logger.log('showTime')
                        this.showTimer = this.authService.hasPremium() ? 'yes' : 'no';
                    }
                    this.patchValues();
                } else {
                    this.logger.log('The room ' + this.userService.getRoomId() + '  does not exist (anymore). Please choose a different one.');
                }
            },
                error => {
                    this.logger.error('Error loading the room' + error);
                }
            )
        ).subscribe();
    }

    public getUser() {
        this.userSubscription = this.userService.getUserObservable().subscribe(
            user => {
                this.refreshForm(user)
            });
    }

    public async handleCreateRoom() {
        if (this.userService.isLoggedIn()) {
            await this.userService.createAndAssignUserRoom(this.authService.uid, this.authService.isAnonymous);
        }
    }

    ngOnDestroy() {
        this.roomSubscription.unsubscribe();
        this.activeProductsSubscription?.unsubscribe()

    }


    async saveProfile() {
        const formValues = this.profileForm.getRawValue();// If to include all values regardless of disabled status, use getRawValue
        this.logger.log('saveProfile', formValues)
        if (this.profileForm.valid) {
            try {
                if (formValues.pokerRoomId !== this.userService.getRoomId()) {
                    try {
                        await this.changeRoomId(formValues.pokerRoomId)
                    } catch (error) {
                        this.uiMessage.warning(error);
                        this.profileForm.patchValue({
                            pokerRoomId: this.userService.getRoomId()
                        })
                        return
                    }
                }
                if (!this.authService.isAnonymous) {
                    const optionsCleanedUp = this.cleanupEstimateOptions(formValues.estimateOptions);
                    this.estimateService.setRoomConfiguration(
                        this.userService.getRoomId(),
                        optionsCleanedUp,
                        formValues.allowHide,
                        formValues.allowClear,
                        formValues.allowDeleteEstimates,
                        formValues.userPresence,
                        formValues.showAverage,
                        formValues.showMedian,
                        formValues.showTimer,
                    );
                }
                this.uiMessage.success('Room settings updated successfully');
                this.dialogRef?.close();
            } catch (error) {
                this.uiMessage.error(error, 'Update failed. ');
            }
        } else {
            this.uiMessage.warning('Form not valid.');
        }
    }

    private async changeRoomId(newRoomId: string) {
        const oldRoomId = this.userService.getRoomId();
        this.logger.log("changeRoomId", newRoomId)
        if (await this.estimateService.isExistentRoom(newRoomId)) {
            throw new Error('Room with Id "' + newRoomId + '" does exsit already')
        } else {
            this.logger.log('changeRoomId', oldRoomId, newRoomId);
            await this.generateRoomService.createRoom(this.userService.getUid(), newRoomId, false);
            this.userService.setRoomId(newRoomId)
            this.estimateService.deleteRoom(oldRoomId)
            this.roomIdService.setRoomIdStr(newRoomId)
            this.initialRoomId.value = newRoomId;

        }
    }
    // replace ending and duplicate commas
    private cleanupEstimateOptions(estimateOptions: string): string {
        let newValue: string = estimateOptions;
        const endsWithComma: boolean = newValue[newValue.length - 1] == ',';
        if (endsWithComma) {
            newValue = newValue.slice(0, newValue.length - 1);
        }
        // replace duplicate commas
        newValue = newValue.replace(/\,{2,}/g, ',');
        return newValue;
    }

    getRoomValidator() {

    }

}
export function roomIdValidatorAsync(parameters: { initialRoomId: { value: string }, estimateService: EstimateService }): AsyncValidatorFn {
    return async (control: AbstractControl) => {
        const newRoomId: string = control.value.toString();
        //        console.error('roomIdValidatorAsync', newRoomId, parameters.initialRoomId)
        if (newRoomId === parameters.initialRoomId.value || parameters.initialRoomId.value === '') {
            return null;
        }
        if (await parameters.estimateService.isExistentRoom(newRoomId)) {
            return { 'roomExists': true };
        }
        return null;
    }
}

function optionsValidator(control: AbstractControl): { [key: string]: boolean } | null {
    const estimateOptions: string[] = control.value.toString().split(',');
    let isOk = true;
    // each element should not be longer than 3 chars.
    estimateOptions.forEach(option => {
        if (option.length > 3) {
            isOk = false;
        }
    });
    if (!isOk) {
        return { 'optionLength': true };
    }
    return null;
}
