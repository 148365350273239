import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { PresenceService } from './online-presence.service';

@Component({
    selector: 'app-user-presence',
    templateUrl: './user-presence.component.html',
    styleUrls: ['./user-presence.component.scss']
})
export class UserPresenceComponent implements OnInit {

    @Input() uid;


    presence$: Observable<{ status?: string }>;


    constructor(private presence: PresenceService) { }

    ngOnInit() {
        this.presence$ = this.presence.getPresence(this.uid);
    }

}
