import { Injectable } from '@angular/core';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { filter, interval, tap } from 'rxjs';
import { LibraryConfigurationService } from '../../core/library-configuration.service';
import { LoggerService } from '../../core/logger.service';
import { UIMesageService } from '../../core/ui-message.service';
import { EssDialogService } from '../dialogs/dialog-service';
import { EssOkCancelDialogComponent } from '../dialogs/ok-cancel-dialog.component';

@Injectable({
    providedIn: 'root',
})
export class EssSoftwareUpdateService {
    public promptUser = false;
    private reloadRequested = false;
    constructor(
        private logger: LoggerService,
        private dialogService: EssDialogService,
        private swUpdates: SwUpdate,
        private libraryConfiguration: LibraryConfigurationService,
        private uiMessage: UIMesageService,
    ) {
        this.logger.log('EssSoftwareUpdateService: constructor');
        this.subscribeVersionUpdates();
    }

    public setTimeTrigger(secondsToCheck?: number) {
        this.logger.log('timeTrigger');
        if (!secondsToCheck) {
            if (this.libraryConfiguration.isDebug) {// in debug mode check every 20 seconds so we get the new version asap
                secondsToCheck = 20;
            } else {
                secondsToCheck = 60;
            }
        }
        // polling for updates with `interval()`.
        const timeTrigger$ = interval(secondsToCheck * 1000);
        /*

        // Allow the app to stabilize first, before starting
        // ?? appRef.isStable. does not work for scrumpoker
        const appIsStable$ = this.appRef.isStable.pipe(first(isStable => isStable === true));//
        const timeTriggerOnceAppIsStable$ = concat(appIsStable$, timeTrigger$);
        timeTriggerOnceAppIsStable$.subscribe(async () => {
        */
        timeTrigger$.subscribe(async () => {
            this.logger.log('timeTrigger');
            try {
                this.logger.log('checkForUpdate');
                if (!this.reloadRequested) {
                    this.logger.log('!reloadRequested checkForUpdate');
                    const updateFound = await this.swUpdates.checkForUpdate();
                    if (updateFound) {
                        this.logger.warn('Update found');
                    } else {
                        this.logger.log('On the latest version.');

                    }
                    // the relaod is requested indirectly by subscribeForAutomaticUpdate
                }
            } catch (err) {
                this.logger.error('Failed to check for updates:', err);
            }
        });
    }

    private subscribeVersionUpdates() {
        this.swUpdates.versionUpdates.subscribe((evt) => {
            switch (evt.type) {
                case 'VERSION_DETECTED':
                    this.logger.warn(`Downloading new app version, hash: ${evt.version.hash}`);
                    break;
                case 'VERSION_READY':
                    this.logger.warn(`Current app version: ${evt.currentVersion.hash}`);
                    this.logger.warn(`New app version ready for use, hash: ${evt.latestVersion.hash}`);
                    this.requestReload();
                    break;
                case 'VERSION_INSTALLATION_FAILED':
                    this.logger.error(`Failed to install app version, hash: '${evt.version.hash}': ${evt.error}`);
                    break;
                case 'NO_NEW_VERSION_DETECTED':
                    this.logger.log('Current version hash', evt.version.hash);
                    break;
                default:
                    this.logger.log('Software update event', evt);
            }
        });
    }

    private requestReload() {
        this.logger.log('requestReload');
        if (!this.reloadRequested) {
            this.reloadRequested = true;
            if (this.promptUser) {
                const dialogRef = this.dialogService.open(
                    EssOkCancelDialogComponent,
                    {
                        title: 'Update',
                        description1:
                            'A new ' +
                            this.libraryConfiguration.appName +
                            ' version is available.',
                        description2:
                            'To continue to use all the features please refresh this browser window.',
                        okLabel: 'Refresh',
                        cancelLabel: 'Cancel',
                    }
                );
                dialogRef.afterClosed().subscribe(async (result) => {
                    this.logger.log('The dialog was closed');
                    if (result) {
                        this.logger.log('Refresh', result);
                        document.location.reload();
                        this.reloadRequested = false;
                    } else {
                        this.reloadRequested = false;
                        this.logger.log('Canceled');
                    }
                });
            } else {
                this.uiMessage.warning('A new ' +
                    this.libraryConfiguration.appName +
                    ' version is available. '
                    + this.libraryConfiguration.appName + ' will reload in few seconds.')
                setTimeout(() => {
                    document.location.reload();
                    this.reloadRequested = false;
                }, 6000);// uimessage lasts 6 seconds

            }
        } else {
            this.logger.warn('Reload was requested already');
        }
    }
}
