// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.



export const environment = {
    adClient: 'ca-pub-XYZ',
    google_analytics_key: 'UA-156986757-1',
    appVersion: require('../../../../package.json').version + '-dev',
    production: false,
    enableFuse: true,
    enablePrimis: false,
    enableAdsense: false, // either adsense or fuse shoudl be enabled but not both

    debug_log: true,
    ngxLoggerLevel: 1,
    ngxServerLoggerLevel: 5,
    brandName: 'ScrumpokerDEV',
    home_button_label: 'ScrumPoker-online.dev',
    home_button_label_short: 'ScrumPoker-online',
    host: 'scrumhelper.com',
    host_url: 'https://www.scrumhelper.com',
    whoodleUrl: 'https://www.whocan.org',
    useEmulators: false,

    firebase: {
        apiKey: 'AIzaSyAuaOYgzBsWcKnVSK7Hy6AJk6cJHnIHCfs',
        authDomain: 'scrumhelper.com',
        databaseURL: 'https://ng-scrum-poker-kp1.firebaseio.com',
        functionsUrl: 'https://us-central1-ng-scrum-poker-kp1.cloudfunctions.net',
        projectId: 'ng-scrum-poker-kp1',
        storageBucket: 'ng-scrum-poker-kp1.appspot.com',
        messagingSenderId: '835583700972',

    }

};



/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
