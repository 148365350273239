import { Routes } from '@angular/router';
import { Error404Page } from './errors/404.component';
import { HomeComponent } from './home/home.page';
import { PokerRoomPage } from './poker-room/poker-room.page';
import { AboutComponent } from './footer/about.component';
import { PrivacyComponent } from './footer/privacy.component';
import { AdminPage } from './admin/admin.page';
import { FeedbackComponent } from './footer/feedback.component';
import { LanguageComponent } from './footer/language.component';
import { PlanningPokerComponent } from './home/planning-poker-home.page';
import { BlogComponent } from './footer/blog.component';
import { BlogContactComponent } from './footer/blog.contact.component';


export const appRoutes: Routes = [
    { path: 'index', redirectTo: '/', pathMatch: 'full',                        data: {id:   'home'               }},
    { path: '',                             component: HomeComponent,           data: {id:   'home'              }},
    { path: 'room/:id',                     component: HomeComponent,           data: {id:   'home'              }},
    { path: 'planning-poker',               component: PlanningPokerComponent,  data: {id:   'planning-poker'    }},
    { path: '404',                          component: Error404Page,            data: {id:   '404'               }},
    { path: 'about',                        component: AboutComponent,          data: {id:   'about'             }},
    { path: 'feedback',                     component: FeedbackComponent,       data: {id:   'feedback'          }},
    { path: 'blog-contact',                 component: BlogContactComponent,    data: {id:   'blog-contact'      }},
    { path: 'blog',                         component: BlogComponent,           data: {id:   'blog'              }},
    { path: 'choose-language',              component: LanguageComponent,       data: {id:   'choose-language'   }},
    { path: 'privacy',                      component: PrivacyComponent,        data: {id:   'privacy'           }},
    { path: 'admin',                        component: AdminPage,               data: {id:   'admin'             }},
    { path: 'room/:id/scrum-poker',         component: PokerRoomPage,           data: {id:   'room'              }},

    { path: 'room/:id/scrum-poker/:id2',    component: PokerRoomPage,           data: {id:   'room'              }},
    { path: 'user', loadChildren: () => import('./user/user.module').then(m => m.UserModule),
        data: {id:   'user'              }},
    {path: '**', redirectTo: '/404'},

];

export const standardTitleIt = 'Scrum Poker Online - strumento gratuito per il poker di pianificazione';
export const standardDescriptionIt = 'Imposta la tua sessione di poker di pianificazione in pochi secondi. App online per impostare e gestire sessioni di planning poker.';
export const routesSEO_it = {
    'home.title':                   standardTitleIt + ' - Home',
    'home.description':             standardDescriptionIt,
    'planning-poker.title':         'Planning Poker Online - strumento gratuito per il poker di pianificazione',
    'planning-poker.description':    'Imposta la tua sessione di poker di pianificazione in pochi secondi. App online per collaborare e eseguire stime con scrum poker.',
    '404.title':                    standardTitleIt + ' - Pagina non trovata',
    '404.description':              standardDescriptionIt,
    'about.title':                  standardTitleIt + ' - Chi siamo',
    'about.description':            'Chi siamo e perché abbiamo sviluppato una App online per Scrum Poker -  la App che richiede solo pochi secondi per impostare una sessione di planning poker.',
    'feedback.title':               standardTitleIt + ' - Invia un Feedback',
    'feedback.description':         'Mandaci un feedback sulla App online di Scrum Poker. Lo strumento che richiede solo pochi secondi per impostare una sessione di planning poker.',
    'blog.title':                   standardTitleIt + ' - Blog',
    'blog.description':             'Scrum e Planning Blog. Il blog per la App che richiede solo pochi secondi per impostare una sessione di planning poker.',
    'blog-contact.title':           standardTitleIt + ' - Contatti',
    'blog-contact.description':     'Scrum e Planning Blog. Il blog per la App che richiede solo pochi secondi per impostare una sessione di planning poker',
    'choose-language.title':        standardTitleIt + ' - Scegli la tua lingua',
    'choose-language.description':  'Scrum Poker Online App è disponibile in molte lingue. Puoi scegliere la tua lingua e impostare la tua sessione di poker di pianificazione in pochi secondi.',
    'privacy.title':                standardTitleIt + ' - Informativa sulla Privacy',
    'privacy.description':          'Scrum Poker Online App prende la privacy molto seriamente. Leggi l\'informativa e imposta la tua sessione di poker di pianificazione in pochi secondi.',
    'room.title':                   standardTitleIt ,
    'room.description':             standardDescriptionIt
};


export const standardTitleEn = 'Scrum Poker Online - Free Tool for Planning Poker';
export const standardDescriptionEn = 'Set up your planning poker session in seconds. Online app to collaborate and estimate in scrum poker.';
export const routesSEO_en = {
    'home.title':                   standardTitleEn + ' - Home',
    'home.description':             standardDescriptionEn,
    'planning-poker.title':         'Planning Poker Online - Free Tool for Planning Poker',
    'planning-poker.description':    'Set up your planning poker session in seconds. Online app to collaborate and estimate in scrum poker.',
    '404.title':                    standardTitleEn + ' - Page Not Found',
    '404.description':              standardDescriptionEn,
    'about.title':                  standardTitleEn + ' - About Us',
    'about.description':            'About us and why we built Scrum Poker Online App -  the tool that takes you just seconds to set-up a planning poker session.',
    'feedback.title':               standardTitleEn + ' - Provide Feedback',
    'feedback.description':         'Provide feedback about Scrum Poker Online App. The tool that takes you just seconds to set-up a planning poker session.',
    'blog.title':                   standardTitleEn + ' - Blog',
    'blog.description':             'Scrum and Planning Blog. The blog for the tool that takes you just seconds to set-up a planning poker session.',
    'blog-contact.title':           standardTitleEn + ' - Contact',
    'blog-contact.description':     'Scrum and Planning Blog. The blog for the tool that takes you just seconds to set-up a planning poker session.',
    'choose-language.title':        standardTitleEn + ' - Choose your language',
    'choose-language.description':  'Scrum Poker Online App is brought to you in many languages. You can choose your language and set-up a planning poker session in seconds.',
    'privacy.title':                standardTitleEn + ' - Privacy Statement',
    'privacy.description':          'Scrum Poker Online App takes your privacy serious. Read all our statement and set-up your planning poker session in seconds.',
    'room.title':                   standardTitleEn ,
    'room.description':             standardDescriptionEn
};

export const standardTitleFr = 'Scrum Poker Online - Outil gratuit pour le planning poker';
export const standardDescriptionFr = 'Organisez votre session de planning poker en quelques secondes. Application en ligne pour collaborer et faire des estimations de scrum poker.';

export const routesSEO_fr  = {
    'home.title': standardTitleFr + ' - Accueil',
    'home.description': standardDescriptionFr,
    'planning-poker.title': 'Planning Poker Online - Outil gratuit pour le poker de plannification ',
    'planning-poker.description': 'Organisez votre session de planning poker en quelques secondes. Application en ligne pour collaborer et faire des estimations de scrum poker.',
    '404.title': standardTitleFr + ' - Page non trouvée',
    '404.description': standardDescriptionFr,
    'about.title': standardTitleFr + ' - À propos de nous',
    'about.description': ' À propos de nous et pourquoi nous avons créé l\'application en ligne Scrum Poker - l\'outil qui vous permet d\'organiser une session de poker en quelques secondes seulement.',
    'feedback.title': standardTitleFr + ' - Fournir un feedback ',
    'feedback.description': 'Donnez votre avis sur l\'application Scrum Poker. L\'outil qui vous permet d\'organiser une session de scrum poker en quelques secondes seulement.',
    'blog.title': standardTitleFr + ' - Blog',
    'blog.description': 'Scrum and Planning Blog. Le blog de l\'outil qui vous permet de mettre en place en quelques secondes une session de planning poker..',
    'blog-contact.title': standardTitleFr + ' - Contactez nous',
    'blog-contact.description': 'Blog de Scrum et Planning Poker. Le blog de l\'application qui vous permet de mettre en place en quelques secondes une session de planning poker..',
    'choose-language.title': standardTitleFr + ' - Choisissez votre langue ',
    'choose-language.description': 'L\'application en ligne Scrum Poker vous est proposée dans de nombreuses langues. Vous pouvez choisir votre langue et organiser une session de scrum poker en quelques secondes.',
    'privacy.title': standardTitleFr + ' - Déclaration de confidentialité',
    'privacy.description': ' L\'application en ligne Scrum Poker prend votre vie privée au sérieux. Lisez notre déclaration et organisez votre session de scrum poker en quelques secondes..',
    'room.title': standardTitleFr ,
    'room.description': standardDescriptionFr


};




export const standardTitleDe = 'Scrum Poker Online - Kostenloses Tool zum Planning Poker';
export const standardDescriptionDe = 'Richten Sie Ihre Planning Poker-Sitzung in Sekundenschnelle ein. Online-App zur Zusammenarbeit und Schätzung beim Scrum-Poker.';
export const routesSEO_de = {
    'home.title': standardTitleDe + ' - Startseite',
    'home.description': standardDescriptionDe,
    'planning-poker.title': ' Planning Poker online - Kostenloses Tool für Planning Poker',
    'planning-poker.description': 'Richten Sie Ihre Planning Poker-Sitzung in Sekundenschnelle ein. Online-App zur Zusammenarbeit und Schätzung beim Scrum-Poker.',
    '404.title': standardTitleDe + '- Seite nicht gefunden',
    '404.description': standardDescriptionDe,
    'about.title': standardTitleDe + '-  Über uns',
    'about.description': 'Über uns und warum wir die Scrum Poker Online App entwickelt haben - das Tool, mit dem Sie in Sekundenschnelle eine Planning Poker-Sitzung einrichten können.',
    'feedback.title': standardTitleDe + ' – Feedback geben',
    'feedback.description': ' Geben Sie Feedback zur Scrum Poker Online App. Das Tool, mit dem Sie in Sekundenschnelle eine Planning-Poker-Sitzung einrichten können.',
    'blog.title': standardTitleDe + ' - Blog',
    'blog.description': 'Scrum und Planing Poker Blog. Der Blog für das Tool, mit dem Sie in Sekundenschnelle eine Planning-Poker-Sitzung einrichten können.',
    'blog-contact.title': standardTitleDe + ' - Kontakt',
    'blog-contact.description': 'Scrum und Planing Poker Blog. Der Blog für das Tool, mit dem Sie in Sekundenschnelle eine Planning-Poker-Sitzung einrichten können.',
    'choose-language.title': standardTitleDe + ' – Sprache wählen ',
    'choose-language.description': 'Scrum Poker Online App wird Ihnen in vielen Sprachen zur Verfügung gestellt. Sie können Ihre Sprache wählen und in Sekundenschnelle eine Planing Poker-Sitzung einrichten.',
    'privacy.title': standardTitleDe + ' - Datenschutzerklärung ',
    'privacy.description': ' Scrum Poker Online App nimmt Ihre Privatsphäre ernst. Lesen Sie alle unsere Erklärungen und richten Sie Ihre Planing Poker-Sitzung in Sekundenschnelle ein.',
    'room.title': standardTitleDe ,
    'room.description': standardDescriptionDe
};

export const standardTitleEs = 'Scrum Poker Online - Herramienta gratuita de planning poker';
export const standardDescriptionEs = ' Organiza tu sesión de planning poker en segundos. Aplicación en línea para colaborar y hacer estimaciones de scrum poker';
export const routesSEO_es = {
    'home.title': standardTitleEs + ' - Página de inicio ',
    'home.description': standardDescriptionEs,
    'planning-poker.title': 'Planning Poker Online - Herramienta gratuita de planning poker ',
    'planning-poker.description': ' Organiza tu sesión de planning poker en segundos. Aplicación en línea para colaborar y hacer estimaciones de scrum poker. ',
    '404.title': standardTitleEs + ' - Página no encontrada',
    '404.description': standardDescriptionEs,
    'about.title': standardTitleEs + ' - Sobre nosotros ',
    'about.description': ' Acerca de nosotros y por qué creamos la aplicación online de Scrum Poker, la herramienta que le permite configurar una sesión de póquer en sólo unos segundos.',
    'feedback.title': standardTitleEs + ' - Dar retroalimentación ',
    'feedback.description': ' Da tu opinión sobre la aplicación de Scrum Poker. La herramienta que le permite organizar una sesión de póquer de scrum en sólo unos segundos.',
    'blog.title': standardTitleEs + ' - Blog',
    'blog.description': 'Blog de Scrum y planificación. El blog de la herramienta que permite establecer una sesión de planning poker en unos pocos segundos.',
    'blog-contact.title': standardTitleEs + ' - Contáctenos ',
    'blog-contact.description': 'Blog de Scrum y planificación. El blog de la herramienta que permite establecer una sesión de planning poker en unos pocos segundos.',
    'choose-language.title': standardTitleEs + ' - Elija su idioma ',
    'choose-language.description': ' La aplicación en línea de Scrum Poker está disponible en muchos idiomas. Puedes elegir tu idioma y configurar una sesión de Scrum Poker en segundos.',
    'privacy.title': standardTitleEs + ' - Declaración de privacidad',
    'privacy.description': ' La aplicación en línea de Scrum Poker se toma su privacidad muy en serio. Lee nuestra declaración y prepara tu sesión de Scrum Poker en segundos.',
    'room.title': standardTitleEs ,
    'room.description': standardDescriptionEs
};


export const standardTitlePt = 'Scrum Poker Online - Free Tool for Planning Poker';
export const standardDescriptionPt = 'Set up your planning poker session in seconds. Online app to collaborate and estimate in scrum poker.';
export const routesSEO_pt = {
    'home.title':                   standardTitleEn + ' - Home',
    'home.description':             standardDescriptionEn,
    'planning-poker.title':         'Planning Poker Online - Free Tool for Planning Poker',
    'planning-poker.description':    'Set up your planning poker session in seconds. Online app to collaborate and estimate in scrum poker.',
    '404.title':                    standardTitleEn + ' - Page Not Found',
    '404.description':              standardDescriptionEn,
    'about.title':                  standardTitleEn + ' - About Us',
    'about.description':            'About us and why we built Scrum Poker Online App -  the tool that takes you just seconds to set-up a planning poker session.',
    'feedback.title':               standardTitleEn + ' - Provide Feedback',
    'feedback.description':         'Provide feedback about Scrum Poker Online App. The tool that takes you just seconds to set-up a planning poker session.',
    'blog.title':                   standardTitleEn + ' - Blog',
    'blog.description':             'Scrum and Planning Blog. The blog for the tool that takes you just seconds to set-up a planning poker session.',
    'blog-contact.title':           standardTitleEn + ' - Contact',
    'blog-contact.description':     'Scrum and Planning Blog. The blog for the tool that takes you just seconds to set-up a planning poker session.',
    'choose-language.title':        standardTitleEn + ' - Choose your language',
    'choose-language.description':  'Scrum Poker Online App is brought to you in many languages. You can choose your language and set-up a planning poker session in seconds.',
    'privacy.title':                standardTitleEn + ' - Privacy Statement',
    'privacy.description':          'Scrum Poker Online App takes your privacy serious. Read all our statement and set-up your planning poker session in seconds.',
    'room.title':                   standardTitleEn ,
    'room.description':             standardDescriptionEn
};
