import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { RoomIdService } from '../poker-room/room-id.service';
import { routesSEO_de, routesSEO_fr, routesSEO_en, standardDescriptionEn, standardTitleEn, routesSEO_it, routesSEO_pt, routesSEO_es } from '../routes';
import { LOCALE_ID, Inject } from '@angular/core';
import { NGXLogger as LoggerService } from "ngx-logger";
import { DOCUMENT } from "@angular/common";
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';


const languageCodes = ["en", "de", "it", "fr", "es", "pt"]


@Injectable()
export class SEOService {
    routesSEO = null;
    private renderer: Renderer2;
    private linkElements = [];

    constructor(
        private title: Title,
        private meta: Meta,
        private roomIdService: RoomIdService,
        @Inject(LOCALE_ID) public locale: string,
        private logger: LoggerService,
        @Inject(DOCUMENT) private document: any,
        rendererFactory: RendererFactory2,// we cannot inject the renderer directly in a service
        private router: Router,


    ) {
        this.initRouteSEO();
        this.renderer = rendererFactory.createRenderer(null, null);

    }
    private initRouteSEO() {
        if (this.getLocal() == 'de') {
            this.routesSEO = routesSEO_de;
        } else if (this.getLocal() == 'fr') {
            this.routesSEO = routesSEO_fr;
        } else if (this.getLocal() == 'it') {
            this.routesSEO = routesSEO_it;
        } else if (this.getLocal() == 'pt') {
            this.routesSEO = routesSEO_pt;
        } else if (this.getLocal() == 'es') {
            this.routesSEO = routesSEO_es;
        } else {
            this.routesSEO = routesSEO_en;
        }
    }
    private getLocal() {
        return this.locale.substring(0, 2);
    }
    public handleRouteChange(id: string) {
        this.updateTitle(id);
        this.updateDescription(id);
        this.updateAlternateHrefLang(id)
    }

    private updateAlternateHrefLang(id: string) {
        this.logger.log('updateAlternateHrefLang', id);
        this.removePreviousLinkElements();
        const refElement = this.document.getElementById("alternate-hreflang-placeholder")
        const alternates = this.getAlternateHrefLangs(id);
        alternates.forEach(alternate => {
            this.logger.log('updateAlternateHrefLang alternate', alternate);
            const linkElement = this.renderer.createElement('link');
            this.renderer.setAttribute(linkElement, 'rel', 'alternate');
            this.renderer.setAttribute(linkElement, 'hreflang', alternate.langCode);
            this.renderer.setAttribute(linkElement, 'href', this.getUrlHost(alternate.langCode, alternate.url));
            if (refElement) {
                this.renderer.insertBefore(this.document.head, linkElement, refElement)
            } else {
                this.renderer.appendChild(this.document.head, linkElement);
            }
            this.linkElements.push(linkElement)
        })
    }

    public getAlternateHrefLangs(pathId: string): { langCode: string, url: string }[] {
        let returnArray: { langCode: string, url: string }[] = []
        const url = this.router.url.replace("/", "")
        languageCodes.forEach(languageCode => {
            returnArray.push({ langCode: languageCode, url: url });
        })
        return returnArray
    }


    private getUrlHost(langCode: string, url: string): string {
        return environment.host_url + '/' + langCode + "/" + url;
    }
    private removePreviousLinkElements() {
        this.linkElements.forEach(linkElement => {
            this.renderer.removeChild(this.document.head, linkElement)
        })
    }
    private updateTitle(id: string) {
        let title = this.routesSEO[id + '.title'];
        if (title == null || title == '') {
            title = standardTitleEn;
        }
        if (this.roomIdService.getCurrentRoomID() != '') {
            title = title + ' - Room: ' + this.roomIdService.getCurrentRoomID();
        }
        this.title.setTitle(title);
    }
    private updateDescription(id: string) {
        let description = this.routesSEO[id + '.description'];
        if (description == null || description == '') {
            description = standardDescriptionEn;
        }
        this.meta.updateTag({ name: 'description', content: description });
    }


}
