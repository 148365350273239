<h1>
    Admin Page
</h1>
<div>
    &nbsp;
    <span>
        <button mat-stroked-button color="warn" (click)="deleteOldRooms()">
            Delete Old Rooms
        </button>
    </span>
    <span>
        <button mat-stroked-button color="warn" (click)="deleteOldUserDocs()">
            Delete Old User Docs
        </button>
    </span>
    <span>

    </span>
</div>
<mat-card>
    <h2>addProductForUserManually</h2>
    <div>
        <mat-label>Security Key</mat-label>
        <mat-form-field appearance="fill" class="whoo-inline-form-field" subscriptSizing="dynamic">
            <input matInput [(ngModel)]="secretKey" autocomplete="on" id="secretKey" name="secretKey">
        </mat-form-field>
    </div>
    <div>
        <mat-label>Product Key</mat-label>
        <mat-form-field appearance="fill" class="whoo-inline-form-field" subscriptSizing="dynamic" autocomplete="on"
            id="productKey" name="productKey">
            <input matInput [(ngModel)]="productKey">
        </mat-form-field>
    </div>
    <div>
        <button mat-stroked-button color="warn" (click)="addProductForUserManually()">
            add product for user manually
        </button>
    </div>

</mat-card>


<br>
<div class="spo-border">
    <ng-adsense [display]="'inline-block'" [width]="728" [height]="90" [adSlot]="6733965246"></ng-adsense>
</div>