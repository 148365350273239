import { Injectable } from '@angular/core';
import { IEstimate } from './esimtate.model';
import { ActivatedRoute } from '@angular/router';
import { NGXLogger as LoggerService } from "ngx-logger";
import { LOCALE_ID, Inject } from '@angular/core';
import { environment } from '../../environments/environment';
import { UserService } from '../user/user.service';

@Injectable()
export class RoomIdService {
    currentEstimate: IEstimate;
    roomID: string;
    constructor(
        @Inject(LOCALE_ID) public locale: string,
        private logger: LoggerService,
        private userService: UserService
    ) {
        this.currentEstimate = {
            storyPoints: '0',
            displayName: '',
        };
        this.roomID = '';
    }

    public setRoomId(routeParam: ActivatedRoute) {
        const routeID = routeParam.snapshot.params['id'];
        if (routeID == null) {
            this.logger.log('routeId == null');
            this.roomID = '';
        } else {
            this.logger.log('routeId: ' + routeID);
            this.roomID = routeID;
        }
    }

    public setRoomIdStr(roomID: string) {
        this.roomID = roomID;
    }

    public getCurrentRoomID() {
        if (this.roomID != '') {
            return this.roomID;
        }
        if (this.userService.isLoggedIn()) {
            return this.userService.getRoomId();
        }
        return '';
    }

    public isRoomIdEmpty() {
        if (this.getCurrentRoomID() == '') {
            return true;
        } else { return false; }

    }

    private getHost() {
        return environment.host;
    }
    private getUrlHost() {
        if (this.locale && this.locale != '' && this.locale != 'en-US') {
            return environment.host_url + '/' + this.locale;
        } else {
            return environment.host_url;
        }
    }

    public getUrlHome() {
        if (this.getCurrentRoomID() != '') {
            return ('/room/' + this.getCurrentRoomID());
        } else {
            return ('');
        }
    }
    private getUrlFullHome() {
        return this.getUrlHost() + this.getUrlHome();
    }

    public getUrlPokerRoom() {
        if (this.getCurrentRoomID() != '') {
            return ('/room/' + this.getCurrentRoomID() + '/scrum-poker');
        } else {
            return this.getUrlHome();
        }
    }

    public getUrlFullPokerRoom() {
        return this.getUrlHost() + this.getUrlPokerRoom();
    }

}
