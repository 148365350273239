import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UIMesageService } from '../common/ui-message.service';
import { NGXLogger as LoggerService } from "ngx-logger";
import { ResponsiveService } from '../common/responsive.service';

import { LOCALE_ID, Inject } from '@angular/core';
import { RoomIdService } from '../poker-room/room-id.service';



@Component({
    selector: 'language',
    templateUrl: './language.component.html',
    styleUrls: ['./language.component.scss']


})
export class LanguageComponent implements OnInit {
    userLang: String;
    constructor(
        @Inject(LOCALE_ID) public locale: string,
        private router: Router,
        private uiMessage: UIMesageService,
        private logger: LoggerService,
        private route: ActivatedRoute,
        public responsiveService: ResponsiveService,
        public roomIdService: RoomIdService,
    ) {

    }

    ngOnInit() {
        this.userLang = this.getUsersLocale('def');


    }

    getUsersLocale(defaultValue: string): string {
        if (typeof window === 'undefined' || typeof window.navigator === 'undefined') {
            return defaultValue;
        }
        const wn = window.navigator as any;
        let lang = wn.languages ? wn.languages[0] : defaultValue;
        lang = lang || wn.language || wn.browserLanguage || wn.userLanguage;
        return lang;
    }

}
