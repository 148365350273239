<div [ngClass]="responsiveService.getBigSmall()">
  <h1 i18n="Not Logged in; First Header for Small Screen" *ngIf="responsiveService.isScreenSmall() else bigScreen" i18n>
    Planning poker in seconds
  </h1>
  <ng-template #bigScreen>
    <h1 i18n>
      Set up your planning poker in seconds, start estimating story points in scrum poker now
    </h1>
    <h2 i18n>
      Create your planning room and invite others with a single click
    </h2>
  </ng-template>
  <create-room-button></create-room-button>
</div>