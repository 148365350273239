import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import firebase from 'firebase/compat/app';
import { Observable, firstValueFrom, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { NGXLogger as LoggerService } from "ngx-logger";
import { AuthProvider, IdCredentials } from './auth.interfaces';
import { PresenceService } from './online-presence.service';
import { User } from './user.model';
import { UserService } from './user.service';
import UserCredential = firebase.auth.UserCredential;

@Injectable()
export class AuthService {
    public isLoading: boolean; // is used for the progress bar in app.component
    private afAuthUser: firebase.User = null;
    private token: firebase.auth.IdTokenResult = null;

    constructor(
        private afAuth: AngularFireAuth,
        private userService: UserService,
        private logger: LoggerService,
        private presenceService: PresenceService,

    ) {
        this.logger.log('AuthService:constructor');
        this.syncUser();
    }

    public async syncUser() {
        try {
            this.logger.log('AuthService:syncUser');
            this.isLoading = true;
            var userObservable: Observable<User | firebase.User>;
            userObservable = this.afAuth.authState.pipe(
                switchMap(user => {
                    if (user) {
                        this.logger.log('afAuth.authState.pipe: User');
                        this.afAuthUser = user;
                        this.loadIdTokenResult();
                        return this.userService.getUserDocObservable(user.uid);
                    } else {
                        this.logger.log('afAuth.authState.pipe: NO User');
                        return of(null);
                    }
                })
            );
        } catch (error) {
            const message = 'Failed syncUser. ' + error;
            this.logger.error(message);
            throw (error);
        } finally {
            this.isLoading = false;
        }
        this.userService.setUserObservable(userObservable);
    }
    private async loadUser(uid: string) {
        this.afAuthUser = await firstValueFrom(this.afAuthUser$);
        this.userService.subscribeToUserDocument();
        this.refreshIdTokenResult()
    }

    // load is called with APP_INITIALIZER in app.modules.ts  to that the app starts only once the load of the uses is done
    loadOnAppInit() {
        this.logger.log('AuthService:loadOnAppInit');
        // subscribe to the user document and set this.currentUser
        this.refreshIdTokenResult()
        return this.userService.subscribeToUserDocument();
    }
    public async loadIdTokenResult() {
        this.token = await this.afAuthUser?.getIdTokenResult(true)
        this.logger.log('loadIdTokenResult', this.token);
    }
    /**
      * WE don't know how long the  firebase function to update the custom claims takes
      * So we update the token in several intervalls
      * Better solution might be using an observable as described here:
      * https://firebase.google.com/docs/auth/admin/custom-claims?hl=en#defining_roles_via_firebase_functions_on_user_creation
      */
    private refreshIdTokenResult() {
        setTimeout(() => {
            this.logger.log("getIdTokenResult 1000");
            this.loadIdTokenResult();
        }, 1000);
        setTimeout(() => {
            this.logger.log("getIdTokenResult 3000");
            this.loadIdTokenResult();
        }, 3000);
        setTimeout(() => {
            this.logger.log("getIdTokenResult 10000");
            this.loadIdTokenResult();
        }, 10000);
        setTimeout(() => {
            this.logger.log("getIdTokenResult 30000");
            this.loadIdTokenResult();
        }, 300000);
    }

    public hasPremium(): boolean {
        return this.hasDepricatedPremium() || this.hasStripePremium()
    }
    public hasDepricatedPremium(): boolean {
        if (!this.token?.claims?.products || this.token?.claims?.products.length === 0) {
            return false;
        }
        return true
    }

    public hasStripePremium(): boolean {
        return this.token?.claims?.stripeRole == "premium";
    }

    async logOut(): Promise<void> {
        try {
            this.isLoading = true;
            this.userService.logout();
            this.token = null;
            await this.presenceService.signOut();
            await this.afAuth.signOut();
        } catch (error) {
            const message = 'Logout failed. ' + error;
            this.logger.error(message);
            throw (error);
        } finally {
            this.isLoading = false;
        }
    }

    public async signInWith(provider: AuthProvider, credentials?: IdCredentials): Promise<UserCredential> {
        this.isLoading = true;
        let signInResult: UserCredential;
        try {
            switch (provider) {
                case AuthProvider.EmailAndPassword:
                    signInResult = await this.afAuth.signInWithEmailAndPassword(credentials.email, credentials.password) as UserCredential;
                    break;
                case AuthProvider.ANONYMOUS:
                    signInResult = await this.afAuth.signInAnonymously() as UserCredential;
                    break;
                case AuthProvider.Google:
                    signInResult = await this.signIn(new firebase.auth.GoogleAuthProvider()) as UserCredential;
                    break;
                case AuthProvider.Facebook:
                    signInResult = await this.signIn(new firebase.auth.FacebookAuthProvider) as UserCredential;
                    break;
                case AuthProvider.GitHub:
                    signInResult = await this.signIn(new firebase.auth.GithubAuthProvider) as UserCredential;
                    break;
                case AuthProvider.Twitter:
                    signInResult = await this.signIn(new firebase.auth.TwitterAuthProvider) as UserCredential;
                    break;
                default:
                    throw new Error(provider + ' is not avaible as authentication provider'); // to do translate
            }
            await this.userService.logout();
            await this.userService.updateUserDoc(signInResult.user, true, null, true);
            await this.loadUser(signInResult.user.uid);
            return signInResult;
        } catch (error) {
            const message = 'Sign in failed (' + provider + '). ' + error;
            this.logger.warn(message);
            throw (error);
        } finally {
            this.isLoading = false;
        }
    }

    private async signIn(provider: firebase.auth.AuthProvider): Promise<UserCredential> {
        let result;
        try {
            const windowWidth = window.innerWidth;
            if (windowWidth > 1025) {
                result = await this.afAuth.signInWithPopup(provider);
            } else {
                await this.afAuth.signInWithRedirect(provider);
                result = await this.afAuth.getRedirectResult() as UserCredential;
            }
            return result;
        } catch (error) {
            const message = 'Sign in failed. ' + error;
            this.logger.warn(message);
            throw (error);
        } finally {
            this.isLoading = false;
        }
    }


    public get isAnonymous(): boolean {
        if (this.afAuthUser?.isAnonymous) {
            if (this.email == null || this.email === '') { // if an email upgrades the anonymous account but did no set the password the currentUser.isAnonymous remains true but we want to treat him already like a proper user
                return true;
            }
        }
        return false;
    };

    public get email(): string {
        return this.afAuthUser?.email;
    }

    public get uid(): string {
        return this.afAuthUser?.uid;
    }
    public get displayName(): string {
        return this.afAuthUser?.displayName;
        // return this.afAuth.auth.currentUser?.displayName;
    }

    /**
     * get driecty the observable to the auth user
     * this is need for reactive forms to updated values after ngInit
     */
    public get afAuthUser$(): Observable<firebase.User | null> {
        // return this.afAuth.user;
        return this.afAuth.authState;
    }


    public async signUpAnonymous(displayName: string, generateRoom: boolean) {
        try {
            this.logger.log('signUpAnonymous, displaynname: ' + displayName);
            //      const signInResult = await this.afAuth.auth.signInAnonymously() as UserCredential
            const signInResult = await this.afAuth.signInAnonymously().catch(error => {
                const message = 'Catch: Connection to the server failed during anonymous log-in. Please check your internet connection. (!signInResult)' + error;
                this.logger.error(message);
                throw (error);
            });
            if (!signInResult) {
                const message = 'Connection to the server failed during anonymous log-in. Please check your internet connection. (!signInResult)';
                this.logger.error(message);
                throw (message);
            }
            if (!signInResult.user) {
                const message = 'Connection to the server failed during anonymous log-in. Please check your internet connection. (!signInResult.user))';
                this.logger.error(message);
                throw (message);
            }
            this.logger.log('signUpAnonymous: signInResult: ');
            this.logger.warn(signInResult);
            await this.userService.logout();
            await this.userService.updateUserDoc(signInResult.user, false, displayName, generateRoom);
            await this.loadUser(signInResult.user.uid);
            this.logger.log('signUpAnonymous: updateUserDoc done ');
        } catch (error) {
            const message = 'Failed to store profile. Your internet connection might not work. ' + error;
            this.logger.error(message);
            throw (error);
        } finally {
            this.isLoading = false;
        }
    }

    public async signUpEmail(cridentials: IdCredentials, displayName: string) {
        try {
            this.isLoading = true;
            const createResult = await this.afAuth.createUserWithEmailAndPassword(cridentials.email, cridentials.password) as UserCredential;
            await this.userService.logout();
            await this.userService.updateUserDoc(createResult.user, false, displayName, true);
            await this.loadUser(createResult.user.uid);
            // await this.syncUser(displayName)
        } catch (error) {
            const message = 'Email sign-up failed. ' + error;
            this.logger.error(message);
            throw (error);
        } finally {
            this.isLoading = false;
        }
    }

    public async sendEmailVerification(langCode?: string) {
        try {
            this.isLoading = true;
            //   this.afAuth.languageCode = langCode ? langCode : 'en';
            await this.afAuthUser.sendEmailVerification();
        } catch (error) {
            const message = 'Sending email verification failed. ' + error;
            this.logger.error(message);
            throw (error);
        } finally {
            this.isLoading = false;
        }
    }

    public async resetPassword(emailParam?: string) {
        let email: string;
        try {
            if (emailParam) {
                email = emailParam;
            } else {
                email = this.afAuthUser?.email;
            }
            this.isLoading = true;
            await this.afAuth.sendPasswordResetEmail(email);
        } catch (error) {
            const message = 'Sending email verification failed. ' + error;
            this.logger.error(message);
            throw (error);
        } finally {
            this.isLoading = false;
        }
    }
}
