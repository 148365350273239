import { Component, OnInit, Input } from '@angular/core';
import { RoomIdService } from './room-id.service';
import { FormGroup } from '@angular/forms';
import { EstimateService } from './estimate.service';
import { UserService } from '../user/user.service';
import { AuthService } from '../user/auth.service';
import { UIMesageService } from '../common/ui-message.service';
import { NGXLogger as LoggerService } from "ngx-logger";
import { ResponsiveService } from '../common/responsive.service';
import { MatDialog } from '@angular/material/dialog';
import { LoginGateDialogService } from './login-gate-dialog.service';


@Component({
    selector: 'provide-estimate',
    templateUrl: './provide-estimate.component.html',
    styleUrls: ['./provide-estimate.component.scss']
})
export class ProvideEstimateComponent implements OnInit {
    @Input() isOwner: boolean = false;
    @Input() ownerHasPremium: boolean = false;
    provideEstimateForm: FormGroup;
    smallScreen: boolean;
    constructor(
        private roomIdService: RoomIdService,
        public estimateService: EstimateService,
        public userService: UserService,
        public authService: AuthService,
        private uiMessage: UIMesageService,
        private logger: LoggerService,
        public responsiveService: ResponsiveService,
        public dialog: MatDialog,
        private loginGateService: LoginGateDialogService
    ) { }

    ngOnInit() {
        this.provideEstimateForm = new FormGroup({
        });
    }

    getSelectedClass(storyPoints: string): string {
        //     this.logger.error('getSElectedClass', storyPoints, this.estimateService.userStoryPoints);
        if (storyPoints === this.estimateService.userStoryPoints) {
            return 'selected';
        }
        return '';
    }

    async submitEstimate(storyPoints: string) {
        this.logger.log('submitEstimate: ', storyPoints);
        try {
            if (!this.userService.isLoggedIn()) {
                await this.loginGateService.openDialog()
                if (!this.userService.isLoggedIn()) throw new Error('Login canceled')
            }

            await this.estimateService.createEstimate(this.roomIdService.getCurrentRoomID(), this.userService.getDisplayName(), this.userService.getUid(), storyPoints);
            this.uiMessage.success('Estimation has been submitted.');
        } catch (error) {
            this.uiMessage.info('Submitting the estimate failed. ' + error);
        }
    }

}
