<div [ngClass]="responsiveService.getBigSmall()">
  <h1 i18n>
    "One thousands brains are better than two”
  </h1>
  <h2 i18n>
    Some famous person (probably)
  </h2>
  <p i18n>
    The two of us have built the tool that we wanted to use and have tried to concentrate on the aspects that seemed the
    most important for us. But you probably have your own ideas, suggestions, needs or issues - so let us hear them by
    dropping us an email and we’ll try to include them in a further iteration.
  </p>

  <form [formGroup]="feedbackForm" (ngSubmit)="submitForm()" autocomplete="off" novalidate>

    <mat-card class="card">




      <mat-form-field class="message-field" appearance="fill">
        <mat-label>Your message</mat-label>
        <textarea matInput input="text" i18n-placeholder placeholder="For example:  This is a great tool but ..."
          rows="5" formControlName="message"></textarea>
        <mat-error *ngIf="feedbackForm.controls.message.hasError('required')">
          Please enter your message
        </mat-error>
      </mat-form-field>
      <div class='email-and-submit'>
        <mat-form-field appearance="fill" class="email-field">
          <mat-label i18n>Email (if you expect an answer)</mat-label>
          <input matInput input="email" i18n-placeholder placeholder="yourname@email.com" maxlength="50"
            formControlName="email">
          <mat-error *ngIf="feedbackForm.controls.email.hasError('email')">
            Please enter a valid email address
          </mat-error>
        </mat-form-field>
        <span class=" submit">
          <button i18n mat-flat-button color="warn" type="submit" class="submit"
            [disabled]="feedbackForm.controls.email.invalid || feedbackForm.controls.message.invalid || formHasBeenSubimtted">Submit</button>
        </span>
      </div>
      <br>
      <div *ngIf="formHasBeenSubimtted" i18n class="suscess-message">Your feedback as been submitted successfully. Thank
        you so much.</div>
      <br>

    </mat-card>
  </form>
  <br><br>
  <p i18n>
    Hope to see you around soon again,
  </p>
  <p i18n>
    Kai and Paul
  </p>
</div>