import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { RoomIdService } from './room-id.service';
import { UserService } from '../user/user.service';
import { AuthService } from '../user/auth.service';
import { UIMesageService } from '../common/ui-message.service';
import { NGXLogger as LoggerService } from "ngx-logger";
import { ResponsiveService } from '../common/responsive.service';
import { EstimateService } from './estimate.service';
import { MatDialog } from '@angular/material/dialog';
import { environment } from '../../environments/environment';
import { AdvertisingService } from '../common/advertising.service';
import { LoginGateDialogService } from './login-gate-dialog.service';
import { filter, Observable, Subscription } from 'rxjs';
//declare const window: any;
declare global {
    interface Window {
        fusetag: any;
    }
}

@Component({
    templateUrl: './poker-room.page.html',
    styleUrls: ['./poker-room.page.scss'],
})

export class PokerRoomPage implements OnInit, OnDestroy {
    public delayAdsense = false;
    public isLoading = true;
    isExistentRoom = true;
    public environment = environment;
    navEnd: Observable<NavigationEnd>;
    navSub: Subscription;
    constructor(
        private route: ActivatedRoute,
        public roomIdService: RoomIdService,
        public userService: UserService,
        public authService: AuthService,
        private uiMessage: UIMesageService,
        private logger: LoggerService,
        public responsiveService: ResponsiveService,
        public estimateService: EstimateService,
        public dialog: MatDialog,
        public advertisingService: AdvertisingService,
        private loginGateService: LoginGateDialogService,
        private router: Router,
    ) {
        this.navEnd = this.router.events.pipe(
            filter(evt => evt instanceof NavigationEnd)
        ) as Observable<NavigationEnd>;
    }

    async ngOnInit() {
        try {
            this.roomIdService.setRoomId(this.route);
            if (await this.estimateService.isExistentRoom(this.roomIdService.getCurrentRoomID())) {
                this.isExistentRoom = true
                if (!this.userService.isLoggedIn()) this.loginGateService.openDialog()
                this.estimateService.enterRoom(this.roomIdService.getCurrentRoomID());
                this.setupNavigationSubscription();
                this.initAds();
            } else {
                this.isExistentRoom = false
                this.uiMessage.info('Rooom does not exist: ', this.roomIdService.getCurrentRoomID());
            }
        } catch (error) {
            this.uiMessage.error(error, 'Initializing the room. Please check your internet connection.');
        } finally {
            this.isLoading = false;
        }
    }

    ngOnDestroy() {
        this.estimateService.unsubscribe();
        this.navSub.unsubscribe();
    }

    private initAds() {
        this.route.params.subscribe(params => {
            this.delayAdsense = true;
            setTimeout(() => (
                this.delayAdsense = false
            ), 200);
        });
        this.initFuse()
        this.initPrimis()
    }
    /**
    * observe the navigation to react on back and forward button and set the current room id accordingly
    */
    private setupNavigationSubscription() {
        this.navSub = this.navEnd.subscribe(evt => {
            this.logger.log('Navigation Ended!');
            this.roomIdService.setRoomId(this.route);
            this.estimateService.enterRoom(this.roomIdService.getCurrentRoomID());
            this.advertisingService.registerFuseTag();
        });
    }

    private initFuse() {
        setTimeout(() => {
            this.logger.log("initFuse 1000");
            this.advertisingService.registerFuseTag()
        }, 1000);
    }

    private initPrimis() {
        setTimeout(() => {
            this.logger.log("initPrimis 1000");
            if (this.advertisingService.primisEnabled) {
                this.advertisingService.loadPrimisScriptToDomElement('primis-header-mobile')
                this.advertisingService.loadPrimisScriptToDomElement('primis-footer-mobile')
                this.advertisingService.loadPrimisScriptToDomElement('primis-middle-1')
                this.advertisingService.loadPrimisScriptToDomElement('primis-middle-2')
                this.advertisingService.loadPrimisScriptToDomElement('primis-right-1')
                this.advertisingService.loadPrimisScriptToDomElement('primis-right-2')
            }
        }, 1000);
    }

    public get isOwner(): boolean {
        if (this.roomIdService.getCurrentRoomID() === this.userService.getRoomId()) {
            return true;
        } else {
            return false;
        }
    }

    public isProduction() {
        return environment.production;

    }
}
