<a *ngIf="!isVisible" (click)="toggleIsVisible()">
    {{moreLabel}}
</a>

<fade-in-and-out [isVisible]="isVisible" [useNgIf]="false" display="block" maxHeight="48px"
    [hideTransitionDuration]="150" [showTransitionDuration]="250" transform="translateY(-24px)">
    <ng-content></ng-content>
    <a *ngIf="isVisible" (click)="toggleIsVisible()">
        {{lessLabel}}
    </a>
</fade-in-and-out>
