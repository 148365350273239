import { Injectable } from '@angular/core';
import { LibraryConfigurationService } from './library-configuration.service';

@Injectable({
    providedIn: 'root',
})
export class LoggerService {
    private logPrefix = '';
    constructor(public config: LibraryConfigurationService) {
        if (this.config.loggerPrefix) {
            this.logPrefix = this.config.loggerPrefix;
        } else {
            this.logPrefix = 'ESS';
        }
    }
    get log(): Function {
        if (!this.config.isDebug) {
            return () => {};
        }
        const logPrefix = this.logPrefix;
        if (logPrefix.length) {
            return console.log.bind(window.console, logPrefix);
        } else {
            return console.log.bind(window.console);
        }
    }

    get warn(): Function {
        const logPrefix = this.logPrefix;
        if (logPrefix.length) {
            return console.warn.bind(window.console, logPrefix);
        } else {
            return console.warn.bind(window.console);
        }
    }

    get dir(): Function {
        if (!this.config.isDebug) {
            return () => {};
        }
        const logPrefix = this.logPrefix;
        if (logPrefix.length) {
            return console.dir.bind(window.console, logPrefix);
        } else {
            return console.dir.bind(window.console);
        }
    }

    get error(): Function {
        const logPrefix = this.logPrefix;
        if (logPrefix.length) {
            return console.error.bind(window.console, logPrefix);
        } else {
            return console.error.bind(window.console);
        }
    }
}
