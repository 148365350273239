import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { RoomIdService } from './room-id.service';
import { ResponsiveService } from '../common/responsive.service';
import { NGXLogger as LoggerService } from "ngx-logger";
import { environment } from '../../environments/environment';

export interface DialogData {

    title: string;
}

@Component({
    selector: 'results-dialog',
    templateUrl: 'results.dialog.html',
    styleUrls: ['results.dialog.scss']
})

export class ResultsDialog {
    constructor(
        public logger: LoggerService,
        public roomIdService: RoomIdService,
        public responsiveService: ResponsiveService,
        public dialogRef: MatDialogRef<ResultsDialog>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
        public dialog: MatDialog
    ) {
    }


    public getHeaderText() {
        return environment.home_button_label;
    }
    onNoClick(): void {
        this.dialogRef.close();
    }
}
