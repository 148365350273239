import { Injectable } from '@angular/core';
import { AngularFireDatabase, AngularFireObject } from '@angular/fire/compat/database';
import { Subscription } from 'rxjs';
import { NGXLogger as LoggerService } from "ngx-logger";
import firebase from 'firebase/compat/app';


const timerPath = "timer";
const currentTimePath = timerPath + "/currentTime";
export interface ITimer {
    initiatedAt?: number;
    targetEndAt?: number;
    canceledAt?: number;
    pausedAt?: number;
    startSeconds?: number;
    startMinutes?: number;
}

@Injectable()
export class TimerService {
    public isRunning: boolean = false;
    private localTargetEnd: number;
    private timeDifference: number;
    private intervalId: ReturnType<typeof setInterval>;
    private roomId: string;
    private timerSubscription: Subscription;
    private tFireObject: ITimer;
    private loadedAtLeastOnce = false;
    private _startSeconds = 30;
    private _startMinutes = 0;
    constructor(
        private logger: LoggerService,
        private afd: AngularFireDatabase
    ) {
    }
    private initTimeDifference() {
        this.timeDifference = this.defaultTimeDifference;
        this.logger.log(this.timeDifference)
    }
    get seconds(): number {
        if (this.timeDifference < 0) return 0;
        const totalSeconds = Math.floor(this.timeDifference / 1000)
        return totalSeconds % 60;
    }
    get minutes(): number {
        if (this.timeDifference < 0) return 0;
        const totalMinutes = Math.floor(this.timeDifference / 1000 / 60)
        return totalMinutes % 60;
    }
    get isLastSeconds() {
        return this.timeDifference < 10000
    }
    get isPaused() {
        return this.timeDifference !== this.defaultTimeDifference;
    }
    get startMinutes() {
        return this._startMinutes
    }
    get startSeconds() {
        return this._startSeconds
    }
    get defaultTimeDifference() {
        return 1000 * 60 * this.startMinutes + 1000 * this.startSeconds
    }

    async setStartMinutesAndSeconds(startMinutes: number, startSeconds: number) {
        this.logger.log('setStartMinutesAndSeconds', startMinutes)
        this.setStartMinutesAndSecondsExecute(startMinutes, startSeconds)
        await this.firebaseRef.update(
            {
                startMinutes: startMinutes,
                startSeconds: startSeconds
            })
    }

    private setStartMinutesAndSecondsExecute(startMinutes: number, startSeconds: number) {
        this.logger.log('setStartMinutesAndSecondsExecute', startMinutes)
        this._startMinutes = startMinutes;
        this._startSeconds = startSeconds;
        this.initTimeDifference()
    }

    public async start() {
        const now = await this.getServerTime()
        const targetEndAt = now + this.timeDifference
        this.isRunning = true;
        await this.firebaseRef.update(
            {
                initiatedAt: now,
                targetEndAt: targetEndAt
            })
    }

    private async startExecute(targetEndAt: number) {
        const serverNow = await this.getServerTime()
        const localNow = new Date().getTime()
        this.timeDifference = targetEndAt - serverNow;
        this.localTargetEnd = localNow + this.timeDifference;
        this.logger.warn("startExecute", serverNow, localNow, this.timeDifference, this.localTargetEnd)
        if (this.timeDifference > 0) {
            this.initiateTimer();
        } else {
            this.initTimeDifference()
        }

    }

    private initiateTimer() {
        this.logger.log('initiateTimer', this.loadedAtLeastOnce)
        this.intervalId = setInterval(() => {
            this.refreshTimeDifferenceFromNow()
            if (this.timeDifference < 1000) {
                this.pauseExecute()
            } else {
                this.isRunning = true;
            }
        }, 1000);
    }
    private async refreshTimeDifferenceFromNow() {
        const localNow = new Date().getTime()
        this.timeDifference = this.localTargetEnd - localNow
    }



    public async cancel() {
        this.firebaseRef.update({
            canceledAt: this.timeStampLocal,
            targetEndAt: 0
        })

    }
    private cancelExecute() {
        this.logger.log("cancelExecute")
        this.pause()
        this.timeDifference = this.defaultTimeDifference
    }

    public async pause() {
        this.firebaseRef.update({
            pausedAt: this.timeStampLocal,
            targetEndAt: 0
        })
    }
    private pauseExecute() {
        this.logger.log("pauseExecute", this.intervalId)
        clearInterval(this.intervalId);
        this.isRunning = false;
    }
    private get firebaseRef(): AngularFireObject<ITimer> {
        return this.afd.object(`${timerPath}/${this.roomId}`);
    }

    public connectRoom(roomId: string) {
        if (roomId === this.roomId) return;
        this.logger.log('connectRoom', roomId)
        this.initTimeDifference()
        this.roomId = roomId;
        this.timerSubscription?.unsubscribe()
        this.timerSubscription = this.firebaseRef.valueChanges().subscribe((tFireObject) => {
            if (tFireObject) {
                this.logger.log('timer change', tFireObject)
                if (tFireObject.targetEndAt) this.startExecute(tFireObject.targetEndAt)
                if ((tFireObject.startMinutes && tFireObject.startSeconds)
                    && (tFireObject.startMinutes !== this.startMinutes || tFireObject.startSeconds !== this.startSeconds)) {
                    this.setStartMinutesAndSecondsExecute(tFireObject.startMinutes, tFireObject.startSeconds)
                }
                if (this.loadedAtLeastOnce) {
                    if (this.tFireObject?.canceledAt !== tFireObject?.canceledAt)
                        this.cancelExecute()
                    if (this.tFireObject?.pausedAt !== tFireObject?.pausedAt)
                        this.pauseExecute()

                }

            }
            this.tFireObject = tFireObject;
            this.loadedAtLeastOnce = true;
        })
    }
    public disconnnectRoom() {
        this.logger.log('disconnnectRoom')
        this.pauseExecute()
        this.timerSubscription?.unsubscribe()
        this.tFireObject = null;
        this.loadedAtLeastOnce = false;
        this.isRunning = false;
        this.intervalId = null;
        this.timeDifference = 0;
        this.localTargetEnd = 0;
        this.roomId = null;
    }
    /**
     * We use this  in order to save some traffic where the server time is not required
     */
    private get timeStampLocal() {
        return new Date().getTime();
    }
    // Not in use because it triggers twice a change if called like: ref.update{started: firebase.database.ServerValue.TIMESTAMP }
    private get timesSampServer() {
        return firebase.database.ServerValue.TIMESTAMP

    }
    // Not a perfect solution because it triggers a write and a read
    // One day that should be replacced by implementing a cloud fucntiion that is called via http
    private async getServerTime(): Promise<number> {
        // first save current date-time in the databse and then get it from there
        // (we can not use firebase.database.ServerValue.TIMESTAMP directly because this is just
        // a place holder that transorms to a date only when it's saved in the database)
        await firebase.database().ref(currentTimePath).update({ time: firebase.database.ServerValue.TIMESTAMP });
        const promise = new Promise<Date>(async (resolve, reject) => {
            try {
                const ref = this.afd.database.ref(currentTimePath);
                ref.once('value', snapshot => {
                    this.logger.log('getServerTime: ', snapshot.val().time);
                    const date = new Date(snapshot.val().time);
                    resolve(date);
                });
            } catch (error) {
                const errorMessage = 'Could not reach server. ' + error;
                this.logger.error(errorMessage);
                reject(error);
            }
        });
        // ?? If we dont call getTime on the date the ref.update triggers
        // two updates: one that deletes that value and one that recreates it
        return (await promise).getTime();
    }

}
