<div [ngClass]="responsiveService.getBigSmall()">


    <h1 mat-dialog-title>{{data.title}}</h1>
    <div mat-dialog-content>
        <p>{{data.description1}}</p>
        <p>{{data.description2}}</p>
        <p>{{data.description3}}</p>
    </div>
    <div class="dialog-actions">
        <div mat-dialog-actions align="end" class="buttons">
            <button mat-button (click)="onNoClick()">{{data.cancelLabel}}</button>
            <span>
                <button mat-flat-button color="warn" [mat-dialog-close]="data"
                    class="spo-font-color-white">{{data.okLabel}}</button>
            </span>
        </div>

    </div>
</div>
