import { CommonModule } from '@angular/common';
import { Component, ElementRef, Input, OnChanges, Renderer2, SimpleChanges } from '@angular/core';
import { NGXLogger as LoggerService } from "ngx-logger";


@Component({
    selector: 'fade-in-and-out',
    standalone: true,
    imports: [
        CommonModule,
    ],
    templateUrl: './fade-in-and-out.component.html',
    styleUrls: ['./fade-in-and-out.component.scss'],
})

export class FadeInAndOutComponent implements OnChanges {
    @Input() isVisible: boolean;
    @Input() display: string;  //  should not be inline if ng-content has no fixed height or width , choose 'block' in this case
    @Input() maxHeight: string = '200px'; // the height of the element - needed to make the transormation smooth
    @Input() transform: string = 'translateY(-150px)'; // default: slide in from top
    @Input() hideTransitionDuration: number = 300;
    @Input() showTransitionDuration: number = 500;
    @Input() useNgIf = true
    domElement: any;
    public ngIfCondition: boolean;

    constructor(
        private elementRef: ElementRef,
        private renderer: Renderer2,
        private logger: LoggerService,
    ) {
        this.domElement = this.elementRef.nativeElement;
    }

    ngOnChanges(changes: SimpleChanges) {
        //    this.logger.log('ngOnChanges', changes);
        this.applyStyles()
    }

    private applyStyles() {
        if (this.isVisible) {
            this.setNgIfCondition(true)
            this.assignStyleToDomElement(this.visibleStyle)
        } else {
            this.assignStyleToDomElement(this.hiddenStyle)
            this.setNgIfCondition(false)

        };
    }

    async setNgIfCondition(value: boolean) {
        if (value) {
            if (this.isVisible) {
                this.ngIfCondition = true;
            }
        } else {
            await this.timeout(this.hideTransitionDuration);
            if (!this.isVisible) {
                this.ngIfCondition = false;
            }
        }
    }

    async timeout(ms: number) {
        return new Promise((resolve) => setTimeout(resolve, ms));
    }

    get hiddenStyleTransitionDuration() {
        return this.hideTransitionDuration.toString() + 'ms'
    }
    get showStyleTransitionDuration() {
        return this.showTransitionDuration.toString() + 'ms'
    }
    get visibleStyle() {
        return {
            'display': this.display,
            'max-height': this.maxHeight,
            'transition-property': 'all',
            'opacity': '1',
            'transform': 'translateY(0%)',
            'transition-duration': this.showStyleTransitionDuration,
            'transition-timing-function': 'cubic-bezier(0, 0, 0.2, 1)',
        }
    }

    get hiddenStyle() {
        return {
            'display': this.display,
            'padding-top': '0px',
            'padding-bottom': '0px',
            'max-height': '0px',
            'opacity': '0',
            'overflow': 'hidden',
            'transform': this.transform,
            'transition-property': 'all',
            'transition-duration': this.hiddenStyleTransitionDuration,
            'transition-timing-function': ' cubic-bezier(0.4, 0, 1, 1)',
        }
    }

    private assignStyleToDomElement(requiredStyles: any) {
        Object.keys(requiredStyles).forEach(newStyle => {
            this.renderer.setStyle(
                this.domElement, `${newStyle}`, requiredStyles[newStyle]
            );
        });
    }
}
