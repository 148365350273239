import { Component, EventEmitter, Inject, Input, LOCALE_ID, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { UIMesageService } from '../core/ui-message.service';
import { LoggerService } from '../core/logger.service';
import { ResponsiveService } from '../core/responsive.service';
import { DOCUMENT } from '@angular/common';
import { BuyableProduct } from "functions-lib";
import { EssDialogService } from '../common/dialogs/dialog-service';
import { EssTermsDialogComponent } from '../common/dialogs/terms-dialog.component';

export interface ClickPaypalEventDetails {
    "popup": boolean;
    "productId": string
};

const tryToUsePopUp = false;
@Component({
    selector: 'ess-payment',
    templateUrl: './payment.component.html',
    styleUrls: ['./payment.component.scss']
})



export class PaymentComponent implements OnInit {
    public payInitiated = false;
    public productSelected: string;
    @Input() termsConfirmed: boolean;
    @Input() functionsUrlPaypalStart: string;
    @Input() userId: string;
    @Input() products: BuyableProduct[];
    @Input() languageCode: string;
    @Output() clickPaypalEvent = new EventEmitter<ClickPaypalEventDetails>();
    constructor(private router: Router,
        public responsiveService: ResponsiveService,
        private logger: LoggerService,
        private uiMessage: UIMesageService,
        @Inject(DOCUMENT) private document: Document,
        @Inject(LOCALE_ID) private locale: string,
        private dialogService: EssDialogService,

    ) {
    }

    ngOnInit() {
        this.logger.log('PaymentComponent ngOnInit')
    }

    public async openTerms() {
        this.logger.warn('deletePoll: ');
        const dialogRef = this.dialogService.open(EssTermsDialogComponent, {
        });
        dialogRef.afterClosed().subscribe(async result => {
            console.log('The dialog was closed');
        });
    }

    pay(product: string) {
        let href = this.functionsUrlPaypalStart;
        href = href + '?uid=' + this.userId;
        href = href + "&prod=" + product;
        href = href + "&lan=" + this.locale
        let popupSuccess = false;
        if (tryToUsePopUp) {
            /** We have decided for now not use the pop
             * Reason: we can not control if the pop-up is a real pop-up
             * or with some browsers (e.g Iphone) opens a new tab 
             * In in this case the flow become inconvenient for the user
             */
            const windowFeatures = "popup=1,left=100,top=100,width=800,height=800";
            const handle = window.open(href, 'ess-pop-pay', windowFeatures);
            this.logger.log('handle', handle)
            popupSuccess = handle ? true : false;
        }
        if (!popupSuccess) {
            this.uiMessage.info('You will be redirected to PayPal.')
            this.payInitiated = true;
            this.document.location.href = href;
        }
        this.clickPaypalEvent.emit({ popup: popupSuccess, productId: product })
    }

    public getProductDescription(product: any) {
        this.logger.log("getProductDescription", this.languageCode)
        if (product.descriptionTranslations) {
            const descriptionTranslations = product.descriptionTranslations[this.languageCode]
            if (descriptionTranslations) return descriptionTranslations

        }
        return product.description;

    }
}
