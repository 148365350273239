import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AuthService } from '../user/auth.service';
import { Router } from '@angular/router';
import { RoomIdService } from '../poker-room/room-id.service';
import { UIMesageService } from '../common/ui-message.service';
import { UserService } from '../user/user.service';
import { ResponsiveService } from '../common/responsive.service';
import { MatDialog } from '@angular/material/dialog';
import { QrCodeDialogComponent } from '../qr-code/qr-code-dialog.component';


export interface DialogData {
    animal: string;
    name: string;
}



@Component({
    selector: 'nav-menu',
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.scss'],
    // encapsulation: ViewEncapsulation.None

})
export class MenuComponent implements OnInit {
    public qrCode: string = null;

    constructor(private router: Router,
        public authService: AuthService,
        public userService: UserService,
        private uiMessage: UIMesageService,
        public roomIdService: RoomIdService,
        public responsiveService: ResponsiveService,
        public dialog: MatDialog
    ) {
    }

    ngOnInit() {
    }

    async logout() {
        try {
            this.router.navigate(['/index']);
            await this.authService.logOut();
            this.uiMessage.success('Log out successfull');
        } catch (err) {
            this.uiMessage.error(err, 'Log out failed. ');
        }
    }

}

